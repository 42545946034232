<template>
  <div>
    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="4" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="4" class="mt-2">
          <b-form-input
            v-model="search"
            :placeholder="$t('action.search')"
          ></b-form-input>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
        <b-col sm="4" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table
            id="campaignTable"
            stacked="lg"
            bordered
            :fields="myfields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(Name)="row">
              <div class="text-center">
                <div>{{ row.item.Name }}</div>
                <div v-if="row.item.Status !== 3">
                  <b-button
                    class="btn-block"
                    size="sm"
                    variant="link"
                    @click="showModal('cloneModal', row.item)"
                    >{{ $t("label.cloneCampaign") }}
                  </b-button>
                  <b-icon-clock
                    class="ml-1"
                    :variant="getColorIcon('start', row.item.DatesCampaign)"
                  ></b-icon-clock>
                  <b-icon-clock
                    class="ml-1"
                    :variant="getColorIcon('relaunch', row.item.DatesCampaign)"
                  ></b-icon-clock>
                </div>
              </div>
            </template>
            <template v-slot:cell(Status)="row">
              <div class="text-center">
                <div>{{ getStatusValue(row.item.Status) }}</div>
                <b-button
                  v-if="row.item.Status === 1 && row.item.DatesCampaign"
                  variant="link"
                  @click="setSessionTableValue('SessionTable', row.item)"
                  >{{ $t("label.sessions") }}</b-button
                >
              </div>
            </template>
            <template v-slot:cell(Description)="row">
              <b-button
                class="btn-block"
                size="sm"
                variant="link"
                @click="showModal('description', row.item)"
                >Description</b-button
              >
            </template>
            <template v-slot:cell(details)="row">
              <b-button
                v-if="row.item.Status !== 3"
                class="btn-block"
                size="sm"
                variant="outline-success"
                @click="showModal('editModal', row.item)"
                >{{ $t("label.edit") }}</b-button
              >
              <b-button
                v-if="row.item.Status === 3"
                class="btn-block"
                size="sm"
                variant="outline-success"
                @click="goTo('CampaignDispatcher')"
                >{{ $t("label.continue") }}</b-button
              >
              <b-button
                class="btn-block"
                size="sm"
                variant="outline-danger"
                @click="showModal('deleteModal', row.item)"
                >{{ $t("label.delete") }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="campaignTable"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>

    <div v-else>
      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-alert show variant="info">
            <p>{{ $t("info.noCampaign") }}</p>
            <p>{{ $t("info.createNewCampaign") }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </div>

    <b-row class="pt-4">
      <b-col class="mt-2">
        <b-button variant="success" @click="goTo('CampaignDispatcher')">{{
          $t("label.add")
        }}</b-button>
      </b-col>
    </b-row>

    <b-modal id="description" title="Description" hide-footer>
      {{ tableRowItem.Description ? tableRowItem.Description : "" }}
    </b-modal>

    <b-modal id="deleteModal" title="Confirmation" hide-footer>
      <DeleteModal
        :values="tableRowItem"
        type="Campaign"
        modalid="deleteModal"
      />
    </b-modal>

    <b-modal id="editModal" :title="$t('label.edit')" hide-footer>
      <EditModal :values="tableRowItem" type="Campaign" modalid="editModal" />
    </b-modal>

    <b-modal id="cloneModal" :title="$t('label.cloneCampaign')" hide-footer>
      <cloneModal :values="tableRowItem" modalid="cloneModal" />
    </b-modal>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import { globalF } from "../Helpers";
import moment from "moment";
import DeleteModal from "../Modal/DeleteModal.vue";
import EditModal from "../Modal/EditModal";
import CloneModal from "../Modal/CloneModal";
import { BIconClock } from "bootstrap-vue";

export default {
  mixins: [globalF],
  data() {
    return {
      sortBy: "StartDate",
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
      tableRowItem: [],
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (
        modalId === "deleteModal" ||
        modalId === "editModal" ||
        modalId === "cloneModal"
      ) {
        let user = this.$store.getters.getUser;
        this.getAppData(user);
      }
    });
  },
  computed: {
    tableData: function () {
      return this.getCampaignWithStartDate();
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let n = o.name ? o.name : "";
        let name = n.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let d = o.description ? o.description : "";
        let description = d.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let sd = o.sd ? o.sd : "";
        let startDate = sd.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        return (
          name.toLowerCase().includes(s) ||
          description.toLowerCase().includes(s) ||
          startDate.toLowerCase().includes(s)
        );
      });

      return find;
    },
    myfields: function () {
      return [
        { key: "Name", sortable: true, label: this.$t("label.campaignName") },
        {
          key: "Description",
          sortable: true,
          label: this.$t("label.campaignDesc"),
        },
        {
          key: "StartDate",
          sortable: true,
          label: this.$t("label.campaignStartDate"),
        },
        {
          key: "Status",
          sortable: true,
          label: this.$t("label.campaignStatus"),
          formatter: (value) => this.getStatusValue(value),
        },
        { key: "details", label: this.$t("label.details") },
      ];
    },
  },
  methods: {
    getColorIcon: function (type, datesCampaign) {
      let color = "";
      if (datesCampaign) {
        let find = datesCampaign.find((dc) => dc.Type === type);
        if (find) {
          color = "success";
          let sd = find.StartDate;
          if (moment(sd).isBefore(moment())) {
            color = "danger";
          }
        }
      }
      return color;
    },
    getStatusValue: function (val) {
      let label = "";
      if (val === 1) {
        label = this.$t("label.active");
      } else if (val === 2) {
        label = this.$t("label.ended");
      } else if (val === 3) {
        label = this.$t("label.incomplete");
      } else if (val === 4) {
        label = this.$t("label.close");
      } else {
        label = "";
      }

      return label;
    },
    goTo: function (val) {
      this.$emit("changePage", val);
    },
    resetTable: function () {
      this.search = "";
    },
    setSessionTableValue: function (page, sessionTableValue) {
      this.$store.commit("setSessionTableValue", sessionTableValue);
      this.goTo(page);
    },
    showModal: function (id, campaign) {
      this.tableRowItem = campaign;
      this.$bvModal.show(id);
    },
  },
  components: {
    DeleteModal,
    EditModal,
    CloneModal,
    BIconClock,
  },
};
</script>
