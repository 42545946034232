<template>
<div class="mt-4">
    <b-form @submit="onSubmit">
    <b-row>
        <b-col sm='4' class="mt-4">
            <div><strong>{{ $t('label.campaignName') }}</strong></div>
            <b-form-input v-model.trim="$v.form.name.$model" :placeholder="$t('label.name')"></b-form-input>
            <div class="text-danger" v-if="!$v.form.name.required && $v.form.name.$dirty">{{ $t("info.required") }}</div>
            <div class="text-danger" v-if="!$v.form.name.maxLength && $v.form.name.$dirty">{{ $t("info.maxLength", [75]) }}</div>
        </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="6" class="mt-4">
        <div><strong>{{ $t('label.campaignDesc') }}</strong></div>
        <b-form-textarea
          id="description"
          v-model="$v.form.description.$model"
          :placeholder="$t('info.comments')"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <div class="text-danger" v-if="!$v.form.description.maxLength && $v.form.description.$dirty">{{ $t("info.maxLength", [1000]) }}</div>
      </b-col>
    </b-row>

    <b-button class="mt-4" type="submit" variant="success" :disabled="disabled">{{ $t("action.submit") }}</b-button>

    <div v-if="spinner">
        <b-spinner
          style="width: 3rem; height: 3rem"
          class="spinner"
          variant="success"
          label="Spinning"
          disabled="labelStatus"
        ></b-spinner>
      </div>

    <b-row v-if="error" class="mt-2">
        <b-col sm class="mt-4">
            <b-alert show variant="danger">
              <p>{{ error }}</p>
            </b-alert>
        </b-col>
    </b-row>

    </b-form>
</div>
</template>

<script>

import {en, fr} from 'vuejs-datepicker/dist/locale';
import { globalF } from '../Helpers';
import axios from 'axios';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {  
mixins: [globalF],  
props: [],
data() { 
  return { 
    form: {
        id: this.getCampaignData('Id', 0),
        name: this.getCampaignData('Name', ""),
        description: this.getCampaignData('Description', ""),
        status: this.getCampaignData('Status', "")
    },
    spinner: false,
    disabled: false,
    error:""
}},
validations() {
    let form = {
        name: {
          required,
          maxLength: maxLength(75)
        },
        description: {
          maxLength: maxLength(1000)
        }
    };

    return {form};
},
mounted () {},
computed: {
  url: function () {
    return this.getUrl();
  },
  user: function () {
    return this.$store.getters.getUser;
  },
  language: function () {
    return this.$i18n.locale === "fr" ? fr : en;
  }
},
methods:{
  setNextPage(page) {
        this.$emit('changePage', page);
    },
  setNextStep(step) {
      this.$emit('changeStep', step);
    },
  onSubmit(evt) {
      evt.preventDefault();
      this.error = "";
      this.$v.$touch();
      if (!this.$v.$invalid) { 
          this.disabled = true;
          this.spinner = true;
          this.form.request = this.form.id ? "updateCampaign" : "insertCampaign";
          this.form.userId = this.user.IdMyProfile;
          
          axios.post(this.url, this.form, { headers: {'Content-Type': 'application/json'}})
          .then(()  => {
            let promise = this.getAppData(this.user);
            promise.then(() => {
              this.disabled = false;
              this.spinner = false;
              this.$emit('changePage', 'CampaignDispatcher');
            });
          })
          .catch(()  => {
            this.error = this.$t('info.errorgeneric');
            this.disabled = false;
            this.spinner = false;
          });
      }
  }
},
components: {}
}
</script>