<template>
  <b-container>
    <div class="pt-4">
      <strong>{{ $t("label.connexionList") }}</strong>
    </div>
    <b-row class="pt-2">
      <b-form-select
        v-model="connexionDate"
        :options="connexionDates"
        v-on:change="OnListClick"
      ></b-form-select>
    </b-row>
    <hr />
    <div v-if="connexionDate">
      <div v-if="requestData">
        {{ requestData }}
      </div>
      <div v-if="submitData.length > 0">
        {{ submitData }}
      </div>
    </div>
  </b-container>
</template>

<script>
//import axios from "axios";
//import moment from "moment";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["sessionsList"],
  data() {
    return {
      connexionDate: null,
      sessionObj: null,
      requestData: null,
      submitData: [],
    };
  },
  mounted() {},
  computed: {
    url: function () {
      return this.getUrl();
    },
    connexionDates: function () {
      let userSessions = this.sessionsList.userSessions;
      userSessions.sort;
      return userSessions.map((s) => {
        let txt = "";
        if (s.Request) {
          txt = " ( R ) ";
        }
        if (s.Submit) {
          txt = " ( S ) ";
        }
        return {
          text: s.LogIn + txt,
          value: s,
        };
      });
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
  },
  methods: {
    OnListClick: function () {
      this.requestData = null;
      this.submitData = [];

      let connexionDate = this.connexionDate;
      if (connexionDate.Request) {
        let validJsonData = this.getJsonData(connexionDate.Request);
        this.requestData = validJsonData;
      }
      if (connexionDate.Submit) {
        let submitArr = this.getJsonData(connexionDate.Submit);
        if (submitArr.length > 0) {
          for (let i = 0; i < submitArr.length; i++) {
            let submitData = submitArr[i].value.config.data;
            if (submitData) {
              let validJsonData = this.getJsonData(submitData);
              if (validJsonData) {
                this.submitData.push(validJsonData);
              }
            }
          }
        }
      }
    },
    getJsonData: function (submitData) {
      let validJason = false;
      try {
        validJason = JSON.parse(submitData, null, 2);
      } catch (error) {
        console.error(error);
      }
      return validJason;
    },
  },
  components: {},
};
</script>
