<template>
  <div>
    <div v-if="current === 'Login'">
      <Login :redirect="redirect" v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'UserForm'">
      <UserForm v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'Admin' && getAppRole(user.AppRole, 1)">
      <Admin v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'AdminCpt' && getAppRole(user.AppRole, 3)">
      <AdminCpt v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'UserDirectory'">
      <UserDirectory v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'CampaignDispatcher' && getAppRole(user.AppRole, 1)">
      <CampaignDispatcher v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'SessionTable'">
      <SessionTable v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'AccessTabs'">
      <AccessTabs v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'TerminatedForm' && getAppRole(user.AppRole, 1)">
      <TerminatedForm v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'ClickEmailConfirmation'">
      <ClickEmailConfirmation v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'CreateUser' && getAppRole(user.AppRole, 1)">
      <CreateUser v-on:changePage="setNextPage" />
    </div>
    <div v-else-if="current === 'SuccessConfirmation'">
      <SuccessConfirmation />
    </div>
    <div v-else>
      <UserForm v-on:changePage="setNextPage" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { globalF } from "./Helpers";
import Login from "./LoginMsalV2.vue";
import UserForm from "./UserForm.vue";
import Admin from "./AdminMenu.vue";
import AdminCpt from "./AdminCpt.vue";
import UserDirectory from "./UserDirectory.vue";
import CampaignDispatcher from "./Campaign/CampaignDispatcher.vue";
import SessionTable from "./SessionTable.vue";
import AccessTabs from "./AccessRequest/AccessTabs.vue";
import TerminatedForm from "./AccessRequest/TerminatedForm.vue";
import ClickEmailConfirmation from "./ClickEmailConfirmation.vue";
import CreateUser from "./AccessRequest/CreateUser.vue";
import SuccessConfirmation from "./AccessRequest/SuccessConfirmation.vue";

export default {
  name: "module1",
  mixins: [globalF],
  props: {
    current: {
      type: String,
      default: "home",
    },
    redirect: {
      type: String,
    },
  },
  mounted() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.goToLogin();
          }
        }
        return Promise.reject(error);
      }
    );
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    setNextPage(page) {
      this.$emit("changePage", page);
    }
  },
  components: {
    Login,
    UserForm,
    Admin,
    AdminCpt,
    UserDirectory,
    CampaignDispatcher,
    SessionTable,
    TerminatedForm,
    ClickEmailConfirmation,
    CreateUser,
    AccessTabs,
    SuccessConfirmation
  },
};
</script>
