<template>
  <b-form @submit="onSubmit">
    <div>{{ $t("label.resetApplicationConfirmation") }}</div>

    <div v-if="spinner" class="text-center">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>

    <b-button
      class="mt-4 text-center"
      type="submit"
      variant="danger"
      :disabled="disabled"
      >{{ $t("label.submit") }}</b-button
    >

    <div v-if="error" class="pt-4">
      <b-alert show variant="danger">
        <p>{{ error }}</p>
      </b-alert>
    </div>
  </b-form>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    error: "",
    disabled: false,
    spinner: false,
  }),
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      this.error = "";
      this.spinner = true;
      this.disabled = true;

      axios
        .post(
          this.url,
          { request: "closedCurrentCampaignProcess", user: this.user.Id },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(() => {
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
  },
};
</script>
