<template>
    <div v-if="distListFromGraph">
      <b-form @submit="onSubmit">
      
      <div v-for="(dlitem, index) in myDistList" :key="'dl-'+ index"> 
        <hr v-if="index > 0" />
        <div class="mt-4"><strong>{{ (index + 1)+' - '+ $t('label.distributionList') }}</strong>: {{distListFromGraph.displayName}}</div>
        <EmailTemplate ref="myEmailTemplate" :myTemplate="getTemplateData(dlitem)"/>
      </div>
      
      <b-button class="mt-4" type="submit" variant="success" :disabled="disabled">{{ $t("action.submit") }}</b-button>
      
      <div v-if="spinner">
        <b-spinner style="width: 3rem; height: 3rem;" class="spinner" variant="success" label="Spinning" disabled="labelStatus"></b-spinner>
      </div>
      
      <b-row v-if="error" class="mt-2">
          <b-col sm class="mt-4">
              <b-alert show variant="danger">
                <p>{{ error }}</p>
              </b-alert>
          </b-col>
      </b-row>

      </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { globalF } from '../Helpers';
import EmailTemplate from './EmailTemplate';

export default {  
mixins: [globalF],  
props: [],
data() { 
  return { 
    distListFromGraph: null,
    myDistList: this.getDlData(),
    spinner: false,
    success: false,
    error:"",
    disabled: false
}},
mounted () {
  let token = this.$store.getters.getAppAccessToken;
  let dlData = this.getDlData();
  this.getDlById(token, dlData[0]);
},
computed: {
  url: function () {
    return this.getUrl();
  },
  user: function () {
    return this.$store.getters.getUser;
  }
},
methods:{
  getTemplateData: function (IdDistList) {
    let idCampaign = this.getCampaignData('Id', 0);
    let templates = this.$store.getters.getCampaignTemplateVersion;
    let templateObj = templates.find(p => p.IdCampaign === idCampaign && p.IdDistributionList === IdDistList);
    let obj = {
      IdCampaign: idCampaign,
      IdDistributionList: IdDistList,
      IdTemplate: "d-2c5c75e897f54d0fbcbadd505b85ff5d",
      Message: ""
    };
    if (templateObj) {
      obj = templateObj
    }
    
    return obj;
  },
  getData: function (type) {
    let idCampaign = this.getCampaignData('Id', 0);
    let templates = this.$store.getters.getCampaignTemplateVersion;
    let templatesFilter = templates.filter(p => p.IdCampaign === idCampaign);
    let data = null;
    if (templatesFilter.length > 0) {
      if (type === 'idCampaign') {
        data = templatesFilter[0].IdTemplate;
      }
      if (type === 'message') {
        data = templatesFilter[0].Message;
      }
    }
    
    return data;
  },
  setNextPage(page) {
      this.$emit('changePage', page);
  },
  setNextStep(step) {
      this.$emit('changeStep', step);
  },
  onSubmit(evt) {
      evt.preventDefault();

      let pass = true;

      let templateData = [];
      if (this.myDistList.length > 0) {
          for (let i=0; i < this.myDistList.length; i++) {
          let obj = this.$refs.myEmailTemplate[i].getTemplateData();
          if (obj) {
            templateData.push(obj);
          }
          else {
            pass = false
            break;
          }
        }
      }

      if (templateData.length > 0 && pass) {
          this.disabled = true;
          this.spinner = true;
          let form = {
            request: 'setVersionTemplateForCampaign',
            IdCampaign: this.getCampaignData('Id', 0),
            templateData: templateData
          }
          
          axios.post(this.url, form, { headers: {'Content-Type': 'application/json'}})
          .then(()  => {
            this.disabled = false;
            this.spinner = false;
            let promise = this.getAppData(this.user);
            promise.then(() => {
              this.$emit('changePage', 'CampaignDispatcher');
            });
          })
          .catch(()  => {
            this.error = this.$t('info.errorgeneric');
            this.disabled = false;
            this.spinner = false;
          });
      }
  }
},
components: {
    //VueEditor,
    EmailTemplate
  }
}
</script>