<template>
  <b-container>
    <b-form @submit="onSubmit">
      <b-alert show variant="danger">
        <p>{{ $t("info.removeUser") }}</p>
        {{ userToUpdate.displayName }}
      </b-alert>

      <b-button
        class="mt-4 text-center"
        type="submit"
        variant="danger"
        :disabled="disabled"
        >{{ $t("action.submit") }}</b-button
      >
      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
            <p>{{ $t("info.delayedUpdate") }}</p>
          </b-alert>
        </b-col>
      </b-row>
      <div v-if="error" class="pt-4">
        <b-alert show variant="info">
          <p>{{ error }}</p>
        </b-alert>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["modalid", "userToUpdate"],
  data() {
    return {
      form: {},
      spinner: false,
      disabled: false,
      success: false,
      error: "",
    };
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.spinner = true;
      let token = this.$store.getters.getAppAccessToken;
      let user = this.userToUpdate;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}/manager/$ref`;
      axios
        .delete(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.success = true;
          let obj = {
            Email: this.user.mail,
            LogOut: null,
            Request: null,
            Submit: JSON.stringify(response),
          };
          this.updateSession(obj);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
  },
};
</script>
