<template>
  <b-container>
    <b-alert show variant="info">
      <p>{{ $t("info.confirmation") }}</p>
      <ul>
        <li v-for="empl in emplChecked" :key="empl.id">
          {{ empl.fname }} {{ empl.lname }} ({{ empl.email }})
        </li>
      </ul>
    </b-alert>

    <div
      v-if="
        requestType === 'addEmplToExclusionList' ||
        requestType === 'blockAccount'
      "
    >
      <b-form-textarea
        class="mt-4"
        id="comments"
        v-model="$v.form.comments.$model"
        rows="5"
        max-rows="10"
        :placeholder="$t('info.comments')"
      ></b-form-textarea>
      <div
        class="text-danger"
        v-if="!$v.form.comments.maxLength && $v.form.comments.$dirty"
      >
        {{ $t("info.maxLength", [1000]) }}
      </div>
    </div>

    <b-button
      @click="onSubmit()"
      class="mt-4 text-center"
      variant="info"
      :disabled="disabled"
      >{{ $t("action.submit") }}</b-button
    >

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";
import { maxLength } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  mixins: [globalF],
  props: ["requestType", "tableData", "modalid", "idTable", "idTicket"],
  data() {
    return {
      form: {
        comments: "",
      },
      success: false,
      spinner: false,
      disabled: false,
      error: "",
      originalListLength: 0,
      newList: [],
    };
  },
  validations() {
    let form = {};
    if (
      this.requestType === "addEmplToExclusionList" ||
      this.requestType === "blockAccount"
    ) {
      form.comments = { maxLength: maxLength(1000) };
    }
    return { form };
  },
  mounted() {},
  computed: {
    agent: function () {
      return this.$store.getters.getUser;
    },
    url: function () {
      return this.getUrl();
    },
    emplChecked() {
      return this.tableData.filter((item) => item.remove === "checked");
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.originalListLength = 0;
        this.newList = [];
        this.error = "";
        this.success = false;
        if (this.requestType === "addEmplToExclusionList") {
          this.addEmplToExclusionList();
        }
        if (this.requestType === "terminateSelectedEmployees") {
          this.terminateSelectedEmployees();
        }
        if (this.requestType === "resetPasswordAndRevokeSession") {
          this.resetPasswordAndRevokeSession();
        }
        if (this.requestType === "blockAccount") {
          this.blockAccount();
        }
      }
    },
    blockAccount() {
      let token = this.$store.getters.getAppAccessToken;
      let empleList = this.tableData.filter(
        (item) => item.remove === "checked"
      );
      if (empleList.length > 0) {
        this.originalListLength = empleList.length;
        for (let i = 0; i < empleList.length; i++) {
          let empl = empleList[i];
          this.blockUser(token, empl);
        }
      }
    },
    blockUser(token, empl) {
      let idUser = empl.graphUser[0].id;

      let obj = {
        accountEnabled: false,
      };

      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}`;
      axios
        .patch(graphEndpoint, obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          empl.blockAccount = response.data;
          this.logRequest(empl);
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          empl.errorBlockAccount = error;
          this.logTerminatedUserError(empl);
        });
    },
    resetPasswordAndRevokeSession() {
      let empleList = this.tableData.filter(
        (item) => item.remove === "checked"
      );
      if (empleList.length > 0) {
        this.originalListLength = empleList.length;
        let userToken = this.$store.getters.getUserToken;
        for (let i = 0; i < empleList.length; i++) {
          let empl = empleList[i];
          this.getPasswordAuthMethod(userToken, empl);
        }
      }
    },
    addEmplToExclusionList() {
      let empleList = this.tableData.filter(
        (item) => item.remove === "checked"
      );

      if (empleList.length > 0) {
        this.disabled = true;
        this.spinner = true;
        let request = "addDesjReportExclusion";

        axios
          .post(
            this.url,
            {
              request: request,
              userList: empleList,
            },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(() => {
            this.sendZendeskTicket(empleList);
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
            this.disabled = false;
          });
      }
    },
    terminateSelectedEmployees() {
      let empleList = this.tableData.filter(
        (item) => item.remove === "checked"
      );

      if (empleList.length > 0) {
        let token = this.$store.getters.getAppAccessToken;
        this.originalListLength = empleList.length;

        for (let i = 0; i < empleList.length; i++) {
          let empl = empleList[i];
          empl.sendingType = "immediate";
          this.getUserMemberOf(token, empl);
        }
      }
    },
    getUserMemberOf(token, empl) {
      this.spinner = true;
      let idUser = empl.graphUser[0].id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/memberOf`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let groupList = response.data.value;
          empl = this.setGroupList(groupList, empl);
          this.getUserLicenses(token, empl);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    setGroupList(groupList, empl) {
      empl.groupsToUpdate = groupList.filter((g) => {
        if (g.groupTypes) {
          if (g.groupTypes.includes("DynamicMembership")) {
            return false;
          }
        }
        if (g.securityEnabled) {
          return false;
        }
        return true;
      });
      let onlyGroupList = empl.groupsToUpdate.filter((s) => s.groupTypes);

      let distList = onlyGroupList.filter((s) => s.groupTypes.length === 0);
      let microsoftList = onlyGroupList.filter((s) =>
        s.groupTypes.includes("Unified")
      );
      empl.distributionList = distList.map((s) => {
        return s.id;
      });
      empl.microsoft365Group = microsoftList.map((s) => {
        return s.id;
      });

      return empl;
    },
    getUserLicenses(token, empl) {
      this.spinner = true;
      let idUser = empl.graphUser[0].id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/licenseDetails`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let userLicenses = response.data.value;
          empl.licensesToRemove = userLicenses.map((l) => {
            return l.skuId;
          });
          this.logRequest(empl);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getPasswordAuthMethod(token, empl) {
      this.spinner = true;
      let idUser = empl.graphUser[0].id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/authentication/passwordMethods`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          empl.getPasswordAuthMethod = response.data;
          let requestObj = {
            newPassword: this.generatePassword(),
          };
          empl.newPassword = requestObj.newPassword;

          this.resetPassword(token, empl);
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          empl.errorGetAuthMethod = error;
          this.logTerminatedUserError(empl);
        });
    },
    resetPassword(token, empl) {
      let requestObj = {
        newPassword: this.generatePassword(),
      };
      empl.newPassword = requestObj.newPassword;
      let idUser = empl.graphUser[0].id;
      let idPasswordAuthMethod = empl.getPasswordAuthMethod.value[0].id;

      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/authentication/methods/${idPasswordAuthMethod}/resetPassword`;
      axios
        .post(graphEndpoint, requestObj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          empl.resetPassword = response.data;
          this.revokeSession(token, empl);
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          empl.errorResetPassword = error;
          this.logTerminatedUserError(empl);
        });
    },
    revokeSession(token, empl) {
      let idUser = empl.graphUser[0].id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/revokeSignInSessions`;
      axios
        .post(
          graphEndpoint,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          empl.revokeSession = response.data;
          this.logRequest(empl);
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          empl.errorRevokeSession = error;
          this.logTerminatedUserError(empl);
        });
    },
    logRequest(form) {
      
      form.user = form.graphUser[0];
      form.request = this.requestType;
      form.agent = this.agent;

      form.accessFormLogObj = {
        blockUser: null,
        convertMailBox: null,
        clearManagerField: null,
        zendeskTicket: null,
      };

      axios
        .post(this.url, form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.newList.push(form);

          if (this.newList.length === this.originalListLength) {
            this.sendZendeskTicket(this.newList);
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          form.accessFormLogObj.logRequest = error;
          this.logTerminatedUserError(form);
        });
    },
    logTerminatedUserError: function (form) {
      this.disabled = true;
      this.spinner = true;

      form.request = "accessFormLog";
      form.requestType = this.requestType;

      axios
        .post(this.url, form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.disabled = false;
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    sendZendeskTicket(userList) {
      let form = {
        request: "sendTicketUpdateDesjReportBatchTermination",
        requestType: this.requestType,
        userList: userList,
        comments: this.form.comments,
        jobData: this.getJobData(),
        idTable: this.idTable,
        idTicket: this.idTicket
      };

      axios
        .post(this.url, form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.spinner = false;
          this.disabled = true;
          this.success = true;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    getJobData() {
      let emplList = this.tableData.map((e) => {
        if (e.remove === "checked") {
          e.graphUser[0].processed = true;
          e.graphUser[0].processedDate = moment().format("YYYY-MM-DD HH:mm:ss");
          e.graphUser[0].processedType = this.requestType;
        }
        return e;
      });

      let foundAccountEnabledTrue = [];

      for (let i = 0; i < emplList.length; i++) {
        let desjItem = emplList[i].desjUser;
        let graphUser = emplList[i].graphUser;
        desjItem[desjItem.length - 1] = JSON.stringify(graphUser);
        foundAccountEnabledTrue.push(desjItem);
      }

      return { foundAccountEnabledTrue: foundAccountEnabledTrue };
    },
  },
};
</script>
