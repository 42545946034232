<template>
  <b-container>
    <b-nav tabs class="mt-4">
      <b-nav-item :active="active === 'res'" @click="active = 'res'">{{
        $t("label.resName")
      }}</b-nav-item>
      <b-nav-item
        :active="active === 'ClassNames'"
        @click="active = 'ClassNames'"
        >{{ $t("label.className") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'Campaign'"
        @click="active = 'Campaign'"
        >{{ $t("label.campaign") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'TicketTable'"
        @click="active = 'TicketTable'"
        >{{ $t("label.ticketTable") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'CreateTable'"
        @click="active = 'CreateTable'"
        >{{ $t("label.createTable") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'LicenseReport'"
        @click="active = 'LicenseReport'"
        >{{ $t("label.licenseReport") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'BillingReport'"
        @click="active = 'BillingReport'"
        >{{ $t("label.billingReport") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'SignInTable'"
        @click="active = 'SignInTable'"
        >{{ $t("label.signInTable") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'DesjTerminationTable'"
        @click="active = 'DesjTerminationTable'"
        >{{ $t("label.DesjTerminationTable") }}</b-nav-item
      >
      <b-nav-item
        :active="active === 'DesjExclusionTable'"
        @click="active = 'DesjExclusionTable'"
        >{{ $t("label.DesjExclusionTable") }}</b-nav-item
      >
      <!--<b-nav-item
        :active="active === 'CreateUserEnBatch'"
        @click="active = 'CreateUserEnBatch'"
        >Create User En Batch</b-nav-item
      >-->
    </b-nav>

    <AdminTable v-if="active === 'res'" :list="[]" type="PropertyName" client="Ti" />
    <AdminTable v-if="active === 'ClassNames'" :list="[]" type="ClassNames" client="Ti" />
    <CampaignTable v-on:changePage="setNextPage" v-if="active === 'Campaign'" />
    <TicketTable
      v-on:changePage="setNextPage"
      v-if="active === 'TicketTable'"
      tableType="unresolved"
      request="getUnresolvedTicket"
    />
    <CreateTable
      v-on:changePage="setNextPage"
      v-if="active === 'CreateTable'"
    />
    <LicenseReport v-on:changePage="setNextPage" v-if="active === 'LicenseReport'"/>
    <BillingReport v-on:changePage="setNextPage" v-if="active === 'BillingReport'"/>
    <DesjTerminationTable v-on:changePage="setNextPage" v-if="active === 'DesjTerminationTable'"/>
    <DesjExclusionTable v-on:changePage="setNextPage" v-if="active === 'DesjExclusionTable'"/>
    <LastSignInTable v-on:changePage="setNextPage" v-if="active === 'SignInTable'"/>
    <CreateUserEnBatch v-on:changePage="setNextPage" v-if="active === 'CreateUserEnBatch'"/>
  </b-container>
</template>

<script>
import { globalF } from "./Helpers";
import AdminTable from "./AdminTable";
import CampaignTable from "./Campaign/CampaignTable";
import TicketTable from "./AccessRequest/TicketTable.vue";
import CreateTable from "./AccessRequest/ClosedRequestSelect.vue";
import LicenseReport from "./Reports/LicenseReportMultipleTab.vue";
import BillingReport from "./Reports/BillingReport.vue";
import LastSignInTable from "./Reports/LastSignInTable.vue";
import CreateUserEnBatch from "./AccessRequest/CreateUserEnBatch.vue";
import DesjTerminationTable from "./AccessRequest/DesjTerminationTable.vue";
import DesjExclusionTable from "./AccessRequest/DesjExclusionTable.vue";

export default {
  mixins: [globalF],
  data() {
    return {
      active: this.getActive(),
    };
  },
  validations() {},
  mounted() {},
  computed: {
    provinces: function () {
      let list = this.$store.getters.getProvinces;
      return this.mapBootstrapSelect(list, "Province");
    },
    countries: function () {
      let list = this.$store.getters.getCountries;
      return this.mapBootstrapSelect(list, "Country");
    },
  },
  methods: {
    getActive: function () {
      return this.$store.getters.getAdminActiveTab;
    },
    mapBootstrapSelect: function (list, type) {
      let lg = this.$i18n.locale;
      return list.map((l) => {
        let obj = {};
        obj.value = l.Id;
        if (type === "City") {
          obj.text = l.Name;
        } else {
          obj.text = lg === "fr" ? l.Fr : l.En;
        }
        return obj;
      });
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
  },
  components: {
    AdminTable,
    CampaignTable,
    TicketTable,
    CreateTable,
    LicenseReport,
    BillingReport,
    LastSignInTable,
    CreateUserEnBatch,
    DesjTerminationTable,
    DesjExclusionTable
  },
};
</script>
