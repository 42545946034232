<template>
  <div>
    <div>
      <b-button variant="success" @click="validateExcelInfo()">{{
        $t("action.generateExcel")
      }}</b-button>
    </div>
    <div v-if="spinner" class="text-center">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
    <div v-if="error" class="pt-4">
      <b-alert show variant="danger">
        <p>{{ error }}</p>
        <a href="https://cogirnet-my.sharepoint.com/" target="_blank">{{
          $t("label.webUrl")
        }}</a>
      </b-alert>
    </div>
    <div v-if="success" class="pt-4">
      <b-alert show variant="success">
        <p>{{ $t("info.successExcel") }}</p>
        <a :href="webUrl" target="_blank">{{ $t("label.webUrl") }}</a>
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["data", "fileName", "worksheet", "range", "sessionLog"],
  data: () => ({
    error: "",
    spinner: false,
    success: false,
    sessionId: null,
    webUrl: "",
  }),
  mounted() {},
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    validateExcelInfo: function () {
      this.error = "";
      this.success = false;
      this.spinner = true;
      this.getDriveItem();
    },
    getSessionId: function (token) {
      let request = { persistChanges: true };
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/createSession";

      axios
        .post(graphEndpoint, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.sessionId = response.data.id;
            this.listWorksheet(token);
          } else {
            this.spinner = false;
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.$t("info.errorSessionId");
        });
    },
    getDriveItem: function () {
      let promise = this.validateToken();
      promise.then((token) => {
        if (token) {
          let graphEndpoint =
            "https://graph.microsoft.com/v1.0/me/drive/root:/" + this.fileName;
          axios
            .get(graphEndpoint, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status === 200) {
                this.webUrl = response.data.webUrl;
                this.getSessionId(token);
              } else {
                this.spinner = false;
                this.error = this.error = this.$t("info.errorgeneric");
              }
            })
            .catch(() => {
              this.uploadFileName(token);
            });
        }
      });
    },
    uploadFileName: function (token) {
      let path = this.fileName;
      axios({
        url: path,
        method: "GET",
        responseType: "blob",
      })
        .then((getResponse) => {
          if (getResponse.status === 200) {
            let graphEndpoint = `https://graph.microsoft.com/v1.0/me/drive/root:/${path}:/content`;
            axios
              .put(graphEndpoint, getResponse.data, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
              })
              .then((putResponse) => {
                if (putResponse.status === 201) this.getSessionId(token);
                this.webUrl = putResponse.data.webUrl;
              })
              .catch(() => {
                this.spinner = false;
                this.error = this.$t("info.excelFilesNotExist", [
                  this.fileName,
                ]);
              });
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.$t("info.excelFilesNotExist", [this.fileName]);
        });
    },
    listWorksheet: function (token) {
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/worksheets";
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "workbook-session-id": this.sessionId,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data) {
            if (response.data.value) {
              let worksheetlist = response.data.value;
              let find = worksheetlist.find((w) => w.name === this.worksheet);
              if (find) {
                this.getCurrentRange(token);
              } else {
                this.createWorkSheet(token);
              }
            }
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.error = this.$t("info.errorgeneric");
        });
    },
    createWorkSheet: function (token) {
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/worksheets";
      let request = { name: this.worksheet };
      axios
        .post(graphEndpoint, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "workbook-session-id": this.sessionId,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.generateExcel(token);
          } else {
            this.spinner = false;
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
        });
    },
    getCurrentRange: function (token) {
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/worksheets/" +
        this.worksheet +
        "/usedRange";

      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "workbook-session-id": this.sessionId,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let rangeValue = null;
          let rawAddress = response.data.address;
          let ind = rawAddress.indexOf("!");
          if (ind !== -1) {
            rangeValue = rawAddress.substr(ind + 1);
          }
          if (rangeValue) {
            this.deleteRange(token, rangeValue);
          } else {
            this.spinner = false;
            this.error = this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.error = this.$t("info.errorgeneric");
        });
    },
    deleteRange: function (token, range) {
      let request = { applyTo: "All" };
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/worksheets/" +
        this.worksheet +
        "/range(address='" +
        range +
        "')/clear";

      axios
        .post(graphEndpoint, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "workbook-session-id": this.sessionId,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 204) {
            this.generateExcel(token);
          } else {
            this.spinner = false;
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
        });
    },
    generateExcel: function (token) {
      let request = { values: this.data };

      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/worksheets('" +
        this.worksheet +
        "')/range(address='" +
        this.range +
        "')";

      axios
        .patch(graphEndpoint, request, {
          headers: {
            Authorization: `Bearer ${token}`,
            "workbook-session-id": this.sessionId,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          if (response.status === 200) {
            this.success = true;
            this.closeSession(token);
          }
        })
        .catch(() => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
        });
    },
    closeSession: function (token) {
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me/drive/root:/" +
        this.fileName +
        ":/workbook/closeSession";

      axios
        .post(
          graphEndpoint,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "workbook-session-id": this.sessionId,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 204) {
            this.sessionId = null;
          }

          let obj = {
            Email: this.user.mail,
            LogOut: null,
            Request: JSON.stringify(this.sessionLog),
            Submit: null,
          };
          this.updateSession(obj);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
        });
    },
  },
};
</script>