<template>
  <b-container>
  <!-- <h1 class="text-center pt-4 pb-4">Ajout d'un statut</h1>-->
  <b-form @submit="onSubmit">

    <b-row v-if="type === 'City'">
      <b-col sm class="mt-4">
        <b-form-input v-model.trim="$v.form.Name.$model" :placeholder="$t('label.name')"></b-form-input>
        <div class="text-danger" v-if="!$v.form.Name.required && $v.form.Name.$dirty">{{ $t("info.required") }}</div>
        <div class="text-danger" v-if="!$v.form.Name.maxLength && $v.form.Name.$dirty">{{ $t("info.maxLength", [75]) }}</div>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col sm class="mt-4">
        <b-form-input v-model.trim="$v.form.Fr.$model" :placeholder="$t('label.french')"></b-form-input>
        <div class="text-danger" v-if="!$v.form.Fr.required && $v.form.Fr.$dirty">{{ $t("info.required") }}</div>
        <div class="text-danger" v-if="!$v.form.Fr.maxLength && $v.form.Fr.$dirty">{{ $t("info.maxLength", [75]) }}</div>
      </b-col>
      <b-col sm class="mt-4">
        <b-form-input v-model.trim="$v.form.En.$model" :placeholder="$t('label.english')"></b-form-input>
        <div class="text-danger" v-if="!$v.form.En.required && $v.form.En.$dirty">{{ $t("info.required") }}</div>
        <div class="text-danger" v-if="!$v.form.En.maxLength && $v.form.En.$dirty">{{ $t("info.maxLength", [75]) }}</div>
      </b-col>
    </b-row>

  <b-button class="mt-4 text-center" type="submit" variant="success" :disabled="disabled">{{ $t("action.submit") }}</b-button>

    <div v-if="error" class="pt-4">
      <b-alert show variant="danger">
        <p>{{ $t("info.errorgeneric", [error]) }}</p>
      </b-alert>
    </div>

  </b-form>
</b-container>
</template>

<script>

import axios from 'axios';
import { required, maxLength } from 'vuelidate/lib/validators';
import { globalF } from '../Helpers';

export default {
mixins: [globalF],
props: ['values', 'type', 'dep', 'modalid'],
data() {
  return {
    form: {
        Name: this.getData('Name'),
        Fr: this.getData('Fr'),
        En: this.getData('En')
    },
    spinner: false,
    disabled: false,
    error: ""
}},
validations() {
    if (this.type === 'City') {
        return {
            form: {
                Name: {required,maxLength: maxLength(75)}
            }
        }     
    }
    else {
        return {
            form: {
                Fr: {required,maxLength: maxLength(75)},
                En: {required,maxLength: maxLength(75)}
            }
        }     
    }
},
computed: {
  url: function () {
    return this.getUrl();
  }
 },
methods:{
    getData: function (field) {
        if (this.values) {
            return this.values[field];
        }
        else {
            return '';
        }   
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) { 
        this.spinner = true;
        this.form.request = this.values ? "update" : "insert";
        this.form.Type = this.type;
        this.form.Id = this.values ? this.values.Id : 0;
        this.form.Dep = this.dep;

        axios.post(this.url, this.form, { headers: {'Content-Type': 'application/json'}})
         .then((response)  => {
           this.disabled = false;
           this.spinner = false;
           
           if (response.data === 0) {
             this.$bvModal.hide(this.modalid);
           }
           else {
             this.error = response.data === 1 ? 'Similar data already exist in database' : this.$t('info.errorgeneric');
           }
         })
         .catch(()  => {
           this.error = this.$t('info.errorgeneric');
           this.disabled = false;
           this.spinner = false;
         });
      }
  }
}
}
</script>
