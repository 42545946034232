<template>
  <div>
    <b-row>
      <b-col sm="6" class="mt-2">
        <div>{{ $t("label.billingReport") }}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" class="mt-2">
        <datepicker input-class="datepicker" v-model="month" :placeholder="$t('action.chooseDate')" :language="language"
          :minimumView="'month'" :maximumView="'month'" :disabledDates="disabledMonth"></datepicker>
      </b-col>
    </b-row>

    <b-row v-if="billingReport.length > 0">
      <b-col sm="6" class="mt-2">
        <ExcelReportMultipleTable :tabList="setDataForExcelReport()" fileName="BillingReport.xlsx"
          :sessionLog="sessionLog" />
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center">
      <b-spinner style="width: 3rem; height: 3rem" class="spinner" variant="success" label="Spinning"
        disabled="labelStatus"></b-spinner>
    </div>
    <div v-if="error" class="pt-4">
      <b-alert show variant="danger">
        <p>{{ error }}</p>
        <a href="https://cogirnet-my.sharepoint.com/" target="_blank">{{
          $t("label.webUrl")
        }}</a>
      </b-alert>
    </div>
    <div v-if="success" class="pt-4">
      <b-alert show variant="success">
        <p>{{ $t("info.successExcel") }}</p>
        <a :href="webUrl" target="_blank">{{ $t("label.webUrl") }}</a>
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExcelReportMultipleTable from "./ExcelReportMultipleTab.vue";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: [],
  data: () => ({
    error: "",
    spinner: false,
    success: false,
    ticketList: [],
    billingReport: [],
    month: "",
    openTicketCount: []
  }),
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    sessionLog: function () {
      return {
        request: "Billing Report",
        length: this.billingReport.length,
        search: "",
      };
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disabledMonth: function () {
      return { from: moment().toDate() };
    },
    buildingList: function () {
      return this.$store.getters.getBuildings;
    },
  },
  watch: {
    month: function (newDate) {
      this.error = "";
      this.spinner = false;
      this.success = false;
      this.billingReport = [];
      this.getClosedTicketByMonth(newDate);
    },
  },
  methods: {
    getClosedTicketByMonth(newDate) {
      let firstDayOfMonth = moment(newDate).startOf("month").format("YYYY-MM-DD");
      let firstTen = moment(newDate).add(9, "day").format("YYYY-MM-DD");
      let secondTen = moment(newDate).add(19, "day").format("YYYY-MM-DD");
      let firstDayOfNextMonth = moment(newDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");

      this.spinner = true;
      axios
        .post(
          this.url,
          {
            request: "getClosedTicketByMonth",
            firstDayOfMonth: firstDayOfMonth,
            firstTen: firstTen,
            secondTen: secondTen,
            firstDayOfNextMonth: firstDayOfNextMonth
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          if (response.data) {
            let openTicketCount = response.data.open;
            let closeTicketList = response.data.close;
            let flatOpen = openTicketCount.flat();
            let flatCloseList = closeTicketList.flat();
            this.openTicketCount = flatOpen.length > 0 ? flatOpen[0] : 0;
            let flatClose = flatCloseList.flat();
            this.setCloseTicketList(flatClose);
          }

          this.disabled = false;
          this.spinner = false;
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });
    },
    setCloseTicketList: function (closeTicketList) {
      this.billingReport = closeTicketList.map((ticket) => {
        let ticketNumber = "";
        let billingCode = "";
        let closeDate = "";
        let buildingName = "";
        let className = "";
        let buildingProvince = "";
        let description = "";
        let billableTime = "";
        let billableAmount = "";
        let materialUsed = "";
        let materialPrice = "";
        let totalAmount = "";
        let hourlyRate = null;
        let replacementReason = "";

        if (ticket) {
          let custom_fields = ticket.custom_fields;
          let idBuilding = custom_fields.find(
            (x) => x.id == 11119519129108
          ).value;

          ticketNumber = ticket.id;
          billingCode = this.getBuildingInfo(idBuilding, "CodeFacturation");
          closeDate = ticket.updated_at
            ? moment(ticket.updated_at).format("YYYY-MM-DD HH:mm:ss")
            : "";
          buildingName = this.getBuildingInfo(idBuilding, "ResName");
          className = this.getBuildingInfo(idBuilding, "Class");
          hourlyRate = this.getBuildingInfo(idBuilding, "HourlyRate"); 
          buildingProvince = this.getBuildingInfo(idBuilding, "State");
          description = custom_fields.find((x) => x.id == 24175545).value;
          billableTime = custom_fields.find((x) => x.id == 24148885).value;
          billableAmount = this.calculateBillableAmount(billableTime, hourlyRate);
          materialUsed = custom_fields.find((x) => x.id == 24108969).value;
          materialPrice = custom_fields.find((x) => x.id == 24108959).value;
          replacementReason = custom_fields.find((x) => x.id == 22892826091796).value;
           
          totalAmount = this.calculateTotalAmount(
            billableAmount,
            materialPrice
          );

          if (idBuilding == "other") {
            buildingName = custom_fields.find((x) => x.id == 22421617).value;
          }
        }

        return {
          ticketNumber: ticketNumber,
          billingCode: billingCode,
          closeDate: closeDate,
          buildingName: buildingName,
          className: className,
          buildingProvince: buildingProvince,
          description: description,
          billableTime: billableTime,
          billableAmount: billableAmount,
          materialUsed: materialUsed,
          materialPrice: materialPrice,
          totalAmount: totalAmount,
          replacementReason: replacementReason
        };
      });
    },
    getBuildingInfo: function (idBuilding, field) {
      let building = this.buildingList.find((x) => x.Id == idBuilding);
      if (building) {
        return building[field];
      }
      return "";
    },
    calculateBillableAmount: function (billableTime, hourlyRate) {
      let defaultHourlyRate = this.$store.getters.getHourlyRate;
      defaultHourlyRate = parseInt(defaultHourlyRate.Value);
      let hrvalue = hourlyRate != null ? hourlyRate : defaultHourlyRate;
      let hrvalueint = parseInt(hrvalue);
      let facturable = isNaN(hrvalueint) ? defaultHourlyRate : hrvalueint;

      let billable = parseFloat(billableTime);
      if (isNaN(billable)) {
        return 0;
      }
      return billable * facturable;
    },
    calculateTotalAmount: function (billableAmount, materialPrice) {
      let billable = parseFloat(billableAmount);
      let material = parseFloat(materialPrice);
      if (isNaN(billable)) {
        billable = 0;
      }
      if (isNaN(material)) {
        material = 0;
      }
      return billable + material;
    },
    setDataForExcelReport: function () {
      let tab1 = {
        worksheetName: "BillingReport",
        data: this.getBillingReportData(),
        range: this.getBillingReportDataRange(),
      };

      return [tab1];
    },
    getBillingReportData: function () {
      let classNameList = this.$store.getters.getClassNames;
      let all = [];
      let data = this.billingReport;
      let header = [
        "Numéro du ticket",
        "Code de facturation",
        "Propriété",
        "Description",
        "Temps facturable",
        "$ Facturable",
        "Matériel utilisé",
        "Raison du remplacement",
        "Prix",
        "Total à facturer",
      ];

      all.push(header);
      all.push(["", "", "", "", "", "", "", "", "", ""]);

      for (let i = 0; i < data.length; i++) {
        let ticketLine = [];
        let ticketNumber = data[i].ticketNumber ? data[i].ticketNumber : "";
        let billingCode = data[i].billingCode ? data[i].billingCode : "";
        let buildingName = data[i].buildingName ? data[i].buildingName : "";
        let description = data[i].description ? data[i].description : "";
        let billableTime = data[i].billableTime ? data[i].billableTime : "";
        let billableAmount = data[i].billableAmount
          ? data[i].billableAmount
          : "";
        let materialUsed = data[i].materialUsed ? data[i].materialUsed : "";
        let price = data[i].materialPrice ? data[i].materialPrice : "";
        let total = data[i].totalAmount ? data[i].totalAmount : "";
        let replacementReason = data[i].replacementReason ? data[i].replacementReason : "";

        ticketLine.push(
          ticketNumber,
          billingCode,
          buildingName,
          description,
          billableTime,
          billableAmount,
          materialUsed,
          replacementReason,
          price,
          total,
        );

        all.push(ticketLine);
      }

      all.push(["", "", "", "", "", "", "", "", "", ""]);

      let closeTicket = data.length;
      let openTicket = this.openTicketCount;
      let billableHour = data.reduce((a, b) => {
        let billableTime = parseFloat(b.billableTime);
        billableTime = isNaN(billableTime) ? 0 : billableTime;
        return a + billableTime;
      }, 0);

      let orphans = data.filter((a) => {
        let find = classNameList.find((c) => c.Id === a.className);
        if (!find) {
          return a;
        }
      });

      let orphansLength = orphans.length;
      let percentageOrphans = (orphansLength / data.length) * 100;

      let orphansBillableHour = data.reduce((a, b) => {
        let billableTime = 0;
        let find = classNameList.find((c) => c.Id === b.className);
        if (!find) {
          billableTime = parseFloat(b.billableTime);
          billableTime = isNaN(billableTime) ? 0 : billableTime;
        }
        return a + billableTime;
      }, 0);

      //Par classe
      for (let i = 0; i < classNameList.length; i++) {
        let classItem = classNameList[i];

        let ticketCount = data.reduce((a, b) => {
          let className = b.className;
          let acc = 0;
          if (className == classItem.Id) {
            acc = 1;
          }
          return a + acc;
        }, 0);

        let billableHour = data.reduce((a, b) => {
          let className = b.className;
          let billableTime = 0;
          if (className == classItem.Id) {
            billableTime = parseFloat(b.billableTime);
            billableTime = isNaN(billableTime) ? 0 : billableTime;
          }
          return a + billableTime;
        }, 0);

        let percentage = (ticketCount / data.length) * 100;

        all.push([
          `Nombre de billets fermés dans le mois pour ${classItem.Fr}`,
          ticketCount,
          percentage + "%",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push([
          `Nombre d'heures associées au nombre de billets fermés dans le mois pour ${classItem.Fr}`,
          billableHour,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
      }

      //Orphelins
      all.push([
        `Nombre de billets fermés dans le mois (Aucune classe associée)`,
        orphansLength,
        percentageOrphans + "%",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      all.push([
        `Nombre d'heures associées au nombre de billets fermés dans le mois (Aucune classe associée)`,
        orphansBillableHour,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      all.push(["", "", "", "", "", "", "", "", "", ""]);

      all.push([
        "Nombre de billets ouverts dans le mois",
        openTicket,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      all.push([
        "Nombre de billets fermés dans le mois",
        closeTicket,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      all.push([
        "Nombre d'heures associées au nombre de billets fermés dans le mois",
        billableHour,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);

      all.push(["", "", "", "", "", "", "", "", "", ""]);

      //Par classe et province

      let classByProvince = [];

      for (let i = 0; i < classNameList.length; i++) {
        let classItem = classNameList[i];

        //className
        //buildingProvince
        let obj = {
          "qcList": [],
          "onList": [],
          "bcList": [],
          "classItem": {}
        }

        let qcList = data.filter((a) => {
          let className = a.className;
          let province = a.buildingProvince.toLowerCase();
          let prShort = "qc";
          let prLong = "quebec";
          let prLongAccent = "québec";
          if (className == classItem.Id) {
            if (province === prShort || province === prLong || province === prLongAccent) {
              return a;
            }
          }
        });

        let onList = data.filter((a) => {
          let className = a.className;
          let province = a.buildingProvince.toLowerCase();
          let prShort = "on";
          let prLong = "ontario";
          if (className == classItem.Id) {
            if (province === prShort || province === prLong) {
              return a;
            }
          }
        });

        let bcList = data.filter((a) => {
          let className = a.className;
          let province = a.buildingProvince.toLowerCase();
          let prShort = "bc";
          let prLong = "BRITISH COLOMBIA";
          if (className == classItem.Id) {
            if (province === prShort || province === prLong) {
              return a;
            }
          }
        });

        obj.qcList = qcList;
        obj.onList = onList;
        obj.bcList = bcList;
        obj.classItem = classItem

        classByProvince.push(obj);
      }

      for (let i = 0; i < classByProvince.length; i++) {
        let buildingClass = classByProvince[i];
        let qcList = buildingClass.qcList;
        let onList = buildingClass.onList;
        let bcList = buildingClass.bcList;
        let className = buildingClass.classItem.Fr

        let qcCloseTicket = qcList.length;
        let qcbillableHour = qcList.reduce((a, b) => {
          let billableTime = parseFloat(b.billableTime);
          billableTime = isNaN(billableTime) ? 0 : billableTime;
          return a + billableTime;
        }, 0);

        let onCloseTicket = onList.length;
        let onbillableHour = onList.reduce((a, b) => {
          let billableTime = parseFloat(b.billableTime);
          billableTime = isNaN(billableTime) ? 0 : billableTime;
          return a + billableTime;
        }, 0);

        let bcCloseTicket = bcList.length;
        let bcbillableHour = bcList.reduce((a, b) => {
          let billableTime = parseFloat(b.billableTime);
          billableTime = isNaN(billableTime) ? 0 : billableTime;
          return a + billableTime;
        }, 0);

        all.push([
          `Nombre de billets fermés dans le mois pour ${className} et Québec`,
          qcCloseTicket,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push([
          `Nombre d'heures associées au nombre de billets fermés dans le mois pour ${className} et Québec`,
          qcbillableHour,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push([
          `Nombre de billets fermés dans le mois pour ${className} et Ontario`,
          onCloseTicket,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push([
          `Nombre d'heures associées au nombre de billets fermés dans le mois pour ${className} et Ontario`,
          onbillableHour,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push([
          `Nombre de billets fermés dans le mois pour ${className} et BC`,
          bcCloseTicket,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push([
          `Nombre d'heures associées au nombre de billets fermés dans le mois pour ${className} et BC`,
          bcbillableHour,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);

        all.push(["", "", "", "", "", "", "", "", "", ""]);
      }

      return all;
    },
    getBillingReportDataRange: function () {
      let excelData = this.getBillingReportData();
      //De A à Z correspond au nombre de champs dans l'entête (nom, courriel, consent, etc.).
      return "A1:J" + excelData.length;
    },
  },
  components: {
    ExcelReportMultipleTable,
    Datepicker,
  },
};
</script>