<template>
  <b-container>
    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-input
            v-model="search"
            :placeholder="$t('action.search')"
          ></b-form-input>
          <div>
            <small>&#9432; {{ $t("info.searchDirectory") }}</small>
          </div>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
        <b-col sm="6" class="mt-2">
          <ExcelReport
            :data="getAutotableBody()"
            fileName="MyProfile.xlsx"
            worksheet="LastSignIn"
            :range="getRange()"
            :sessionLog="sessionLog"
          />
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table
            id="UserDirectoryTable"
            stacked="lg"
            bordered
            :fields="myfields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(displayName)="row">
              <b-button
                variant="link"
                size="sm"
                @click="openmodal('userInfo', row.item)"
                >{{ row.item.displayName }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="UserDirectoryTable"
          ></b-pagination>
        </b-col>
      </b-row>


      <b-modal id="userInfo" :title="$t('label.lastSignInDetails')" hide-footer>
        <LastSignInDetails :userInfo="dataFromTable" />
      </b-modal>
    </div>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </b-container>
</template>

<script>
import ExcelReport from "./ExcelReport.vue";
import LastSignInDetails from "../Modal/LastSignInDetails.vue";
import axios from "axios";
import moment from "moment";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      rawData: [],
      tableData: [],
      count: null,
      dataFromTable: {},
      sortBy: "displayName",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
    };
  },
  mounted() {
      let token = this.$store.getters.getAppAccessToken;
      
      let graphEndpoint = `https://graph.microsoft.com/beta/users?$select=id,displayName,userPrincipalName,officeLocation,signInActivity,createdDateTime&$filter=accountEnabled eq true and userType eq 'Member' and 
      assignedPlans/any(a:a/servicePlanId eq 9aaf7827-d63c-4b61-89c3-182f06f82e5c and a/capabilityStatus eq 'Enabled') or 
      assignedPlans/any(a:a/servicePlanId eq efb87545-963c-4e0d-99df-69c6916d9eb0 and a/capabilityStatus eq 'Enabled')&$count=true`;
      
      this.getAllUsers(token, graphEndpoint);
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    sessionLog: function () {
      return {
        request: "Generate Excel Report",
        length: this.items.length,
        search: this.search,
      };
    },
    sessionsList: function () {
      return this.$store.getters.getAllSessions;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let dp = o.displayName ? o.displayName : "";
        let displayName = dp.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        //let userPrincipalName = o.userPrincipalName ? o.userPrincipalName : "";

        let off = o.officeLocation ? o.officeLocation.replace(/[()]/g, "") : ""; //Remove ()
        let officeLocation = off
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return (
          displayName.toLowerCase().includes(s) ||
          //userPrincipalName.toLowerCase().includes(s) ||
          officeLocation.toLowerCase().includes(s)
        );
      });

      return find;
    },
    myfields: function () {
      let myFields = [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        /*{ key: "userPrincipalName", sortable: true, label: this.$t("label.email") },*/
        {
          key: "officeLocation",
          sortable: true,
          label: this.$t("label.offices"),
        },
        {
          key: "lastSignInDateTime",
          sortable: true,
          label: this.$t("label.lastSignIn")
        },
        {
          key: "lastNonInteractiveSignInDateTime",
          sortable: true,
          label: this.$t("label.lastSignInNonInteractive"),
        },
        {
          key: "createdDateTime",
          sortable: true,
          label: this.$t("label.creation"),
          formatter: (value) => {return value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : ""} ,
        }
      ];

      return myFields;
    },
  },
  watch: {
    currentLg: function () {},
  },
  methods: {
    getAllUsers(token, graphEndpoint) {
      this.spinner = true;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data["@odata.count"]) {
              this.count = response.data["@odata.count"];
            }
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getAllUsers(token, nextLink);
            } else {
              this.spinner = false;
              this.formatRawData();
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    formatRawData: function () {
      let flatTable = this.rawData.flat(); //Distribution List
      
      let mapData = flatTable.map(f => {

        let lastSignIn = f.signInActivity;
        f.lastSignInDateTime = "";
        f.lastNonInteractiveSignInDateTime = "";

        if (lastSignIn) {
          let lastSignInDateTime = lastSignIn.lastSignInDateTime;
          f.lastSignInDateTime = lastSignInDateTime ? moment(lastSignInDateTime).format("YYYY-MM-DD HH:mm:ss") : "";

          let lastNonInteractiveSignInDateTime = lastSignIn.lastNonInteractiveSignInDateTime;
          f.lastNonInteractiveSignInDateTime = lastNonInteractiveSignInDateTime ? moment(lastNonInteractiveSignInDateTime).format("YYYY-MM-DD HH:mm:ss") : "";
        }

        return f;
      })
      
      this.tableData = mapData;
    },
    resetTable: function () {
      this.search = "";
    },
    openmodal: function (id, value) {
      this.dataFromTable = value;
      this.$bvModal.show(id);
    },
    getAutotableBody: function () {
      let all = [];
      let data = this.items.map(i => {return i});

      let header = [
        "Display name",
        "Mail",
        "Office location",
        "Last SignIn",
        "Last Non Interactive SignIn",
        "Creation"
      ];
      
      all.push(header);
      all.push(["", "","", "", "", ""]);

      for (let i = 0; i < data.length; i++) {
        let dataArray = [];
        let creation = data[i].createdDateTime ? moment(data[i].createdDateTime).format("YYYY-MM-DD HH:mm:ss") : "";
        dataArray.push(
          data[i].displayName,
          data[i].userPrincipalName,
          data[i].officeLocation,
          data[i].lastSignInDateTime,
          data[i].lastNonInteractiveSignInDateTime,
          creation
        );

        all.push(dataArray);
      }

      return all;
    },
    getRange: function () {
      //De A à Z correspond au nombre de champs dans l'entête (nom, courriel, consent, etc.).
      let excelList = this.getAutotableBody();
      return "A1:F" + excelList.length;
    },
  },
  components: {
    ExcelReport,
    LastSignInDetails,
  },
};
</script>
