<template>
  <b-container>
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand v-on:click="reload" mx-auto class="logo">
        <img
          v-bind:src="
            $i18n.locale === 'fr'
              ? 'Images/cogir-rev-fr-110x110.png'
              : 'Images/cogir-rev-en-110x110.png'
          "
          class="d-inline-block align-top"
          alt="logo cogir"
        />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="user" class="ml-auto">
          <b-nav-item v-if="getAppRole(user.AppRole, 1)" v-on:click="goTo('Admin')">{{
            $t("label.admin")
          }}</b-nav-item>
          <b-nav-item v-if="getAppRole(user.AppRole, 3)" v-on:click="goTo('AdminCpt')">{{
            $t("label.admin")
          }}</b-nav-item>
          <b-nav-item v-on:click="goTo('UserForm')">{{
            $t("label.userForm")
          }}</b-nav-item>
          <b-nav-item v-on:click="goTo('UserDirectory')">{{
            $t("label.userDirectory")
          }}</b-nav-item>
          <b-nav-item v-on:click="goTo('AccessTabs')">{{
            $t("label.accessForm")
          }}</b-nav-item>
          <b-nav-item-dropdown right v-if="user">
            <template v-slot:button-content>
              <em>{{ user.displayName }}</em>
            </template>
            <b-dropdown-item v-on:click="logOut()">{{
              $t("action.logout")
            }}</b-dropdown-item>
            <b-dropdown-item v-on:click="getLang('en')">{{
              $t("label.english")
            }}</b-dropdown-item>
            <b-dropdown-item v-on:click="getLang('fr')">{{
              $t("label.french")
            }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right v-if="user">
            <template v-slot:button-content>
              <em>{{ $t("label.externalLink") }}</em>
            </template>
            <b-dropdown-item href="https://aka.ms/ssprsetup" target="_blank">{{
              $t("label.passwordReset")
            }}</b-dropdown-item>
            <b-dropdown-item href="https://aka.ms/mfasetup" target="_blank">{{
              $t("label.multifactorsAuth")
            }}</b-dropdown-item>
            <b-dropdown-item
              href="https://teams.microsoft.com/_#"
              target="_blank"
              >{{ $t("label.teams") }}</b-dropdown-item
            >
            <b-dropdown-item
              href="https://outlook.office.com/mail/"
              target="_blank"
              >{{ $t("label.outlook") }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import moment from "moment";
import { globalF } from "./Helpers";
export default {
  mixins: [globalF],
  data: () => ({}),
  mounted() {},
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    getLang: function (val) {
      this.$i18n.locale = val;
      this.$emit("changeLg", val);
    },
    logOut: function () {
      let obj = {
        Email: this.user.mail,
        LogOut: moment().format("YYYY-MM-DD HH:mm:ss"),
        Request: null,
        Submit: null,
      };
      this.updateSession(obj);
      let myMSALObj = this.$store.getters.getmsalObj;
      let currentMicrosoftAccount = this.$store.getters.getMicrosoftAccount;
      if (currentMicrosoftAccount) {
        const logoutRequest = {
          account: myMSALObj.getAccountByHomeId(
            currentMicrosoftAccount.homeAccountId
          ),
        };

        myMSALObj.logoutRedirect(logoutRequest);
      }
    },
    reload: function () {
      window.location.reload(true);
    },
    goTo: function (val) {
      this.$emit("changePage", val);
    },
  },
  components: {},
};
</script>
