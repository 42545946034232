<template>
  <b-container>
  <b-form @submit="onSubmit">

  <b-alert show variant="danger">
    <p>{{ $t("info.cloneWarning") }}</p>
    <p>{{ $t("info.cloneModal") }}<span>{{values.Name}}</span></p>
  </b-alert>
  
  <b-button class="mt-4 text-center" type="submit" variant="danger" :disabled="disabled">{{ $t("action.submit") }}</b-button>

    <div v-if="error" class="pt-4">
      <b-alert show variant="info">
        <p>{{ error }}</p>
      </b-alert>
    </div>

  </b-form>
</b-container>
</template>

<script>

import axios from 'axios';
import { globalF } from '../Helpers';

export default {
mixins: [globalF],
props: ['values', 'modalid'],
data() {
  return {
    form: {},
    spinner: false,
    disabled: false,
    error: ""
}},
computed: {
  url: function () {
    return this.getUrl();
  }
 },
methods:{
    onSubmit(evt) {
      evt.preventDefault();

        this.spinner = true;
        this.form.request = "cloneCampaign";
        this.form.UserId = this.values.UserId;
        this.form.IdCampaign = this.values.Id;

        axios.post(this.url, this.form, { headers: {'Content-Type': 'application/json'}})
         .then((response)  => {
           this.disabled = false;
           this.spinner = false;
           
           if (response.data === 0) {
             this.$bvModal.hide(this.modalid);
           }
           else {
             this.error = this.$t('info.errorgeneric');
           }
         })
         .catch(()  => {
           this.error = this.$t('info.errorgeneric');
           this.disabled = false;
           this.spinner = false;
         });
  }
}
}
</script>
