<template>
  <b-container>
    <b-row class="mt-4">
      <b-col sm="6">
        <b-alert show variant="info">
          <p>{{ $t("info.warningAccessForm") }}</p>
        </b-alert>
      </b-col>
    </b-row>
    <div class="mt-4">
      <!-- Access request / Access modification radio button -->
      <h5 class="mt-4">{{ $t("label.accessFormTitle") }}</h5>

      <b-form-group>
        <b-form-radio v-model="requestType" name="requestType" value="new"
          >{{ $t("label.newAccess") }}
          <span v-b-tooltip.hover :title="$t('tooltip.newAccess')"
            >&#9432;</span
          ></b-form-radio
        >
        <b-form-radio
          v-model="requestType"
          name="requestType"
          value="modification"
          >{{ $t("label.accesModification") }}
          <span v-b-tooltip.hover :title="$t('tooltip.accesModification')"
            >&#9432;</span
          >
        </b-form-radio>
      </b-form-group>

      <b-row v-if="requestType === 'modification'">
        <b-col sm="6" class="mt-4">
          <v-select
            v-model="existingUser"
            :options="users"
            @search="searchUser"
            :placeholder="
              searchByMail === 'yes'
                ? $t('action.chooseUserByMail')
                : $t('action.chooseUser')
            "
            :label="searchByMail === 'yes' ? 'mail' : 'displayName'"
          >
          </v-select>
          <small>&#9432; {{ $t("info.searchByName") }}</small>
        </b-col>
      </b-row>

      <b-row v-if="requestType === 'modification'">
        <b-col sm="6" class="mt-1">
          <b-form-checkbox
            class="mt-1"
            v-model="searchByMail"
            name="searchByMail"
            value="yes"
            unchecked-value="no"
            >{{ $t("label.searchByMail") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <hr />

      <!-- Acces request / Access modification forms -->
      <b-form
        v-if="requestType === 'new' || requestType === 'modification'"
        @submit="onSubmit"
      >
        <b-row>
          <b-col sm class="mt-4">
            <div>
              <strong>{{ $t("label.fname") }}</strong>
            </div>
            <b-form-input
              v-model.trim="$v.form.fname.$model"
              :placeholder="$t('label.fname')"
            ></b-form-input>
            <div
              class="text-danger"
              v-if="!$v.form.fname.required && $v.form.fname.$dirty"
            >
              {{ $t("info.required") }}
            </div>
            <div
              class="text-danger"
              v-if="!$v.form.fname.maxLength && $v.form.fname.$dirty"
            >
              {{ $t("info.maxLength", [75]) }}
            </div>
          </b-col>
          <b-col sm class="mt-4">
            <div>
              <strong>{{ $t("label.lname") }}</strong>
            </div>
            <b-form-input
              v-model.trim="$v.form.lname.$model"
              :placeholder="$t('label.lname')"
            ></b-form-input>
            <div
              class="text-danger"
              v-if="!$v.form.lname.required && $v.form.lname.$dirty"
            >
              {{ $t("info.required") }}
            </div>
            <div
              class="text-danger"
              v-if="!$v.form.lname.maxLength && $v.form.lname.$dirty"
            >
              {{ $t("info.maxLength", [75]) }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.job") }}</strong>
            </div>
            <b-form-input
              v-model.trim="$v.form.job.$model"
              :placeholder="$t('label.job')"
            ></b-form-input>
            <div
              class="text-danger"
              v-if="!$v.form.job.required && $v.form.job.$dirty"
            >
              {{ $t("info.required") }}
            </div>
            <div
              class="text-danger"
              v-if="!$v.form.job.maxLength && $v.form.job.$dirty"
            >
              {{ $t("info.maxLength", [75]) }}
            </div>
          </b-col>

          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.offices") }}</strong>
            </div>
            <v-select
              v-model.trim="$v.form.office.$model"
              :options="buildings"
              :selectable="(option) => !option.Flag"
              :filter="searchBuilding"
              label="ResName"
              :placeholder="$t('action.chooseBuilding')"
            >
              <template slot="option" slot-scope="option">
                <strong
                  style="color: black"
                  v-if="option.Flag === 'ClassName'"
                  disabled
                  >{{ option.ResName }}</strong
                >
                <span v-else>{{ option.ResName }}</span>
              </template>
            </v-select>
            <div>
              <small>&#9432; {{ $t("info.searchBuilding") }}</small>
            </div>
            <div
              class="text-danger"
              v-if="!$v.form.office.required && $v.form.office.$dirty"
            >
              {{ $t("info.required") }}
            </div>
            <b-button
              variant="link"
              @click="openmodal('sendNotificationEmail')"
              >{{ $t("label.cannotFindMyBuilding") }}</b-button
            >
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.manager") }}</strong> :
              {{ displayManagerName() }}
            </div>

            <v-select
              v-model.trim="$v.form.manager.$model"
              :options="users"
              @search="searchUser"
              :placeholder="
                searchManagerByMail === 'yes'
                  ? $t('action.chooseManagerByMail')
                  : $t('action.chooseManager')
              "
              :label="searchManagerByMail === 'yes' ? 'mail' : 'displayName'"
            >
            </v-select>
            <small>&#9432; {{ $t("info.searchByName") }}</small>
            <b-row>
              <b-col sm="6" class="mt-1">
                <b-form-checkbox
                  class="mt-1"
                  v-model="searchManagerByMail"
                  name="searchManagerByMail"
                  value="yes"
                  unchecked-value="no"
                  >{{ $t("label.searchByMail") }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <div
              class="text-danger"
              v-if="!$v.form.manager.required && $v.form.manager.$dirty"
            >
              {{ $t("info.required") }}
            </div>
          </b-col>
          <b-col sm="6">
            <div class="mt-4">
              <strong>{{ $t("label.communication") }}</strong>
            </div>
            <b-form-select
              v-model.trim="$v.form.communication.$model"
              :options="communication"
            ></b-form-select>
            <div
              class="text-danger"
              v-if="
                !$v.form.communication.required && $v.form.communication.$dirty
              "
            >
              {{ $t("info.required") }}
            </div>
          </b-col>
        </b-row>

        <b-row v-if="requestType === 'new'" class="mt-2">
          <hr />
          <b-col sm>
            <div>
              <strong>{{ $t("label.campaignStartDate") }}</strong>
            </div>
            <datepicker
              input-class="datepicker"
              v-model="form.startDate"
              :placeholder="$t('action.chooseDate')"
              :language="language"
            ></datepicker>
            <div
              class="text-danger"
              v-if="!$v.form.startDate.required && $v.form.startDate.$dirty"
            >
              {{ $t("info.required") }}
            </div>
            <div class="mt-2 text-danger">
              {{ $t("info.businessAdvances") }}
            </div>
          </b-col>
        </b-row>
        <hr />
        <div>
          <b-row>
            <b-col class="mt-2">
              <div class="mt-2">
                <b-row class="mt-2">
                  <b-col sm="6">
                    <div v-if="requestType === 'new'">
                      <strong>{{ $t("label.email") }} </strong> :
                      {{ $t("info.futureEmail") }} :
                      <span>{{
                        newEmailUser.length > 1
                          ? newEmailUser + "@cogir.net"
                          : ""
                      }}</span>
                    </div>
                    <div v-if="requestType === 'modification'">
                      <strong>{{ $t("label.email") }} </strong> :
                      {{ form.email ? form.email : "no email" }}
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-form-checkbox
                id="customEmailFlag"
                v-model="form.customEmailFlag"
                class="mt-2"
                name="customEmailFlag"
                value="yes"
                unchecked-value="no"
              >
                {{ $t("label.customEmail") }}
              </b-form-checkbox>
              <div class="mt-2" v-if="form.customEmailFlag === 'yes'">
                <b-row class="mt-2">
                  <b-col sm="6">
                    <div>
                      <strong>{{ $t("label.nameForEmail") }}</strong>
                    </div>
                    <b-input-group :append="'@' + form.domain">
                      <b-form-input
                        v-model="$v.form.nameForEmail.$model"
                        class="text-right"
                      ></b-form-input>
                    </b-input-group>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.form.nameForEmail.required &&
                        $v.form.nameForEmail.$dirty
                      "
                    >
                      {{ $t("info.required") }}
                    </div>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.form.nameForEmail.maxLength &&
                        $v.form.nameForEmail.$dirty
                      "
                    >
                      {{ $t("info.maxLength", [75]) }}
                    </div>
                  </b-col>
                  <b-col sm="6">
                    <div>
                      <strong>{{ $t("label.domains") }}</strong>
                    </div>
                    <b-form-select
                      v-model="form.domain"
                      :options="domains"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <hr />
        </div>

        <div>
          <strong>{{ $t("label.accessType") }}</strong>
        </div>

        <!-- Acces request / Access modification Checkbox, text box, comments -->
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="softwareAccess"
            v-model="accessRequestList"
            :aria-describedby="ariaDescribedby"
            name="remote"
            stacked
          >
            <b-form-checkbox
              v-if="form.office && form.office.Class === 10"
              value="vpn"
              >VPN</b-form-checkbox
            >
            <b-form-checkbox
              v-if="form.office && form.office.Class === 10 && form.office.Id === 304"
              value="accesscardmtl"
              >Carte d'accès Montréal</b-form-checkbox
            >
            <b-form-checkbox value="remote"
              >{{ $t("label.remoteAccess") }}
              <span v-b-tooltip.hover :title="$t('tooltip.portailRemoteApp')"
                >&#9432;</span
              ></b-form-checkbox
            >
            <b-form-checkbox value="agile">Agile</b-form-checkbox>
            <b-row v-if="showInput('agile')">
              <b-col sm="6" class="m-2">
                <hr />
                <!-- Department -->
                <strong>{{ $t("label.department") }} :</strong>
                <b-form-input
                  v-model.trim="$v.form.department.$model"
                  :placeholder="$t('action.department')"
                ></b-form-input>
                <div
                  class="text-danger"
                  v-if="
                    !$v.form.department.required && $v.form.department.$dirty
                  "
                >
                  {{ $t("info.required") }}
                </div>
                <div
                  class="text-danger"
                  v-if="
                    !$v.form.department.maxLength && $v.form.department.$dirty
                  "
                >
                  {{ $t("info.maxLength", [75]) }}
                </div>
                <!-- Salary view -->
                <strong>{{ $t("label.salaryView") }} :</strong>
                <b-form-group>
                  <b-form-radio
                    v-model="form.salaryView"
                    name="salaryView"
                    value="yes"
                    >{{ $t("label.yes") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="form.salaryView"
                    name="salaryView"
                    value="no"
                    >{{ $t("label.no") }}</b-form-radio
                  >
                </b-form-group>
                <!-- Send Access To -->
                <strong>{{ $t("label.sendAccessTo") }} :</strong>
                <b-form-input
                  v-model.trim="$v.form.sendAccessTo.$model"
                  :placeholder="$t('action.sendAccessTo')"
                ></b-form-input>
                <div
                  class="text-danger"
                  v-if="
                    !$v.form.sendAccessTo.required &&
                    $v.form.sendAccessTo.$dirty
                  "
                >
                  {{ $t("info.required") }}
                </div>
                <div
                  class="text-danger"
                  v-if="
                    !$v.form.sendAccessTo.maxLength &&
                    $v.form.sendAccessTo.$dirty
                  "
                >
                  {{ $t("info.maxLength", [75]) }}
                </div>
                <hr />
              </b-col>
            </b-row>

            <b-form-checkbox
              v-for="cs in collaboratorService"
              :key="cs.id"
              :value="cs.Service"
              >{{ currentLg === "en" ? cs.En : cs.Fr }}</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-form-group>

        <!-- Other software -->
        <b-row>
          <b-col sm="6" class="mt-2">
            <div>{{ $t("label.otherSoftware") }} :</div>
            <b-form-input
              v-model.trim="form.otherSoftware"
              :placeholder="$t('action.otherSoftware')"
            ></b-form-input>
          </b-col>
        </b-row>

        <hr />

        <b-row class="mt-2">
          <b-col sm="6">
            <div>
              <strong>{{ $t("label.comments") }} </strong
              ><span v-b-tooltip.hover :title="$t('tooltip.accessFormComments')"
                >&#9432;</span
              >
            </div>
            <b-form-checkbox
              class="mt-1"
              v-model="form.materialNeeds"
              name="materialNeeds"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.materialNeeds") }}
            </b-form-checkbox>
            <b-form-textarea
              class="mt-1"
              id="comments"
              v-model="$v.form.comments.$model"
              :placeholder="
                form.materialNeeds === 'yes'
                  ? $t('label.materialNeedsInfo')
                  : ''
              "
              rows="5"
              max-rows="10"
            ></b-form-textarea>
            <div
              class="text-danger"
              v-if="!$v.form.comments.maxLength && $v.form.comments.$dirty"
            >
              {{ $t("info.maxLength", [1000]) }}
            </div>
          </b-col>
        </b-row>

        <!-- Acces request / Access modification Add to distribution list -->
        <b-row class="mt-2" v-if="requestType === 'new'">
          <b-col>
            <div class="mt-2">
              <b-alert show variant="info">
                <div>
                  <strong>{{ $t("info.dlForAll") }}</strong>
                </div>

                <div class="mt-2" v-if="corpoDlList.length > 0">
                  <ul>
                    <li v-for="item in corpoDlList" :key="item.id">
                      {{ item.displayName }}
                    </li>
                  </ul>
                </div>

                <div class="mt-2" v-if="additionalDl.length > 0">
                  <ul>
                    <li v-for="item in additionalDl" :key="item.id">
                      {{ item.displayName }}
                    </li>
                  </ul>
                  <b-button variant="link" @click="resetAdditionnalDlList()">{{
                    $t("action.resetTheList")
                  }}</b-button>
                </div>

                <div class="mt-4" v-if="distListFromGraph.length > 0">
                  <b-button
                    variant="outline-info"
                    @click="openmodal('multiSelectTable')"
                    >{{ $t("info.addToDl") }}</b-button
                  >
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <b-button
          v-if="distListFromGraph.length > 0"
          class="mt-4"
          type="submit"
          variant="success"
          :disabled="disabled"
          >{{ $t("action.submit") }}</b-button
        >

        <b-modal
          id="multiSelectTable"
          size="xl"
          :title="$t('label.distributionList')"
          hide-footer
        >
          <MultiSelectTable
            ref="multiSelectDl"
            :tableDataFromProp="distListFromGraph"
            :fields="fieldsForMultipleSelectTable"
            tableName="distributionList"
            :corpoDlList="corpoDlList"
          />
        </b-modal>

        <b-modal
          id="sendNotificationEmail"
          :title="$t('label.emailNotification')"
          hide-footer
        >
          <SendNotificationEmail modalid="sendNotificationEmail" />
        </b-modal>

        <div v-if="spinner" class="text-center align-middle">
          <b-spinner
            style="width: 3rem; height: 3rem"
            variant="success"
            label="Spinning"
            disabled="labelStatus"
          ></b-spinner>
        </div>

        <b-row v-if="success" class="text-center pt-4">
          <b-col>
            <b-alert show variant="success">
              <p>{{ $t("info.successgeneric") }}</p>
            </b-alert>
          </b-col>
        </b-row>

        <b-row v-if="existingEmail" class="pt-4">
          <b-col>
            <b-alert show variant="danger">
              <strong>{{ $t("info.existingEmail") }}</strong>
              <ul>
                <li>{{ existingEmail.displayName }}</li>
                <li>{{ existingEmail.mail }}</li>
              </ul>
            </b-alert>
          </b-col>
        </b-row>

        <b-row v-if="error" class="mt-2">
          <b-col sm class="mt-4">
            <b-alert show variant="danger">
              <p>{{ error }}</p>
            </b-alert>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { required, maxLength } from "vuelidate/lib/validators";
import SendNotificationEmail from "../Modal/SendNotificationEmail";
import MultiSelectTable from "./MultipleSelectTable.vue";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      searchByMail: "no",
      searchManagerByMail: "no",
      allDomains: [],
      domains: [],
      accessRequestList: [],
      distListFromGraph: [],
      rawData: [],
      form: {
        id: null,
        fname: "",
        lname: "",
        email: null,
        job: "",
        office: null,
        manager: "",
        communication: null,
        startDate: "",
        customEmail: "",
        customEmailFlag: "no",
        nameForEmail: "",
        agileRequest: false,
        department: "",
        salaryView: "no",
        sendAccessTo: "",
        otherSoftware: "",
        materialNeeds: "no",
        comments: "",
        domain: "cogir.net",
      },
      requestType: "",
      managers: [],
      spinner: false,
      success: false,
      disabled: false,
      users: [],
      existingUser: null,
      buildings: [],
      error: "",
      info: "",
      addToDl: "no",
      existingEmail: null,
    };
  },
  validations() {
    let form = {
      fname: {
        required,
        maxLength: maxLength(75),
      },
      lname: {
        required,
        maxLength: maxLength(75),
      },
      job: {
        required,
        maxLength: maxLength(75),
      },
      manager: { required },
      communication: { required },
      office: { required },
      comments: { maxLength: maxLength(1000) },
    };

    if (this.requestType === "new") {
      form.startDate = { required };
    }

    if (this.form.customEmailFlag === "yes") {
      form.nameForEmail = { required, maxLength: maxLength(75) };
    }

    if (this.showInput("agile")) {
      form.department = { required, maxLength: maxLength(75) };
      form.sendAccessTo = { required, maxLength: maxLength(75) };
    }

    return { form };
  },
  mounted() {
    let token = this.$store.getters.getAppAccessToken;
    this.getDomains(token);
    this.$store.commit("addAdditionnalDlList", []);
    let graphEndpoint = "https://graph.microsoft.com/v1.0/groups";
    this.getGroups(token, graphEndpoint);
    this.getBuildingList();
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    collaboratorService: function () {
      let cs = this.$store.getters.getCollaboratorService;
      let filterCs = cs.filter(
        (c) =>
          c.Service !== "vpn" && c.Service !== "remote" && c.Service !== "agile" && c.Service !== "accesscardmtl"
      );
      filterCs.sort((a, b) => {
        if (a.Service < b.Service) {
          return -1;
        }
        if (a.Service > b.Service) {
          return 1;
        }
        return 0;
      });
      return filterCs;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    additionalDl: function () {
      return this.$store.getters.getAdditionnalDlList;
    },
    corpoDlList: function () {
      let corpoDlList = [];

      if (this.form.office) {
        let officeClass = this.form.office.Class;
        let officeId = this.form.office.Id;
        //à mettre en bd
        if (officeClass === 10 && officeId === 302) {
          corpoDlList = this.getCorpoList("dist_Cogir-Brossard");
        }
        if (officeClass === 10 && officeId === 303) {
          corpoDlList = this.getCorpoList("dist_Cogir-Quebec");
        }
        if (officeClass === 10 && officeId === 304) {
          corpoDlList = this.getCorpoList("dist_Cogir-Montreal");
        }
        if (officeClass === 10 && officeId === 305) {
          corpoDlList = this.getCorpoList("dist_Cogir-Whitby");
        }
      }

      return corpoDlList;
    },
    communication: function () {
      return [
        { value: null, text: this.$t("label.language") },
        { value: "fr-FR", text: this.$t("label.french") },
        { value: "en-US", text: this.$t("label.english") },
      ];
    },
    fieldsForMultipleSelectTable: function () {
      return [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "mail", sortable: true, label: this.$t("label.email") },
        {
          key: "createdDateTime",
          sortable: true,
          label: this.$t("label.creation"),
        },
        { key: "Details", label: this.$t("label.details") },
      ];
    },
    newEmailUser: function () {
      let firstName = this.form.fname.trim();
      let lastName = this.form.lname.trim();
      let firstLetterName = firstName.charAt(0);

      let emailToSuggest = firstLetterName + lastName;

      let defaultEmail = emailToSuggest
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z]+/g, "")
        .toLowerCase();

      return defaultEmail;
    },
  },
  watch: {
    currentLg: function () {
      this.actionCall("building");
    },
    existingUser: function (user) {
      if (user) {
        this.form.id = user.id;
        this.form.fname = user.givenName;
        this.form.lname = user.surname;
        this.form.email = user.mail;
        this.form.job = user.jobTitle;
        this.form.communication = user.preferredLanguage;
        this.form.office = this.getOfficeData(user.officeLocation);
        this.form.manager = user.manager;
      } else {
        this.form.id = null;
        this.form.fname = "";
        this.form.lname = "";
        this.form.email = null;
        this.form.job = "";
        this.form.communication = null;
        this.form.office = null;
        this.form.manager = "";
      }
    },
    requestType: function (/*value*/) {
      this.disabled = false;
      this.success = false;
      this.error = "";
      this.existingEmail = null;

      this.form.id = null;
      this.form.fname = "";
      this.form.lname = "";
      this.form.email = null;
      this.form.job = "";
      this.form.communication = null;
      this.form.office = null;

      this.form.manager = "";
      this.form.startDate = "";
      this.form.customEmailFlag = "no";
      this.form.nameForEmail = "";
      this.form.customEmail = "";
      this.form.customEmailFlag = "no";
      this.form.domain = "cogir.net";
      this.form.agileRequest = false;
      this.form.department = "";
      this.form.salaryView = "no";
      this.form.sendAccessTo = "";
      this.form.otherSoftware = "";
      this.form.comments = "";
      this.form.materialNeeds = "no";

      this.accessRequestList = [];
    },
  },
  methods: {
    getDomains(token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/domains`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let allDomains = response.data.value;
          this.allDomains = response.data.value;
          if (allDomains) {
            this.domains = allDomains.map((d) => {
              return {
                value: d.id,
                text: d.id,
              };
            });
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getCorpoList: function (corpoName) {
      return this.distListFromGraph.filter(
        (dl) => dl.displayName === corpoName
      );
    },
    resetAdditionnalDlList: function () {
      this.$store.commit("addAdditionnalDlList", []);
    },
    getGroups: function (token, graphEndpoint) {
      this.spinner = true;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getGroups(token, nextLink);
            } else {
              let flatList = this.rawData.flat();
              this.distListFromGraph = flatList;

              this.spinner = false;
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    showInput: function (value) {
      return this.accessRequestList.includes(value);
    },
    getOfficeData: function (officeName) {
      let building = null;
      if (officeName) {
        let buildings = this.$store.getters.getBuildings;
        building = buildings.find((b) => b.ResName === officeName);
      }

      return building ? building : null;
    },
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    searchUser: function (search) {
      this.actionCall("allUsers", search);
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;
      let promise = this.validateToken();
      promise.then((value) => {
        if (value) {
          if (type === "allUsers") {
            this.searchUsersBySearchText(value, searchText);
          }
          if (type === "building") {
            this.getBuildingList();
          }
        }
      });
    },
    getManager(token) {
      let graphEndpoint = "https://graph.microsoft.com/v1.0/me/manager";
      return axios.get(graphEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          ConsistencyLevel: "eventual",
        },
      });
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapBuilding(buildings);
    },
    mapBuilding: function (buildings) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < buildings.length; i++) {
        let find = uniqueClass.find((c) => c === buildings[i].Class);
        if (!find) {
          uniqueClass.push(buildings[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let buildingsListByClassWithLabel = [];

      for (let i = 0; i < uniqueClass.length; i++) {
        let blist = buildings.filter((b) => b.Class === uniqueClass[i]);
        let className =
          lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
        blist.unshift({ ResName: className, Flag: "ClassName" });
        buildingsListByClassWithLabel.push(blist);
      }

      this.buildings = buildingsListByClassWithLabel.flat();
    },
    displayManagerName: function () {
      let manager = this.form.manager;
      let name = "";
      if (manager) {
        name = manager.displayName ? manager.displayName : "";
      }
      return name;
    },
    openmodal: function (id) {
      this.$bvModal.show(id);
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    setNextStep(step) {
      this.$emit("changeStep", step);
    },
    getEmailForUser: function () {
      let emailToValidate = null;
      if (this.form.customEmailFlag === "yes") {
        emailToValidate = this.form.nameForEmail + "@" + this.form.domain;
      } else {
        let firstName = this.form.fname.trim();
        let lastName = this.form.lname.trim();
        let firstLetterName = firstName.charAt(0);

        let emailToSuggest = firstLetterName + lastName;

        let emailFirstPart = emailToSuggest
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z]+/g, "")
          .toLowerCase();

        emailToValidate = emailFirstPart + "@cogir.net";
      }
      return emailToValidate;
    },
    getUser(token) {
      let emailToValidate = this.getEmailForUser();
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${emailToValidate}`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          this.existingEmail = response.data;
          this.disabled = false;
          this.spinner = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              this.setAccessForm();
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        });
    },
    setAccessForm: function () {
      this.form.requesterId = this.user.id;
      this.form.requesterName = this.user.displayName;
      this.form.requesterEmail = this.user.mail;
      this.form.idBuilding = this.form.office.Id;
      this.form.managerId = this.form.manager.id;
      this.form.managerName = this.form.manager.displayName;
      this.form.managerEmail = this.form.manager.mail;
      this.form.startDate = this.form.startDate
        ? moment(this.form.startDate).format("YYYY-MM-DD")
        : "";
      this.form.accessRequest = this.accessRequestList;
      this.form.agileRequest = this.showInput("agile");

      if (this.form.customEmailFlag === "yes") {
        this.form.customEmail = this.form.nameForEmail + "@" + this.form.domain;
      } else {
        let firstName = this.form.fname.trim();
        let lastName = this.form.lname.trim();
        let firstLetterName = firstName.charAt(0);

        let emailToSuggest = firstLetterName + lastName;

        this.form.nameForEmail = emailToSuggest
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z]+/g, "")
          .toLowerCase();
      }

      this.form.request = "setAccessFormRequest";
      this.form.requestType = this.requestType;
      this.form.subject =
        this.requestType === "new" ? "Access request" : "Access Modification";

      this.form.distributionList = this.additionalDl.map((s) => {
        return s.id;
      });

      if (this.existingUser) {
        this.form.existingUser = this.existingUser;
      }

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          this.spinner = false;
          if (response.data === 0) {
            this.$emit("changePage", "SuccessConfirmation");
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.error = "";
      this.existingEmail = null;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.disabled = true;
        this.spinner = true;
        if (this.requestType === "new") {
          let token = this.$store.getters.getAppAccessToken;
          this.getUser(token);
        }
        if (this.requestType === "modification") {
          this.setAccessForm();
        }
      }
    },
  },
  components: {
    SendNotificationEmail,
    Datepicker,
    MultiSelectTable,
  },
};
</script>