<template>
    <b-container>
        <b-form @submit="onSubmit">
            <b-row>
                <b-col sm="4" class="mt-2">
                    <div>
                        <strong>{{ $t("label.hourlyRate") }}</strong>
                    </div>
                    <b-form-input v-model="form.HourlyRate" type="number"
                        :placeholder="$t('label.hourlyRate')"></b-form-input>
                    <div class="text-danger" v-if="!$v.form.HourlyRate.required && $v.form.HourlyRate.$dirty">
                        {{ $t("info.required") }}
                    </div>
                    <div class="text-danger" v-if="!$v.form.HourlyRate.maxValue && $v.form.HourlyRate.$dirty">
                        {{ $t("info.maxValue", [1000]) }}
                    </div>
                    <div class="text-danger" v-if="!$v.form.HourlyRate.minValue && $v.form.HourlyRate.$dirty">
                        {{ $t("info.minValue", [0]) }}
                    </div>
                </b-col>

            </b-row>

            <b-button class="mt-4 text-center" type="submit" variant="success" :disabled="disabled">{{ $t("action.submit")
            }}</b-button>

            <div v-if="error" class="pt-4">
                <b-alert show variant="danger">
                    <p>{{ $t("info.errorgeneric", [error]) }}</p>
                </b-alert>
            </div>

        </b-form>
    </b-container>
</template>
  
<script>

import axios from 'axios';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { globalF } from '../Helpers';

export default {
    mixins: [globalF],
    props: ['currentHourlyRate', 'modalid'],
    data() {
        return {
            form: {
                HourlyRate: this.getData(),
            },
            spinner: false,
            disabled: false,
            error: ""
        }
    },
    validations() {
        return {
            form: {
                HourlyRate: { required, minValue: minValue(0), maxValue: maxValue(1000) }
            },
        };
    },
    computed: {
        url: function () {
            return this.getUrl();
        },
    },
    methods: {
        getData: function () {
            let configObj = this.currentHourlyRate;
            return parseInt(configObj.Value);
        },
        onSubmit(evt) {
            evt.preventDefault();
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.spinner = true;
                this.form.request = "updateCurrentHourlyRate";
                this.form.Key = this.currentHourlyRate.Key;
                this.form.Id = this.currentHourlyRate.Id;
                this.form.CurrentHourlyRate = parseInt(this.currentHourlyRate.Value);

                axios.post(this.url, this.form, { headers: { 'Content-Type': 'application/json' } })
                    .then(() => {
                        this.disabled = false;
                        this.spinner = false;
                        this.$bvModal.hide(this.modalid);
                    })
                    .catch(() => {
                        this.error = this.$t('info.errorgeneric');
                        this.disabled = false;
                        this.spinner = false;
                    });
            }
        }
    }
}
</script>
  