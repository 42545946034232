<template>
  <b-container>
    <div>
      <b-form @submit="onSubmit">
        <b-button
          class="mt-4"
          type="submit"
          variant="success"
          :disabled="disabled"
          >{{ $t("action.submit") }}</b-button
        >

        <div v-if="spinner" class="text-center align-middle">
          <b-spinner
            style="width: 3rem; height: 3rem"
            variant="success"
            label="Spinning"
            disabled="labelStatus"
          ></b-spinner>
        </div>

        <b-row v-if="success" class="text-center pt-4">
          <b-col>
            <b-alert show variant="success">
              <p>{{ $t("info.successgeneric") }}</p>
            </b-alert>
          </b-col>
        </b-row>

        <b-row v-if="error" class="mt-2">
          <b-col sm class="mt-4">
            <b-alert show variant="danger">
              <p>{{ error }}</p>
            </b-alert>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
//import moment from "moment";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      userList: [],
      index: 1,
      accessFormLogObj: {},
      form: {
        password: "",
      },
    };
  },
  mounted() {
    this.getUserList();
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    token: function () {
      return this.$store.getters.getAppAccessToken;
    },
  },
  methods: {
    getUserList: function () {
      this.spinner = true;
      axios
        .post(this.url, { request: "getBatchUserTable" }, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          this.spinner = false;
          this.userList = response.data;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    setNewPassword: function () {
      this.form.password = this.generatePassword();
    },
    generatePassword() {
      let characters = "a-z,A-Z,0-9";
      let passwordSize = 5;

      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      /*if (charactersArray.indexOf("#") >= 0) {
        //CharacterSet += "![]{}()%&*$#^<>~@|";
        CharacterSet += "!%&*$#@";
      }*/
      for (let i = 0; i < passwordSize; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }

      password += "6xV";

      return password.charAt(0).toUpperCase() + password.slice(1);
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.disabled = true;
      this.spinner = true;
      //this.deleteUser();
      //this.createUser(this.index);
      //this.blockUser(this.index);
      this.getUserLicenses(this.index);
      /*if (this.index < 300) {
        this.createUser(this.index);
      } else {
        this.spinner = false;
        this.success = true;
      }*/
    },
    createUser(ind) {
      let user = this.userList[ind];

      this.accessFormLogObj.currentUser = user;

      let displayName = user.DisplayName; 
      let mail = user.NewCogirEmail; 

      let indexFirstPartEmail = mail.indexOf("@");
      let mailNickname = mail.slice(0, indexFirstPartEmail);
      
      let title = user.Title; 
      let building = user.Building; 

      this.form.password = this.generatePassword();

      let newUser = {
        accountEnabled: true,
        displayName: displayName,
        mailNickname: mailNickname,
        userPrincipalName: mail,
        mail: mail,
        passwordProfile: {
          forceChangePasswordNextSignIn: false,
          password: this.form.password,
        },
        jobTitle: title,
        officeLocation: building,
        country: "Canada",
        usageLocation: "CA",
        preferredLanguage: "fr-FR",
      };

      this.accessFormLogObj.newUser = newUser;

      let token = this.$store.getters.getAppAccessToken;
      let graphEndpoint = "https://graph.microsoft.com/v1.0/users";

      axios
        .post(graphEndpoint, newUser, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //this.spinner = false;
          if (response.status === 201) {
            this.accessFormLogObj.createUser = response.data;
            let userId = response.data.id;

            let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${userId}/assignLicense`;
            let licensesListObj = this.getLicensesListObj();
            this.accessFormLogObj.licensesListObj = licensesListObj;
            this.assignLicense(token, licensesListObj, graphEndpoint, userId);
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorCreateUser");
          if (error.response) {
            this.error = error.response.data;
          }
        });
    },
    getLicensesListObj() {
      let userLicenses = [{"skuId": "f245ecc8-75af-4f8e-b61f-27d8114de5f3"}]; //Business Standard
      let licenses = userLicenses.map((l) => {
        return {
          skuId: l.skuId,
        };
      });

      return {
        addLicenses: licenses,
        removeLicenses: [],
      };
    },
    assignLicense(token, obj, graphEndpoint) {
      axios
        .post(graphEndpoint, obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.accessFormLogObj.assignLicense = response.data;
            this.logCreateUserRequest();
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
        });
    },
    deleteUser() {
      let token = this.$store.getters.getAppAccessToken;
      let userId = "5a73a143-8a9a-4f28-a4d5-8de2de44ea63";
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${userId}`;

      axios
        .delete(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          this.spinner = false;
          this.disabled = false;
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
        });
    },
    blockUser(ind) {
      let token = this.$store.getters.getAppAccessToken;
      let user = this.userList[ind];
      this.accessFormLogObj.currentUser = user;

      user.exDisplayName = "*Ex- " + user.DisplayName;
      let obj = {
        displayName: user.exDisplayName,
        accountEnabled: false,
      };

      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.NewCogirEmail}`;
      axios
        .patch(graphEndpoint, obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.accessFormLogObj.blockUser = response;
          this.logCreateUserRequest();
          //this.convertMailBox(token);
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          //this.form.accessFormLogObj.blockUser = error;
          //this.logTerminatedUserError();
        });
    },
    getUserLicenses(ind) {
      let token = this.$store.getters.getAppAccessToken;
      let user = this.userList[ind];
      this.accessFormLogObj.currentUser = user;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.NewCogirEmail}/licenseDetails`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          let userLicenses = response.data.value;
          if (userLicenses.length > 0) {
            let licensesToRemove = userLicenses.map((l) => {
              return l.skuId;
            });
            this.removeLicense(user, licensesToRemove);
          } else {
            this.accessFormLogObj.removeLicense = response.data;
            this.logCreateUserRequest();
          }
          
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    removeLicense(user, licensesToRemove) {
      let token = this.$store.getters.getAppAccessToken;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.NewCogirEmail}/assignLicense`;
      let obj = {
        addLicenses: [],
        removeLicenses: licensesToRemove,
      }
      axios
        .post(graphEndpoint, obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.accessFormLogObj.removeLicense = response.data;
            this.logCreateUserRequest();
          }
        })
        .catch((error) => {
          this.spinner = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
        });
    },
    logCreateUserRequest: function () {
      //this.disabled = true;
      //this.spinner = true;

      this.form.request = "updateBatchUserTable";
      this.form.accessFormLogObj = this.accessFormLogObj;

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          //this.spinner = false;
          //this.success = true;
          this.index += 1;
          console.log(this.index);
          this.getUserLicenses(this.index);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
  }
};
</script>