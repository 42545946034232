import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'

import Vuelidate from 'vuelidate'
import { i18n } from './i18n.js'
import { router } from './vuerouter.js'
import { store } from './vuexstore.js'
import './custom.scss'

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect);

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
