<template>
  <div>
    <b-form @submit="onSubmit">
      <b-row v-if="!values">
        <b-col sm="6" class="mt-4">
          <vue-google-autocomplete
            id="map"
            classname="form-control"
            :placeholder="$t('action.search')"
            v-on:placechanged="getAddressData"
            :country="['ca', 'us']"
          >
          </vue-google-autocomplete>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.resName") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.ResName.$model"
            :placeholder="$t('label.resName')"
            :readonly="fieldReadOnly"
            :formatter="toUppercase"

          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.ResName.required && $v.form.ResName.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.ResName.maxLength && $v.form.ResName.$dirty"
          >
            {{ $t("info.maxLength", [100]) }}
          </div>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.street") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.Street.$model"
            :placeholder="$t('label.street')"
            :readonly="fieldReadOnly"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.Street.required && $v.form.Street.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.Street.maxLength && $v.form.Street.$dirty"
          >
            {{ $t("info.maxLength", [100]) }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.city") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.City.$model"
            :placeholder="$t('label.city')"
            :readonly="fieldReadOnly"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.City.required && $v.form.City.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.City.maxLength && $v.form.City.$dirty"
          >
            {{ $t("info.maxLength", [100]) }}
          </div>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.zip") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.Zip.$model"
            :placeholder="$t('label.zip')"
            :readonly="fieldReadOnly"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.Zip.required && $v.form.Zip.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.Zip.maxLength && $v.form.Zip.$dirty"
          >
            {{ $t("info.maxLength", [8]) }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.Zip.minLength && $v.form.Zip.$dirty"
          >
            {{ $t("info.minLength", [6]) }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.province") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.State.$model"
            :placeholder="$t('label.province')"
            :readonly="fieldReadOnly"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.State.required && $v.form.State.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.State.maxLength && $v.form.State.$dirty"
          >
            {{ $t("info.maxLength", [100]) }}
          </div>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.country") }}</strong>
          </div>
          <b-form-input
            v-model.trim="$v.form.Country.$model"
            :placeholder="$t('label.country')"
            :readonly="fieldReadOnly"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.Country.required && $v.form.Country.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.Country.maxLength && $v.form.Country.$dirty"
          >
            {{ $t("info.maxLength", [100]) }}
          </div>
        </b-col>
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.countryCode") }}</strong>
          </div>
          <b-form-select
            v-model.trim="$v.form.CountryCode.$model"
            :options="countryCodeList"
            :disabled="fieldReadOnly"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.form.CountryCode.required && $v.form.CountryCode.$dirty"
          >
            {{ $t("info.required") }}
          </div>
        </b-col>
        
      </b-row>
      <b-row>
        <b-col sm="4" class="mt-4">
          <div>
            <strong>{{ $t("label.className") }}</strong>
          </div>
          <b-form-select
            v-model.trim="$v.form.Class.$model"
            :options="classNames"
            :disabled="fieldReadOnly"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.form.Class.required && $v.form.Class.$dirty"
          >
            {{ $t("info.required") }}
          </div>
        </b-col>
        <b-col sm="4" class="mt-4">
          <div>
            <strong>{{ $t("label.codeFacturation") }}</strong>
          </div>
          <b-form-input
            v-model="form.CodeFacturation"
            :placeholder="$t('label.codeFacturation')"
          ></b-form-input>
        </b-col>
        <b-col sm="4" class="mt-4">
          <div>
            <strong>{{ $t("label.hourlyRate") }}</strong>
          </div>
          <b-form-input
            v-model="form.HourlyRate"
            type="number"
            :placeholder="$t('label.hourlyRate')"
            :readonly="!fieldReadOnly"
          ></b-form-input>
          <div
            class="text-danger"
            v-if="!$v.form.HourlyRate.required && $v.form.HourlyRate.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.HourlyRate.maxValue && $v.form.HourlyRate.$dirty"
          >
            {{ $t("info.maxValue", [1000]) }}
          </div>
          <div
            class="text-danger"
            v-if="!$v.form.HourlyRate.minValue && $v.form.HourlyRate.$dirty"
          >
          {{ $t("info.minValue", [0]) }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="mt-4">
          <b-form-checkbox
            id="solulan"
            v-model="form.Solulan"
            name="solulan"
            value="1"
            unchecked-value="0"
            :disabled="fieldReadOnly"
          >
            Solulan
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-button
        class="mt-4"
        type="submit"
        variant="success"
        :disabled="disabled"
        >{{ $t("action.submit") }}</b-button
      >
    </b-form>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="info" class="text-center pt-4">
      <b-col>
        <b-alert show variant="info">
          <p>{{ info }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, maxLength, minLength, minValue, maxValue } from "vuelidate/lib/validators";
import "vue-search-select/dist/VueSearchSelect.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["values", "type", "modalid", "readOnlyForm"],
  data() {
    return {
      form: {
        ResName: this.getData("ResName", ""),
        Street: this.getData("Street", ""),
        City: this.getData("City", ""),
        State: this.getData("State", ""),
        Country: this.getData("Country", ""),
        CountryCode: this.getData("CountryCode"),
        Zip: this.getData("Zip", ""),
        Class: this.getData("Class", null),
        Solulan: this.getData("Solulan", "0"),
        CodeFacturation: this.getData("CodeFacturation", ""),
        HourlyRate: this.getData("HourlyRate", null),
      },
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
    };
  },
  validations() {
    return {
      form: {
        ResName: { required, maxLength: maxLength(100) },
        //Address: {maxLength: maxLength(200)},
        Street: { required, maxLength: maxLength(100) },
        City: { required, maxLength: maxLength(100) },
        State: { required, maxLength: maxLength(100) },
        Country: { required, maxLength: maxLength(100) },
        CountryCode: { required },
        Zip: { required, minLength: minLength(5), maxLength: maxLength(10) },
        Class: { required },
        HourlyRate: {required, minValue: minValue(0), maxValue: maxValue(1000)}
      },
    };
  },
  mounted() {},
  computed: {
    fieldReadOnly: function () {
      return this.readOnlyForm === 'yes' ? true : false;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    url: function () {
      return this.getUrl();
    },
    cities: function () {
      //return this.mapBootstrapSelect(cities, 'City');
      let lg = this.$i18n.locale;
      let cities = this.$store.getters.getCities;
      let provinces = this.$store.getters.getProvinces;

      let cityprovinceList = [];

      for (let i = 0; i < provinces.length; i++) {
        let province = provinces[i];
        let cityList = cities.filter((c) => c.Province === province.Id);
        let provinceName = lg === "fr" ? province.Fr : province.En;
        cityList.unshift({ Name: provinceName, Flag: "Province" });
        cityprovinceList.push(cityList);
      }

      return cityprovinceList.flat();
    },
    classNames: function () {
      let list = this.$store.getters.getClassNames;
      return this.mapBootstrapSelect(list, "ClassNames");
    },
    countryCodeList: function () {
      return [
        { value: null, text: this.$t("label.countryCode") },
        { value: "CA", text: "CA" },
        { value: "US", text: "US" },
      ];
    },
  },
  watch: {
    currentLg: function () {},
  },
  methods: {
    getAddressData: function (addressData, placeResultData) {
      if (addressData) {
        this.form.locAddress = addressData;
        let civic = Object.prototype.hasOwnProperty.call(
          addressData,
          "street_number"
        )
          ? addressData.street_number
          : "";
        let street = Object.prototype.hasOwnProperty.call(addressData, "route")
          ? addressData.route
          : "";
        this.form.Street = `${civic} ${street}`;
        this.form.City = Object.prototype.hasOwnProperty.call(
          addressData,
          "locality"
        )
          ? addressData.locality
          : "";
        this.form.State = Object.prototype.hasOwnProperty.call(
          addressData,
          "administrative_area_level_1"
        )
          ? this.getLongName(
              placeResultData,
              addressData.administrative_area_level_1
            )
          : "";
        this.form.Country = Object.prototype.hasOwnProperty.call(
          addressData,
          "country"
        )
          ? addressData.country
          : "";
        this.form.CountryCode = this.getShortName(
          placeResultData,
          addressData.country
        );
        this.form.Zip = Object.prototype.hasOwnProperty.call(
          addressData,
          "postal_code"
        )
          ? addressData.postal_code
          : "";
      }
    },
    getLongName: function (placeResultData, shortName) {
      let longName = shortName;

      if (
        Object.prototype.hasOwnProperty.call(
          placeResultData,
          "address_components"
        )
      ) {
        if (Array.isArray(placeResultData.address_components)) {
          let object = placeResultData.address_components.find(
            (p) => p.short_name === shortName
          );
          if (object) {
            longName = object.long_name;
          }
        }
      }

      return longName;
    },
    getShortName: function (placeResultData, longName) {
      let shortName = "";

      if (
        Object.prototype.hasOwnProperty.call(
          placeResultData,
          "address_components"
        )
      ) {
        if (Array.isArray(placeResultData.address_components)) {
          let object = placeResultData.address_components.find(
            (p) => p.long_name === longName
          );
          if (object) {
            shortName = object.short_name;
          }
        }
      }

      return shortName;
    },
    mapBootstrapSelect: function (list, type) {
      let lg = this.$i18n.locale;
      return list.map((l) => {
        let obj = {};
        obj.value = l.Id;
        if (type === "City") {
          obj.text = l.Name;
        } else {
          obj.text = lg === "fr" ? l.Fr : l.En;
        }
        return obj;
      });
    },
    getData: function (field, defaultValue) {
      if (field === 'HourlyRate') {
        let hourlyRate = this.$store.getters.getHourlyRate;
        defaultValue = parseInt(hourlyRate.Value);
      }

      let returnValue = defaultValue;
      
      if (this.values) {
        if (this.values[field] === 0) {
          returnValue = 0;
        }
        else {
          returnValue = this.values[field] ? this.values[field] : defaultValue;
        }
      }
      
      return returnValue;
    },
    toUppercase: function(value) {
      return value.toUpperCase();
    },
    getCity: function (field, returnValue) {
      if (this.values) {
        let cities = this.$store.getters.getCities;
        let city = cities.find((c) => c.Id === this.values[field]);
        returnValue = city.Name;
      }
      return returnValue;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.spinner = true;
        this.form.request = this.values ? "updateProperty" : "insertProperty";
        this.form.Type = this.type;
        this.form.Id = this.values ? this.values.Id : 0;
        this.form.IdCity = this.form.City.Id;
        this.form.HourlyRate = this.form.HourlyRate === "0" ? 0 : parseInt(this.form.HourlyRate)

        axios
          .post(this.url, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            this.disabled = false;
            this.spinner = false;

            if (response.data === 0) {
              this.$bvModal.hide(this.modalid);
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      }
    },
  },
  components: {
    VueGoogleAutocomplete,
  },
};
</script>
