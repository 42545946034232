<template>
  <div>
    <b-row class="mt-4">
      <b-col>

        <b-alert show variant="info">
          <p>
            <strong>{{ $t('info.choice') }}</strong>
          </p>
          <ul>
            <li v-for="item in selectList" :key="item.id">
              {{ item.displayName }}
            </li>
          </ul>
        </b-alert>
      </b-col>
    </b-row>

    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-input
            v-model="search"
            :placeholder="$t('action.search')"
          ></b-form-input>
          <div>
            <small>&#9432; {{ $t("info.searchDirectory") }}</small>
          </div>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table
            id="MultiSelectTable"
            stacked="lg"
            bordered
            :fields="fields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(details)="row">
              <b-button
                :variant="row.item.buttonStyle"
                class="ml-2"
                size="sm"
                @click="onSelected(row.item)"
                >{{ row.item.buttonStyle === 'outline-success' ? $t("label.choose") : $t("label.remove") }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="MultiSelectTable"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tableDataFromProp", "fields", "tableName", "corpoDlList"],
  data() {
    return {
      tableData: this.getTableData(),
      count: null,
      sortBy: "displayName",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      search: this.getSearch(),
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      selectList: this.getSelectList(),
      addToDl: 'no'
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    items: function () {
      let find = [];
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      if (this.tableName === "distributionList") {
        find = mydatalist.filter((o) => {
          let dp = o.displayName ? o.displayName : "";
          let displayName = dp.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

          let mail = o.mail ? o.mail : "";

          let createdDateTime = o.createdDateTime ? o.createdDateTime : "";

          return (
            displayName.toLowerCase().includes(s) ||
            mail.toLowerCase().includes(s) ||
            createdDateTime.toLowerCase().includes(s)
          );
        });
      }

      if (this.tableName === "licenseList") {
        find = mydatalist.filter((o) => {
          let dp = o.displayName ? o.displayName : "";
          let displayName = dp.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

          let skuPartNumber = o.skuPartNumber ? o.skuPartNumber : "";

          return (
            displayName.toLowerCase().includes(s) ||
            skuPartNumber.toLowerCase().includes(s)
          );
        });
      }

      return find;
    },
    displayList: function () {
      let map = this.selectList.map((s) => {
        return s.displayName;
      });

      return map;
    },
  },
  methods: {
    getSearch: function () {
      let search = "";
      if (this.tableName === "distributionList") {
        search = "dist";
      }
      return search;
    },
    getSelectList: function () {
      let selectList = [];
      if (this.tableName === "distributionList") {
        selectList = this.$store.getters.getAdditionnalDlList;
      }
      if (this.tableName === "licenseList") {
        selectList = this.$store.getters.getLicenseList;
      }
      return selectList;
    },
    getTableData: function () {
      let listToCheck = [];
      let tableDataFromProp = [];
      if (this.tableName === "distributionList") {
        listToCheck = this.$store.getters.getAdditionnalDlList; 
        let tableDataFilterByGroupType = this.tableDataFromProp.filter(t => !t.groupTypes.includes("DynamicMembership"));
        if (this.corpoDlList.length > 0) {
          let corpoName = this.corpoDlList[0].displayName;
          tableDataFromProp = tableDataFilterByGroupType.filter(t => t.displayName !== corpoName); 
        }
        else {
          tableDataFromProp = tableDataFilterByGroupType;
        }
      }
      if (this.tableName === "licenseList") {
        listToCheck = this.$store.getters.getLicenseList;
        tableDataFromProp = this.tableDataFromProp;
      }
      
      let tableData = tableDataFromProp.map(t => {
        if (listToCheck.some(a => a.id === t.id)) {
          t.buttonStyle = "success";
        }
        else {
          t.buttonStyle = "outline-success";
        }
        return t;
      });

      return tableData;
    },
    resetTable: function () {
      this.search = "";
    },
    onSelected: function (item) {
      let selectarr = this.selectList;
      let dlArr = this.tableData;
      let found = selectarr.find((s) => s.id === item.id);
      if (found) { 
        this.selectList = selectarr.filter((s) => s.id !== found.id);
        this.tableData = dlArr.map(d => {
          if (d.id === found.id) {
            d.buttonStyle = 'outline-success';
          }
          return d;
        });
      } else {
        this.selectList.push(item);
        this.tableData = dlArr.map(d => {
          if (d.id === item.id) {
            d.buttonStyle = 'success';
          }
          return d;
        });
      }

      if (this.tableName === "distributionList") {
        this.$store.commit('addAdditionnalDlList', this.selectList);
      }

      if (this.tableName === "licenseList") {
        this.$store.commit('addLicenseList', this.selectList);
      }
    },
  },
};
</script>
