import axios from 'axios';
export const queryStringHelperFunctions = {
  methods: {
    validateEmailClickCampaign: async function (query) {
      let token = query.token;
      let token1 = query.token1;
      let email = query.email;
      let action = query.action;

      let nextAction = 'insertError';
      let url = this.getUrl();

      if (token && token1) {
        if (token.length > 63 && token.length < 70 && this.validateEmail(email) && (action === 'modified' || action === 'confirmed')) {
          nextAction = await axios.post(url, {request: "addEmailClickCampaign", token: token, token1: token1, email: email, action: action }, { headers: {'Content-Type': 'application/json'}})
             .then((response) => {
              return response.data;
             })
             .catch((error)  => {
               console.log(error);
             });
        }
      }

      return nextAction;
    },
    validateEmail: function (email) {
      let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return regex.test(email);
    },
    getUrl() {
      const loc = window.location.hostname;
      const env = process.env.VUE_APP_WHERE;
      const urls = this.$store.getters.getUrlsList;
      let url = urls[env].json;

      if (env === 'prod') {
        url = loc.includes('myprofile') ? urls[env].json.en : urls[env].json.fr;
      }

      return url; 
    },
    //accessFormId=$accessFormId&ticketId=$ticketId
    validateTicketInformation: async function (query) {
      let accessFormId = parseInt(query.accessFormId);
      let ticketId = parseInt(query.ticketId);
      let request = null;
      
      if (isNaN(accessFormId) || isNaN(ticketId)) { 
        request = null;
      }
      else {
        let url = this.getUrl();
        request = await axios.post(url, {request: "getTicketByIds", accessFormId: accessFormId, ticketId: ticketId }, { headers: {'Content-Type': 'application/json'}})
             .then((response) => {
              return response.data;
             })
             .catch((error)  => {
               console.log(error);
             });
      }

      return request;
    },
    validateTerminationInformation: async function (query) {
      let terminationOfEmploymentId = parseInt(query.terminationOfEmploymentId);
      let ticketId = parseInt(query.ticketId);
      let request = null;
      
      if (isNaN(terminationOfEmploymentId) || isNaN(ticketId)) { 
        request = null;
      }
      else {
        let url = this.getUrl();
        request = await axios.post(url, {request: "getTerminationByIds", terminationOfEmploymentId: terminationOfEmploymentId, ticketId: ticketId }, { headers: {'Content-Type': 'application/json'}})
             .then((response) => {
              return response.data;
             })
             .catch((error)  => {
               console.log(error);
             });
      }

      return request;
    }
  }
};