<template>
  <b-container>
    <b-nav tabs class="mt-4">
      <b-nav-item :active="active === 'access'" @click="active = 'access'">{{
        $t("label.accessFormTitle")
      }}</b-nav-item>
      <b-nav-item
        :active="active === 'termination'"
        @click="active = 'termination'"
        >{{ $t("label.terminatedUserForm") }}</b-nav-item
      >
    </b-nav>

    <AccessForm v-if="active === 'access'" v-on:changePage="setNextPage" />
    <InitiateTermination v-if="active === 'termination'" v-on:changePage="setNextPage"/>
    
  </b-container>
</template>

<script>
import { globalF } from "../Helpers";
import InitiateTermination from "./InitiateTermination";
import AccessForm from "./AccessForm.vue";

export default {
  mixins: [globalF],
  data() {
    return {
      active: this.getActive(),
    };
  },
  validations() {},
  mounted() {},
  computed: {},
  methods: {
    getActive: function () {
      return this.$store.getters.getAdminActiveTab;
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
  },
  components: {
    InitiateTermination,
    AccessForm
  },
};
</script>
