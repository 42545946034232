<template>
  <div>
    <b-row v-if="client === 'Rh'" class="mt-4">
      <b-col>
        <div><strong>{{ $t("label.hourlyRate") }}</strong> : {{ currentHourlyRate.Value }}
          <span><b-button variant="link" @click="openmodal('hourlyRateModal', null)">
            {{ $t("label.updateHourlyRateDefaultValue")}}</b-button></span></div>
      </b-col>
    </b-row>

    <b-row v-if="type === 'City' || type === 'Province'">
      <b-col sm="6" class="mt-4">
        <div><strong>{{ selectLabel }}</strong></div>
        <b-form-select v-model="selected" :options="list"></b-form-select>
      </b-col>
    </b-row>

    <div v-if="selected">

      <div class="mt-2" v-if="tableData.length > 0">
        <b-row class="pt-2">
          <b-col sm="4" class="mt-2">
            <b-form-select v-model="perPage" :options="options"></b-form-select>
          </b-col>
          <b-col sm="4" class="mt-2">
            <b-form-input v-model="search" :placeholder="$t('action.search')"></b-form-input>
            <b-button variant="link" @click="resetTable()">{{ $t('action.reset') }}</b-button>
          </b-col>
          <b-col sm="4" class="mt-2">
            <div>{{ $t("label.tablesize") }} {{ rows }}</div>
          </b-col>
        </b-row>

        <b-row class="pt-4">
          <b-col class="lgTable">
            <b-table id="CRUDTable" stacked="lg" bordered :fields="myfields" :items="items" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :per-page="perPage" :current-page="currentPage">
              <template v-slot:cell(details)="row">
                <b-button v-if="type === 'PropertyName'" variant="outline-success" class="ml-2" size="sm"
                  @click="openmodal('AddEditResidence', row.item)">{{ $t("label.edit") }}</b-button>
                <b-button v-else variant="outline-success" class="ml-2" size="sm"
                  @click="openmodal('addEditModal', row.item)">{{ $t("label.edit") }}</b-button>
                <b-button v-if="client === 'Ti'" variant="outline-danger" class="ml-2" size="sm"
                  @click="openmodal('deleteModal', row.item)">{{ $t("label.delete") }}</b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row class="pt-4">
          <b-col sm="6" class="mt-2">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
              aria-controls="CRUDTable"></b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-row v-if="client === 'Ti'" class="pt-4">
        <b-col class="mt-2">
          <b-button v-if="type === 'PropertyName'" variant="success" @click="openmodal('AddEditResidence', null)">{{
            $t("label.add") }}</b-button>
          <b-button v-else variant="success" @click="openmodal('addEditModal', null)">{{ $t("label.add") }}</b-button>
        </b-col>
      </b-row>

      <b-modal id="addEditModal" :title="modalTitle" hide-footer>
        <AddEditModal :values="tableRowItem" :type="type" :dep="selected" modalid="addEditModal" />
      </b-modal>
      <b-modal id="AddEditResidence" size="xl" :title="modalTitle" hide-footer>
        <AddEditResidence :values="tableRowItem" :type="type" modalid="AddEditResidence"
          :readOnlyForm="client === 'Ti' ? 'no' : 'yes'" />
      </b-modal>
      <b-modal id="deleteModal" title="Confirmation" hide-footer>
        <DeleteModal :values="tableRowItem" :type="type" modalid="deleteModal" />
      </b-modal>
      <b-modal id="hourlyRateModal" :title="$t('label.edit')" hide-footer>
        <EditHourlyRate :currentHourlyRate="currentHourlyRate" modalid="hourlyRateModal" />
      </b-modal>

    </div>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }} </p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner style="width: 3rem; height: 3rem;" variant="success" label="Spinning" disabled="labelStatus"></b-spinner>
    </div>
  </div>
</template>

<script>
import { globalF } from './Helpers';
import AddEditModal from './Modal/AddEditModal';
import AddEditResidence from './Modal/AddEditResidence';
import DeleteModal from './Modal/DeleteModal';
import EditHourlyRate from './Modal/EditHourlyRate.vue';

export default {
  mixins: [globalF],
  props: ['list', 'type', 'client'],
  data() {
    return {
      selected: this.getSelected(),
      tableRowItem: null,
      sortBy: 'Id',
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" }
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: ""
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', () => {
      let user = this.$store.getters.getUser;
      this.getAppData(user);
    })
  },
  computed: {
    currentHourlyRate: function () {
      return this.$store.getters.getHourlyRate;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    modalTitle: function () {
      return this.tableRowItem ? this.$t('label.edit') : this.$t('label.add');
    },
    selectLabel: function () {
      let type = this.type;
      let label = 'Select Label'
      if (type === 'City') {
        label = this.$t('label.province');
      }
      else if (type === 'Province') {
        label = this.$t('label.country');
      }
      else {
        label = 'Select Label';
      }
      return label;
    },
    tableData: function () {
      let tblData = [];
      if (this.selected) {
        let type = this.type;
        if (type === 'City') {
          let cities = this.$store.getters.getCities;
          tblData = cities.filter(c => c.Province === this.selected);
        }
        else if (type === 'Province') {
          let provinces = this.$store.getters.getProvinces;
          tblData = provinces.filter(p => p.Country === this.selected);
        }
        else if (type === 'Country') {
          let countries = this.$store.getters.getCountries;
          tblData = countries.map(c => { return c; });
        }
        else if (type === 'ClassNames') {
          let classNames = this.$store.getters.getClassNames;
          tblData = classNames.map(c => { return c; });
        }
        else if (type === 'PropertyName') {
          let buildings = this.$store.getters.getBuildings;
          tblData = buildings.map(c => { return c; });
        }
        else {
          tblData = [];
        }
      }

      return tblData;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let type = this.type;
      let search = this.search.toLowerCase();
      if (type === 'City') {
        return mydatalist.filter(p => p.Name.toString().toLowerCase().includes(search));
      }
      else if (type === 'PropertyName') {
        return this.searchBuilding(mydatalist, search);
      }
      else {
        return mydatalist.filter(p => p.Fr.toString().toLowerCase().includes(search) || p.En.toString().toLowerCase().includes(search));
      }
    },
    myfields: function () {
      let obj = [];
      let type = this.type;
      if (type === 'City') {
        obj = [
          { key: 'Id', sortable: true, label: 'Id' },
          { key: 'Name', sortable: true, label: this.$t('label.name') },
          { key: 'details', label: this.$t('label.edit') }
        ]
      }
      else if (type === 'PropertyName') {
        obj = [
          { key: 'Id', sortable: true, label: 'Id' },
          { key: 'ResName', sortable: true, label: this.$t('label.name') },
          { key: 'Class', sortable: true, label: this.$t('label.className'), formatter: (value) => { return this.getClassNames(value) } },
          { key: 'details', label: this.$t('label.edit') }
        ]
      }
      else {
        obj = [
          { key: 'Id', sortable: true, label: 'Id' },
          { key: 'Fr', sortable: true, label: this.$t('label.french') },
          { key: 'En', sortable: true, label: this.$t('label.english') },
          { key: 'details', label: this.$t('label.edit') }
        ]
      }
      return obj;
    }
  },
  watch: {
    type: function () {
      if (this.type === 'City' || this.type === 'Province') {
        this.selected = null;
      }
      else {
        this.selected = "no";
      }
    }
  },
  methods: {
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      let find = options.filter(o => {

        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize('NFD').replace(/[\u0300-\u036f]/g, ""); //Remove accent 

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return City.toLowerCase().includes(s) || ClassNamesFr.toLowerCase().includes(s) || ClassNamesEn.toLowerCase().includes(s)
          || ResName.toLowerCase().includes(s) || Country.toLowerCase().includes(s) || State.toLowerCase().includes(s)
          || Street.toLowerCase().includes(s) || Zip.toLowerCase().includes(s);
      });

      return find;
    },
    resetTable: function () {
      this.search = "";
    },
    openmodal: function (id, rowItem) {
      this.tableRowItem = rowItem;
      this.$bvModal.show(id)
    },
    getSelected: function () {
      let selected = null;
      if (this.type === 'Country' || this.type === 'ClassNames' || this.type === 'PropertyName') {
        selected = "no";
      }
      return selected;
    },
    getClassNames: function (value) {
      let lg = this.$i18n.locale;
      let list = this.$store.getters.getClassNames;
      let className = list.find(c => c.Id === value);
      return lg === 'fr' ? className.Fr : className.En;
    }
  },
  components: {
    AddEditModal,
    AddEditResidence,
    DeleteModal,
    EditHourlyRate
  }
}
</script>
