<template>
  <footer class="footer pt-4">
  <b-container>
  <b-navbar type="dark" variant="dark" :fixed="position">
    <b-navbar-nav>
      <b-nav-text>&copy; {{ $t("title.copyright") }}</b-nav-text>
    </b-navbar-nav>
  </b-navbar>
  
  </b-container>
</footer>
</template>

<script>
export default {
  data: () => ({
  position: ""
  }),
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
  },
  methods:{
  },
  components: {}
}
</script>
