<template>
    <b-container>
        <div v-if="buildingAddress">
            <div>{{ buildingAddress.ResName }}</div>
            <p>
                <span>{{ currentLg === 'fr' ? buildingAddress.ClassNamesFr : buildingAddress.ClassNamesEn }}
                </span>
            </p>
            <div>{{ buildingAddress.Street }}</div>
            <div><span>{{ buildingAddress.City }}, </span><span>{{ buildingAddress.State }}, </span><span>{{
                buildingAddress.Country }}</span></div>
            <div><span>{{ buildingAddress.Zip }}</span></div>
        </div>
        <div v-else>
            {{ $t('info.noAddressFound') }}
        </div>
    </b-container>
</template>
  
<script>
import { globalF } from "../Helpers";

export default {
    mixins: [globalF],
    props: ["userInfo"],
    computed: {
        buildingAddress: function () {
            return this.getOfficeData();
        },
        currentLg: function () {
            return this.$i18n.locale;
        },
    },
    methods: {
        getOfficeData: function () {
            let officeName = this.userInfo.officeLocation;
            let buildings = this.$store.getters.getBuildings;
            let building = buildings.find((b) => b.ResName.toLowerCase().trim() === officeName.toLowerCase().trim());
            return building ? building : null;
        },
    },
    components: {},
};
</script>
  