<template>
  <b-container>
    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-input
            v-model="search"
            :placeholder="$t('action.search')"
          ></b-form-input>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table
            id="DesjExclusionTable"
            stacked="lg"
            bordered
            :fields="myfields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(email)="row">
              <b-button
                variant="link"
                size="sm"
                @click="openmodal('userInfo', row.item)"
                >{{ row.item.email }}</b-button
              >
            </template>
            <template v-slot:cell(details)="row">
              <b-form-checkbox
                v-model="row.item.remove"
                value="checked"
                unchecked-value="unchecked"
                name="testEmail"
                switch
              >
                <span>{{ $t("label.remove") }}</span>
              </b-form-checkbox>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="DesjExclusionTable"
          ></b-pagination>
        </b-col>
      </b-row>
      <b-button
        @click="removeEmpl()"
        class="mt-4"
        type="submit"
        variant="success"
        :disabled="disabled"
        >{{ $t("action.submit") }}</b-button
      >
    </div>

    <b-modal id="userInfo" :title="$t('label.contactDetails')" hide-footer>
      <b-row v-if="userInfoModal">
        <b-col>
          <ul>
            <li
              v-for="(value, key) in getUserInfoModal(userInfoModal)"
              :key="key"
            >
              {{ value }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-modal>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  data() {
    return {
      userInfoModal: null,
      requestType: "addEmplToExclusionList",
      exclusionList: [],
      rawData: [],
      tableData: [],
      count: null,
      dataFromTable: {},
      sortBy: "StartJob",
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
    };
  },
  mounted() {
    this.getExclusionList();
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let fn = o.fname ? o.fname : "";
        let fname = fn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let ln = o.lname ? o.lname : "";
        let lname = ln.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let email = o.email ? o.email : "";

        let lastPaidDay = o.lastPaidDay ? o.lastPaidDay : "";

        let st = o.status ? o.status : "";
        let status = st.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        return (
          fname.toLowerCase().includes(s) ||
          lname.toLowerCase().includes(s) ||
          email.toLowerCase().includes(s) ||
          lastPaidDay.toLowerCase().includes(s) ||
          status.toLowerCase().includes(s)
        );
      });

      return find;
    },
    myfields: function () {
      let myFields = [
        { key: "fname", sortable: true, label: this.$t("label.fname") },
        { key: "lname", sortable: true, label: this.$t("label.lname") },
        { key: "email", sortable: true, label: this.$t("label.email") },
        {
          key: "lastPaidDay",
          sortable: true,
          label: this.$t("label.lastPaidDay"),
        },
        { key: "status", sortable: true, label: this.$t("label.jobStatus") },
        { key: "details", label: this.$t("label.details") },
      ];

      return myFields;
    },
    url: function () {
      return this.getUrl();
    },
  },
  methods: {
    getUserInfoModal(userInfoModal) {
      let desjUser = userInfoModal.desjUser;
      let lastIndex = desjUser.length - 1;
      return desjUser.filter((item, index) => index !== lastIndex);
    },
    openmodal: function (id, userInfo) {
      this.userInfoModal = userInfo;
      this.$bvModal.show(id);
    },
    setExclusionList() {
      this.tableData = this.exclusionList.map((item) => {
        return {
          id: item.Id,
          fname: item.Fname,
          lname: item.Lname,
          email: item.Email,
          lastPaidDay: item.LastPaidDay,
          status: item.EmplStatus,
          remove: "unchecked",
        };
      });
    },
    getExclusionList() {
      this.spinner = true;
      axios
        .post(
          this.url,
          { request: "getDesjExclusionList" },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.spinner = false;
          this.exclusionList = response.data;
          this.setExclusionList();
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    removeEmpl() {
      let empleList = this.tableData.filter(
        (item) => item.remove === "checked"
      );
      if (empleList.length > 0) {
        this.disabled = true;
        this.spinner = true;
        let request = "deleteEmployeeInExclusionList";

        axios
          .post(
            this.url,
            { request: request, exclusionList: empleList },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(() => {
            this.getExclusionList();

            this.spinner = false;
            this.success = true;
            this.disabled = false;
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
            this.disabled = false;
          });
      }
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    resetTable: function () {
      this.search = "";
    },
  },
  components: {
  },
};
</script>
