<template>
  <b-container>
    <div class="mt-4">
      <b-button variant="link" @click="goTo('Admin')">{{
        $t("label.backToCampaign")
      }}</b-button>
    </div>
    <b-row v-if="!spinner" class="pt-2">
      <b-col sm="4" class="mt-2">
        <div>
          <strong>Date(s)</strong>
        </div>
        <b-form-select
          v-model="campaignDate"
          :options="campaignDates"
        ></b-form-select>
      </b-col>
      <b-col sm="4" class="mt-2">
        <div>
          <strong>{{ $t("label.distributionList") }}</strong>
        </div>
        <b-form-select
          v-model="distributionList"
          :options="selectCampaignDlData"
        ></b-form-select>
      </b-col>
      <b-col sm="4" class="mt-2">
        <b-button class="mt-4" variant="success" @click="OnListClick">{{
          $t("action.submit")
        }}</b-button>
      </b-col>
    </b-row>
    <!-- table -->
    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="4" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="4" class="mt-2">
          <b-form-input
            v-model="search"
            :placeholder="$t('action.search')"
          ></b-form-input>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
        <b-col sm="4" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>
      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table
            id="sessionTable"
            stacked="lg"
            bordered
            :fields="myfields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(countUserSessions)="row">
              <b-button
                v-if="parseInt(row.item.countUserSessions) > 0"
                class="btn-block"
                size="sm"
                variant="link"
                @click="showModal('request', row.item)"
                >{{ row.item.countUserSessions }}</b-button
              >
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="sessionTable"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-modal id="request" :title="$t('label.lastRequest')" hide-footer>
      <SessionConnexionModal :sessionsList="tableRowItem" />
    </b-modal>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { globalF } from "./Helpers";
import SessionConnexionModal from "./Modal/SessionConnexionModal.vue";

export default {
  mixins: [globalF],
  data() {
    return {
      sortBy: "countUserSessions",
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
      tableData: [],
      tableRowItem: [],
      distributionList: null,
      campaignDate: this.getStartDate(),
      distListFromGraph: [],
      count: 0,
      rawData: [],
      sessionsListByDate: [],
    };
  },
  mounted() {
    let token = this.$store.getters.getAppAccessToken;
    let graphEndpoint = "https://graph.microsoft.com/v1.0/groups";
    this.getAllGroups(token, graphEndpoint);
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    selectCampaignDlData: function () {
      let campaignData = this.$store.getters.getSessionTableValue;
      let templates = this.$store.getters.getCampaignTemplateVersion;
      let templateDl = templates.filter(
        (p) => p.IdCampaign === campaignData.Id
      );
      let distListFromGraph = this.distListFromGraph;
      let campaignDlData = [];
      for (let i = 0; i < templateDl.length; i++) {
        let find = distListFromGraph.find(
          (dl) => dl.value === templateDl[i].IdDistributionList
        );
        campaignDlData.push(find);
      }
      return campaignDlData;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let n = o.displayName ? o.displayName : "";
        let displayName = n.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let m = o.mail ? o.mail : "";
        let mail = m.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let logIn = o.logIn ? o.logIn : "";

        let c = o.countUserSessions ? o.countUserSessions : "";
        let count = c.toString();

        return (
          displayName.toLowerCase().includes(s) ||
          mail.toLowerCase().includes(s) ||
          logIn.toLowerCase().includes(s) ||
          count.toLowerCase().includes(s)
        );
      });

      return find;
    },
    myfields: function () {
      return [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "mail", sortable: true, label: this.$t("label.email") },
        { key: "logIn", sortable: true, label: this.$t("action.login") },
        {
          key: "countUserSessions",
          sortable: true,
          label: this.$t("label.connexionCount"),
        },
      ];
    },
    campaignDates: function () {
      let campaignData = this.$store.getters.getSessionTableValue;
      let DatesCampaign = campaignData.DatesCampaign;

      return DatesCampaign.map((dc) => {
        return {
          text:  dc.StartDate + " ("+ dc.Type +") ",
          value: dc,
        };
      });
    },
  },
  methods: {
    getStartDate: function () {
      let campaignData = this.$store.getters.getSessionTableValue;
      return campaignData.DatesCampaign ? campaignData.DatesCampaign[0] : null;
    },
    goTo: function (val) {
      if (val === "Admin") {
        this.$store.commit("setAdminActiveTab", "Campaign");
      }
      this.$emit("changePage", val);
    },
    resetTable: function () {
      this.search = "";
    },
    showModal: function (id, campaign) {
      this.tableRowItem = campaign;
      this.$bvModal.show(id);
    },
    OnListClick: function () {
      if (this.distributionList && this.campaignDate) {
        this.count = 0;
        this.rawData = [];
        this.tableData = [];
        let token = this.$store.getters.getAppAccessToken;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${this.distributionList}/members?$count=true`;
        this.getDistributionListMember(token, graphEndpoint);
      }
    },
    getDistributionListMember: function (token, graphEndpoint) {
      this.spinner = true;

      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data["@odata.count"]) {
              this.count = response.data["@odata.count"];
            }
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getDistributionListMember(token, nextLink);
            } else {
              this.getSessionsListByDate();
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    formatRawData: function () {
      let flatTable = this.rawData.flat(); //Distribution List
      let sessions = this.sessionsListByDate;

      this.tableData = flatTable.map((dl) => {
        let userSessions = sessions.filter((s) => s.Email === dl.mail);
        dl.logIn = null;
        if (userSessions.length > 0) {
          userSessions.sort((a, b) => {
            return moment(a.LogIn).toDate() + moment(b.LogIn).toDate();
          });
          dl.logIn = userSessions[0].LogIn;
        }
        dl.userSessions = userSessions;
        dl.countUserSessions = userSessions.length;

        return dl;
      });
    },
    getSessionsListByDate: function () {
      //this.spinner = true;
      if (this.campaignDate) {
        let dc = this.campaignDate;
        let sd = dc.StartDate; 
        let obj = { request: "getSessionListByDate", startDate: sd };

        axios
          .post(this.url, obj, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            this.spinner = false;
            this.disabled = false;
            this.sessionsListByDate = response.data;
            this.formatRawData();
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      }
    },
  },
  components: {
    SessionConnexionModal,
  },
};
</script>
