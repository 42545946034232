<template>
  <b-container>
    <b-row class="text-center pt-4">
      <b-col v-if="signInBtn">
        <b-button
          v-if="!spinner"
          class="mt-4"
          variant="success"
          @click="RedirectToLogin()"
          >{{ $t("action.login") }}</b-button
        >
      </b-col>
    </b-row>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="info" class="text-center pt-4">
      <b-col>
        <b-alert show variant="info">
          <p>{{ info }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </b-container>
</template>

<script>
import { globalF } from "./Helpers";
import { queryStringHelperFunctions } from "./QueryStringHelpers";
import axios from "axios";
import * as msal from "@azure/msal-browser";

export default {
  mixins: [globalF, queryStringHelperFunctions],
  props: ["redirect"],
  data: () => ({
    spinner: true,
    success: false,
    signInBtn: false,
    error: "",
    info: "",
    scope: [
      "user.read",
      "user.readwrite",
      "user.read.all",
      "user.readwrite.all",
      "directory.readwrite.all",
      "files.readwrite",
    ],
    tenantId: ["326c0bd4-11c9-41cc-be49-55e652fc8ea3"],
  }),
  mounted() {
    let promise = this.RedirectToLogin();
    promise.then(() => {
      console.log("Redirect to login terminated");
    });
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    redirectUri: function() {
       
      let redirectUri = "http://localhost:8080/";
      const env = process.env.VUE_APP_WHERE;
      
      if (env === 'prod') {
        let uriFromBrowser = window.location.origin;
        if (uriFromBrowser === "https://myprofile-app.azurewebsites.net") {
          redirectUri =  "https://myprofile-app.azurewebsites.net";
        }
        if (uriFromBrowser === "https://monprofil.cogir.net") {
          redirectUri =  "https://monprofil.cogir.net";
        }
        if (uriFromBrowser === "https://myprofile.cogir.net") {
          redirectUri =  "https://myprofile.cogir.net";
        }
      }
      return redirectUri;
    }
  },
  methods: {
    getConfig: function () {
      const config = {
        auth: {
          clientId: "ce26bf76-81c6-44c6-a5f6-786fce772d0f",
          redirectUri: this.redirectUri,
          postLogoutRedirectUri: this.redirectUri,
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false,
          secureCookies: false,
        },
      };

      const myMSALObj = new msal.PublicClientApplication(config);

      this.$store.commit("addmsalObj", myMSALObj);

      return myMSALObj;
    },
    RedirectToLogin: async function () {
      const myMSALObj = this.getConfig();
      return myMSALObj
        .handleRedirectPromise()
        .then((tokenResponse) => {
          let accountObj = null;
          if (tokenResponse !== null) {
            accountObj = tokenResponse.account;
            this.$store.commit("addMicrosoftAccount", accountObj);

            const token = tokenResponse.accessToken;
            this.$store.commit("addUserToken", token);

            this.getGraphUser(token);
          } else {
            let loginRequest = {
              scopes: this.scope,
            };
            const currentAccounts = myMSALObj.getAllAccounts();
            if (!currentAccounts || currentAccounts.length === 0) {
              // No user signed in
              myMSALObj.loginRedirect(loginRequest);
            } else {
              accountObj = currentAccounts.find((c) =>
                this.tenantId.includes(c.tenantId)
              );

              if (accountObj) {
                this.$store.commit("addMicrosoftAccount", accountObj);

                let accessTokenRequest = {
                  scopes: this.scope,
                  account: accountObj,
                };

                myMSALObj
                  .acquireTokenSilent(accessTokenRequest)
                  .then(function (accessTokenResponse) {
                    const token = accessTokenResponse.accessToken;
                    this.$store.commit("addUserToken", token);
                    // Call your API with token
                    this.getGraphUser(token);
                  })
                  .catch(function () {
                    console.log("Acquire token failed");
                    myMSALObj.acquireTokenRedirect(accessTokenRequest);
                  });
              } else {
                console.log("Cogir tenant not found");
                this.signInBtn = true;
                this.spinner = false;
              }
            }
          }
        })
        .catch(() => {
          //After log out
          this.signInBtn = true;
          this.spinner = false;
        });
    },
    getGraphUser(token) {
      let graphEndpoint =
        "https://graph.microsoft.com/v1.0/me?$expand=directReports";
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.validateUserAndToken(token, response.data);
          } else {
            this.spinner = false;
            this.error = this.$t("info.UserNotAuthorized");
            this.signInBtn = true;
          }
        })
        .catch(() => {
          this.info = this.$t("info.errorlogin");
          this.spinner = false;
          this.signInBtn = true;
        });
    },
    validateUserAndToken(token, user) {
      let url = this.getUrl();

      axios.post(url, { request: "validateUserAndToken", token: token, user: user }, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          this.validateRedirectV2(user, response.data);
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });
    },
    validateRedirectV2(user, appToken) {

      axios.defaults.headers.common['myprofile-api-key'] = appToken;

      let page = "UserForm";
      let queryStringValues = sessionStorage.getItem("queryStringRequest");
      let promise = null;
      let requestName = "UserForm";

      if (queryStringValues) {
        sessionStorage.removeItem("queryStringRequest");
        let query = JSON.parse(queryStringValues);
        if (query) {
          if (query.token && query.token1 && query.email && query.action) {
            requestName = "campaign";
            page =
              query.action === "confirmed"
                ? "ClickEmailConfirmation"
                : "UserForm";
            promise = this.validateEmailClickCampaign(query);
          }

          if (query.terminationOfEmploymentId && query.ticketId) {
            requestName = "terminateduser";
            promise = this.validateTerminationInformation(query);
          }

          if (query.accessFormId && query.ticketId) {
            requestName = "createuser";
            promise = this.validateTicketInformation(query);
          }

          if (query.redirect) {
            if (query.redirect === "AccessForm") {
              page = "AccessTabs";
            }
            if (query.redirect === "Admin") {
              page = "Admin";
              this.$store.commit("setAdminActiveTab", "ReportManager");
            }
          }
        }
      }

      if (promise) {
        promise.then((value) => {
          if (value) {
            if (requestName === "campaign") {
              this.setRedirect(value);
            }
            if (requestName === "terminateduser") {
              page = "TerminatedForm";
              this.$store.commit("addCurrentUserToCreate", value);
              this.setRedirect(value);
            }
            if (requestName === "createuser") {
              page = "CreateUser";
              this.$store.commit("addCurrentUserToCreate", value);
              this.setRedirect(value);
            }
          }

          let appDataPromise = this.getAppData(user, page);
          appDataPromise.then(() => {
          });
        });
      } else {
        let appDataPromise = this.getAppData(user, page);
        appDataPromise.then(() => {
        });
      }
    },
    setRedirect(message) {
      switch (message) {
        case "noProblemo":
          this.$store.commit("addClickEmailMessage", {
            message: this.$t("info.emailClickConfirmation"),
            alert: "success",
          });
          break;
        case "userAlreadyExistInClickCampaign":
          this.$store.commit("addClickEmailMessage", {
            message: this.$t("info.userAlreadyExistInClickCampaign"),
            alert: "success",
          });
          break;
        case "noUserInDl":
          this.$store.commit("addClickEmailMessage", {
            message: this.$t("info.noUserInDl"),
            alert: "error",
          });
          break;
        case "noCampaignFound":
        case "noTemplate":
        case "insertError":
          this.$store.commit("addClickEmailMessage", {
            message: this.$t("info.errorgeneric"),
            alert: "error",
          });
          break;
      }
    },
  },
  components: {},
};
</script>
