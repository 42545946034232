import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      title: {      
        copyright: "Société de gestion Cogir, all right reserved.",
        /**/
      },
      tooltip: {
        portailRemoteApp: "Application portal, required to use software such as Hopem and Maestro while out of the corporate offices",
        keepHistory: "Permits to consult the old emails of the user, the mailbox will appear directly in Outlook",
        forwardEmail: "Will send future emails received to a different mailbox",
        accessRestriction: "If you check this box, every new email sent to this address will be rejected",
        accesModification: "Allows you to submit a request to modify the accesses of an existing user",
        newAccess: "Allows you to submit a request to create a new user who has no access and no email address",
        accessFormComments: "Hardware needs : cellphone, computer, etc.",
        rightnowTermination: "The agent will process the request as soon as possible - to be used when the employee has already left",
        scheduleTermination: "Will be processed at selected time - use when employee has not terminated yet",
      },
      label: {  
        updateHourlyRateDefaultValue: "Update default hourly rate",
        hourlyRate: "Hourly rate",
        blockAccount: "Block account",
        accessCard: "Montreal access card",
        processed: "Processed",
        resetPassword: "Reset password and revoke session",
        building: "Building",
        userInfo: "User information",
        desjUser: "Desjardins user",
        graphUser: "Graph user",
        searchByMail: "Search by email",
        noEmail: "No email",
        simpleForm: "I can't find a user by name",
        addEmplToExclusionList: "Add employee to exclusion list",
        removeEmplFromExclusionList: "Remove employee from exclusion list",
        terminateSelectedEmployees: "Terminate selected employees",
        DesjTerminationTable: "Desjardins termination tables",
        DesjExclusionTable: "Desjardins exclusion tables",
        lastPaidDay: "Last paid day",
        time: "Time",
        sendingType: "Sending type",
        billingReport: "Billing report",
        codeFacturation: "Billing code",
        keepInMailbox: "Keep in user mailbox",
        accessRequestToRemove: "Access to remove",
        buildingFilter: "Building filter",
        jobName: "Task name",
        jobData: "Data",
        jobStatus: "Job status",
        password: "Password",
        autoreply: "Set-up autoreply",
        accessRestriction: "Block receiving new emails",
        countryCode: "Country code",
        removeUser: "Remove user",
        addUserToManager: "Add a user to the team",
        removedDl: "Remove from distribution List",
        forwardEmail: "Forward email to",
        removedManager: "Remove manager",
        keepHistory: "Give access to email history to",
        sendAs: "Send as",
        lastSignInDetails: "Last SignIn details",
        lastSignIn: "Last SignIn",
        lastSignInNonInteractive: "Last SignIn non interactive",
        signInTable: "SignIn table",
        openScheduleJob: "Open schedule job",
        licenseReport: "License report",
        requestType: "Request",
        backToCurrentOpenTicket: "Back to current open tickets",
        ticketInfo: "Ticket info",
        enabled: "Available licenses",
        licenses: "Available licenses",
        domains: "Available domains",
        remove: "Remove",
        choose: "Choose",
        creation: "Creation",
        userCreationDate: "User creation",
        createUser: "Create user",
        createTable: "Table of users",
        ticketTable: "Current open ticket",
        otherSoftware: "Other software and access",
        yes: "yes",
        no: "no",
        salaryView: "Salary view",
        department: "Department",
        cialocation: "CIA SPR",
        fgl: "FGL Project module",
        ciarh: "ATS",
        acrobatPro: "Acrobat (paid version)",
        comments: "Comments, clarifications or other",
        materialNeeds: "Hardware needs ex. computer, cellphone, etc.",
        materialNeedsInfo: "Specify your hardware requirements in the comment box",
        sendAccessTo: "Send access to",
        remoteAccess: "Remote App portal",  
        newAccess: "New user request",
        accesModification: "Acces modification",
        user: "User",
        resetApplication: "Reset application",
        resetApplicationConfirmation: "Please confirm that you want to close the current application.",
        details: "Details",    
        active: "Active",
        ended: "Ended",
        incomplete: "Incomplete",
        close: "Closed",
        campaignName: "Name",
        campaignDesc: "Description",
        campaignStartDate: "Start date",
        campaignStartTime: "Start time",
        campaignRelaunch: "Relaunch date",
        campaignStatus: "Status",
        departureDate: "Departure date",
        name: "Name",
        displayName: "Display name",
        fullname: "Full name",
        fname: "First name",
        lname: "Last name",
        email: "Email",
        tel: "Phone",
        ext: "Ext",
        mobile: "Mobile Phone",
        officePhone: "Office Phone",
        offices: "Office location",
        job: "Job title",
        manager: "Manager",
        language: "Language",
        french: "French",
        english: "English",
        admin: "Admin",
        userForm: "User Form",
        resName: "Building name",
        address: "Address",
        street: "Street",
        zip: "Zip code",
        city: "City",
        province: "Province/State",
        country: "Country",
        className: "Class",
        add: "Add",
        edit: "Edit",
        delete: "Delete",
        tablesize: "Number of entrie(s) in the table",
        emailNotification: "Email notification",
        cannotFindMyBuilding: "I can't find my building",
        externalLink: "External links",
        multifactorsAuth: "Set-up multi-factor authentication",
        passwordReset: "Set up self-service password reset",
        teams: "Microsoft Teams",
        outlook: "Microsoft Outlook",
        userDirectory: "User directory",
        contactDetails: "Contact details",
        campaign: "Campaign manager",
        webUrl: "Get your file here",
        distributionList: "Distribution list",
        displayDistributionList: "Display distribution list",
        dlCount: "List size",
        connexionCount: "Count",
        connexionList: "List of connections",
        step1: "Step 1",
        step2: "Step 2",
        step3: "Step 3",
        step4: "Step 4",
        templateName: "Template name",
        templateVersion: "Template version",
        typeOfTermination: "Type of termination",
        rightnowTermination: "Immediate termination",
        scheduleTermination: "Scheduled termination",
        typeOfSending: "Type of sending",
        rightnow: "Immediate sending",
        schedule: "Scheduled sending",
        delayed: "Create campaign without sending it",
        subject: "Subject",
        accessFormTitle: "Acces request / Access modification",
        accessForm: "Access Form",
        terminatedUserForm: "Access termination",
        newUser: "New User",
        lastRequest: "Request",
        backToCampaign: "Back to campaign management",
        sessions: "Sessions",
        continue: "Continue",
        cloneCampaign: "Clone campaign",
        currentDl: "Current distribution list",
        accessType: "Choose software and access for the user",
        nameForEmail: "Enter your custom email address",
        customEmail: "Request a different email address",
        communication: "Preferred language"
      },
      action: {
        generatePassword: "Generate password",
        generateNewReport: "Generate a new set of data",
        viewTicketInfo: "View ticket info",
        resetTheList: "Reset the list",
        otherSoftware: "Add additional software and access",
        department: "Which department",
        login: "Sign in",
        logout: "Sign out",
        submit: "Submit",
        testEmail: "Send the email(s) to your Cogir address ",
        chooseDate: "Choose a date",
        chooseManager: "Enter a manager name",
        chooseManagerByMail: "Enter a manager email",
        chooseBuilding: "Enter a building name",
        chooseUser: "Enter a user name",
        chooseUserByMail: "Enter a user email",
        submitUserForm: "Update",
        search: "Search",
        reset: "Reset",
        setStartDate: "Start date",
        missingBuilding: "Please enter the name of the missing property",
        generateReport: "Generate a report",
        generateExcel: "Generate an Excel report",
        getLicensesReport: "Generate a licenses report",
        step1: "Choose a name",
        step2: "Distribution List",
        step3: "Write a message",
        step4: "Start the campaign",
        relaunch: "Relaunch the campaign",
        startCampaign: "Start the campaign",
        chooseDl: "Choose a distribution List",
        sendAccessTo: "Enter the required email address",
      },
      info: {
        maxValue: "Maximum value {0}",
        minValue: "Minimum value {0}",
        noAddressFound: "No address found",
        noDesjData: "No data available",
        noEmployeeSelected: "No employee selected",
        accountDisabled: "The following user accounts have been terminated",
        jobExistForThisId: "This request has been processed and a scheduled task has been created.",
        errorCreateUser: "An error occurred at user creation. Verify submitted information",
        existingEmail: "This email exists for another user",
        managerExist: "This employee is part of the team of {0}. Are you sure you want to integrate him into your team",
        removeUser: "Press the button below to confirm that you are no longer the manager of : ",
        userAlreadyExistInTerminationTable: "A request to terminate this user's job is open",
        delayedUpdate: "Please wait a few minutes to let the changes populate in the Microsoft environment.",
        awaitReport: "You will be notified by email when your report is ready",
        jobRunning: "A task is in progress. Try again in 10 minutes",
        dlForAll: "By default, the user will be subscribed to the following distribution lists:",
        dlForAllAgent: "The user will be subscribed to the following distribution lists:",
        licensesForAgent: "The following licenses will be assigned to the user :",
        choice: "Choices will appear here",
        addToDl: "Request to be added to other distribution lists",
        addToDlAgent: "Add other distribution lists",
        addLicensesForUser: "Add user licenses",
        bypassManager: "If you don't have a supervisor, check the box to bypass field validation",
        updateSupervisorSuccess: "Your supervisor has been successfully changed",
        nodata: "No data was submit",
        confirmation : "Press the button below to confirm : ",
        delete: "Press the button below to confirm deletion : ",
        errorSessionId: "Microsoft is unable to log in to perform operations in the Excel file. Please try again later or contact technical support if the problem persists.",
        errorDelete: "This value cannot be deleted. One or more data depends on it.",
        excelFilesNotExist: "An error occurred while uploading the file : {0}",
        approbationRequest: "Choose whether the application is denied or approved for <strong>{0}</strong>.",
        publicProspectApplication: "Confirm that the application request for <strong>{0}</strong> is compliant.",
        requiredOne: "At least one file is required",
        acknowledgeNewUnit: "Confirm unit change",
        drApprobation: "Waiting for DR Approbation",
        successcanceled: "Visit successfully canceled",
        successnoshow: "Prospect status has been updated",
        showNoshow: "Prospect present or not for a visit",
        partOfDay: "Time of day when contact is made",
        meetingConflict: "There is already one or more events on your calendar for this time slot:",
        goAnyway: "Press submit to bypass this warning.",
        reportsLength: "No data for this request",
        meetingNotFound: "This visit was not found",
        meetingCancel: "This visit has been canceled",
        notCogirUser: "This email address is not part of the cogir.net domain",
        activesessions: "There are active sessions for this building",
        response: "Response Status",
        successgeneric: "Your information has been processed succesfully",
        successmeetingrequest: "Your meeting request has been send",
        nomeetingrequest: "An error occurred while sending the meeting request",
        emailexist: "This email belongs to an active prospect.",
        emailexistuser: "This email belongs to an active user.",
        months : 'January_February_March_April_May_June_July_August_September_October_November_December',
        required: "This field is required",
        email: "This email address is not valid",
        tel: "A phone number should only contain numbers",
        integer: "This must contain number",
        maxLength: "This field is limited to {0} characters",
        minLength: "This field must contain at least {0} characters",
        units: "You must choose at least one unit",
        errorfields: "All fields are required and must be numeric",
        errorgenericarg: "An error has occurred : {0}",
        errorgeneric: "An error has occurred",
        errorlogin: "Try to connect by pressing the login button.",
        errorfile: "Error in file upload",
        textConfirmed: "Press the button below to confirm that the prospect <br> <strong> {0} </strong> <br> has rented the apartment {1}",
        removeConfirmed: "Press the button below to confirm that the prospect <strong> {0} </strong> <br> is unlinked from the apartment {1}",
        removeConfirmedLeads: "Press the button below to confirm that the lead <strong> {0} </strong> is no longer active",
        removeConfirmedProspect: "Press the button below to confirm that the prospect <strong> {0} </strong> is no longer active",
        changeStatusProspect: "Press the button below to confirm that the prospect <strong> {0} </strong> is not interested",
        removeConfirmedUser: "Press the button below to confirm that the user <strong> {0} </strong> is no longer active",
        removeConfirmedUserBuilding: "Press the button below to confirm that the user <strong> {0} </strong> is no longer associate to the building <strong> {1} </strong>",
        removeFromUnitRented: "Press the button below to confirm that the prospect <strong> {0} </strong> is to be remove from the rented unit",
        removeBuilding: "Press the button below to confirm that the building <strong> {0} </strong> is no longer active",
        notoken: "Unable to acquire token",
        myMSALObj: "myMSALObj not valid",
        UserNotAuthorized: "User not authorized",
        MeetingRequestNotSend: "The meeting request was not send",
        startPast: "The start time is earlier than current time.",
        dateBefore: "The start date must be earlier than the end date.",
        dateAfterToday: "The departure date must be after today date",
        maxVisitTime: "The maximum duration of a visit is 5 hours.",
        comments: "Comments ...",
        reason: "Add a reason",
        applicationStatus: "The new application status is : {0}",
        documentTypeError: "You must choose a document type for each field",
        updateMissingDoc: "You must provide {0} files",
        missingDocInProgress: "Missing Document(s). You are waiting for {0} file(s).",
        limitNumberOfFiles: "You cannot upload more than 3 files", 
        limitNumberOfFilesMissingDoc: "You cannot upload more files than the number of required documents",        
        invalidFileType: "File type not supported (.pdf, .jpg or .png) or file too large (max 4 mb)",
        requiredDoc: "File type supported (.pdf, .jpg or .png)",
        invalidFileSize: "The maximum size of all combined files is 4 mb",
        invalidFileTypeCsv: "Invalid file type (csv)",
        invalidFileTypeFormat: "Invalid file format",
        startLocation : "Enter the rental start date",
        confirmationLocation: "Confirm rental date",
        emptyApplication : "No application at this time",
        emptyProspect: "No prospect for this building",
        emptyVacant: "No unit for this building",
        emptyNotification: "No notification at this time",
        emptyField: "All fields must be completed",
        infoevent: "Set a unit ready or not ready for rental",
        buildingExist: "This building is already register in the database",
        nobuilding: "There is no building with this NOCIE and NODIV",
        noendorserpossible : "All applicants have an endorser for this application",
        searchBuilding: "Search by name, address, province/state, country, postal code and building category",
        searchByName: "Search for a name and take accents into account",
        searchDirectory: "Search by display name, first name, last name, email, job title and office location",
        successExcel: "Your information has been processed succesfully. Follow the link to get your Excel report.",
        noCurrentOpenTicket: "No open ticket",
        noCampaign: "No campaign at this time",
        createNewCampaign: "Create a new campaign ?",
        nullvalue: "No data to send",
        startCampaign: "Start the campaign immediately.",
        relaunchCampaign: "Relaunch the campaign immediately. If a relaunch date was scheduled, it will be updated with today's date.",
        cloneWarning: "if you have a campaign in progress it will be closed",
        cloneModal: "Confirm campaign clone : ",
        dateCampaignAlreadyExist: "The chosen campaign date and time exists for a current campaign",
        emailClickConfirmation: "Thank you for confirming your information.",
        userAlreadyExistInClickCampaign: "You have already submitted your information for this campaign.",
        noUserInDl: "Your email address was not found in the campaign distribution list.",
        businessAdvances: "*** Plan at least 48 working hours in advance.",
        futureEmail: "An email address consisting of your first and last name will be created",
        warningAccessForm: "*** Please complete this form in order for best results."
      }
    },
    fr: {
      title: {
        copyright: "Société de gestion Cogir, tous droits réservés.",
      },
      tooltip: {
        portailRemoteApp: "Portail d'applications, requis pour l'utilisation de logiciels tels qu'Hopem et Maestro lorsqu'à l'extérieur des bureaux corporatifs",
        keepHistory: "Permet de consulter les anciens courriels de l'utilisateur, la boite apparaitra directement dans Outlook",
        forwardEmail: "Enverra les nouveaux courriels reçus dans une boite courriel différente",
        accessRestriction: "En cochant cette case, tout nouveau courriel envoyé vers cette adresse sera rejeté",
        accesModification: "Permet de soumettre une demande pour modifier les accès d'un utilisateur existant",
        newAccess: "Permet de soumettre une demande pour créer un nouvel utilisateur qui n'a aucun accès et aucune adresse courriel",
        accessFormComments: "Besoins matériels : Cellulaire, Ordinateur, etc.",
        rightnowTermination: "L'agent traitera la demande dès que possible - à utiliser quand l'employé à déjà quitté",
        scheduleTermination: "Sera traitée au moment choisi - à utiliser quand l'employé n'a pas encore terminé",
      },
      label: {
        updateHourlyRateDefaultValue: "Mettre à jour le taux horaire par défaut",
        hourlyRate: "Taux horaire",
        blockAccount: "Bloquer le compte",
        accessCard: "Carte d'accès Montréal",
        processed: "Traité",
        resetPassword: "Réinitialiser le mot de passe et révoquer les sessions",
        building: "Immeuble",
        userInfo: "Informations de l'utilisateur",
        desjUser: "Utilisateur Desjardins",
        graphUser: "Utilisateur Graph",
        searchByMail: "Recherche par courriel",
        noEmail: "Aucun courriel",
        simpleForm: "Je ne trouve pas l'utilisateur",
        addEmplToExclusionList: "Ajouter un employé à la liste d'exclusion",
        removeEmplFromExclusionList: "Enlever un employé de la liste d'exclusion",
        terminateSelectedEmployees: "Terminer les employés sélectionnés",
        lastPaidDay: "Dernier jour payé",
        time: "Heure",
        sendingType: "Type d'envoi",
        billingReport: "Rapport de facturation",
        DesjTerminationTable: "Tableaux des terminaisons Desjardins",
        DesjExclusionTable: "Tableaux des exclusions Desjardins",
        codeFacturation: "Code de facturation",
        keepInMailbox: "Conserver les courriels dans la boite de l'utilisateur",
        accessRequestToRemove: "Accès à retirer",
        buildingFilter: "Immeubles",
        jobName: "Nom",
        jobData: "Données",
        jobStatus: "Statut",
        password: "Mot de passe",
        autoreply: "Configurer un message d'absence",
        accessRestriction: "Bloquer la réception de nouveaux courriels",
        countryCode: "Code du pays",
        removeUser: "Enlever l'utilisateur",
        addUserToManager: "Ajouter une personne à l'équipe",
        removedDl: "Enlever des listes de distribution",
        forwardEmail: "Transférez les courriels à",
        removedManager: "Enlevez le superviseur",
        keepHistory: "Donner accès à l'historique courriel à",
        sendAs: "Envoyez en tant que",
        lastSignInDetails: "Détails des dernières connexions",
        lastSignIn: "Dernière connexion",
        lastSignInNonInteractive: "Dernière connexion non interactive",
        signInTable: "Tableau des dernières connexions",
        openScheduleJob: "Tâches cédulées ouvertes",
        licenseReport: "Rapport des licenses",
        requestType: "Demande",
        backToCurrentOpenTicket: "Retour à la liste des tickets ouverts",
        ticketInfo: "Information du ticket",
        enabled: "licences(s) disponible(s)",
        licenses: "Listes des licences disponibles",
        domains: "Liste des domaines disponibles",
        remove: "Enlever",
        choose: "Choisir",
        creation: "Création",
        userCreationDate: "Création de l'utilisateur",
        createUser: "Créer l'utilisateur",
        createTable: "Tableau des utilisateurs",
        ticketTable: "Ticket ouvert",
        otherSoftware: "Autres logiciels et accès",
        yes: "oui",
        no: "non",
        salaryView: "Visibilité aux salaires",
        department: "Département",
        cialocation: "AIC RPA",
        ciarh: "ATS",
        fgl: "FGL module Projet",
        acrobatPro: "Acrobat (version payante)",
        comments: "Commentaires, précisions ou autres",
        materialNeeds: "Besoins matériels ex. ordinateur, cellulaire, etc.",
        materialNeedsInfo: "Spécifiez vos besoins matériels dans la boîte de commentaire.",
        sendAccessTo: "Envoyez l'accès à",
        remoteAccess: "Portail Remote App",
        nameForEmail: "Entrez l'adresse courriel souhaité",
        customEmail: "Demander une adresse courriel différente",
        newAccess: "Nouveau",
        accesModification: "Modification d'accès",
        user: "Utilisateur",
        resetApplication: "Réinitialiser l'application",
        resetApplicationConfirmation: "Svp confirmer que vous voulez fermer l'application en cours.",
        details: "Détails",  
        active: "Actif",
        ended: "Terminé",
        incomplete: "Incomplète",
        close: "Fermé",
        campaignName: "Nom",
        campaignDesc: "Description",
        campaignStartDate: "Date de début",
        campaignStartTime: "Heure de début",
        campaignRelaunch: "Relance",
        campaignStatus: "Statut",
        departureDate: "Date de départ",
        name: "Nom",
        displayName: "Nom public",
        fullname: "Nom complet",
        fname: "Prénom",
        lname: "Nom",
        email: "Courriel",
        tel: "Téléphone",
        ext: "Ext",
        mobile: "Cellulaire",
        officePhone: "Téléphone au bureau",
        offices: "Lieu de travail",
        job: "Titre d'emploi",
        manager: "Superviseur",
        language: "Langue",
        french: "Français",
        english: "Anglais",
        admin: "Admin",
        userForm: "Mes infos",
        resName: "Nom de l'immeuble",
        address: "Adresse",
        street: "Adresse",
        zip: "Code postal",
        city: "Ville",
        province: "Province/État",
        country: "Pays",
        className: "Catégorie",
        add: "Ajouter",
        edit: "Modifier",
        delete: "Supprimer",
        tablesize: "Nombre d'entrée(s) dans la table",
        emailNotification: "Envoi d'un courriel de notification",
        cannotFindMyBuilding: "Je ne trouve pas mon immeuble",
        externalLink: "Liens externes",
        multifactorsAuth: "Configurer l'authentification multifacteur",
        passwordReset: "Configurer réinitialisation de mot de passe libre-service",
        teams: "Microsoft Teams",
        outlook: "Microsoft Outlook",
        userDirectory: "Répertoire",
        contactDetails: "Coordonnées",
        campaign: "Gestion des campagnes",
        webUrl: "Accéder au fichier ici",
        distributionList: "Liste de distribution",
        displayDistributionList: "Afficher la liste de distribution",
        dlCount: "Taille de la liste",
        step1: "Étape 1",
        step2: "Étape 2",
        step3: "Étape 3",
        step4: "Étape 4",
        templateName: "Nom du template",
        templateVersion: "Version du template",
        typeOfTermination: "Type de terminaison",
        rightnowTermination: "Terminaison immédiate",
        scheduleTermination: "Terminaison cédulée",
        typeOfSending: "Type d'envoi",
        rightnow: "Envoi immediat",
        schedule: "Envoi planifié",
        delayed: "Créer la campagne sans l'envoyer",
        subject: "Sujet",
        newUser: "Nouvel utilisateur",
        accessFormTitle: "Demande / modification d'accès",
        accessForm: "Formulaire d'accès",
        terminatedUserForm: "Terminaison d'accès",
        connexionCount: "Connexion",
        connexionList: "List des connections",
        lastRequest: "Requête",
        backToCampaign: "Retour à la gestion des campagnes",
        sessions: "Sessions",
        continue: "Continuer",
        cloneCampaign: "Cloner cette campagne",
        currentDl: "List de distribution actuelle",
        accessType: "Choisir les accès pour l'utilisateur",
        communication: "Langue de communication"
      },
      action: {       
        generatePassword: "Générer un mot de passe",
        generateNewReport: "Générer un nouveau lot de données",
        viewTicketInfo: "Voir les informations du ticket",
        resetTheList: "Effacer la liste",
        otherSoftware: "Ajouter d'autres logiciels ou accès",
        department: "Quel département",
        login: "S'identifier",
        logout: "Déconnexion",
        submit: "Soumettre",
        testEmail: "Envoyez le(s) courriel(s) à votre adresse Cogir ",
        chooseDate: "Choisir une date",
        chooseManager: "Entrez le nom d'un superviseur",
        chooseManagerByMail: "Entrez l'adresse courriel d'un superviseur",
        chooseBuilding: "Entrez le nom d'un immeuble",
        chooseUser: "Entrez le nom d'un utilisateur",
        chooseUserByMail: "Entrez l'adresse courriel d'un utilisateur",
        submitUserForm: "Mettre à jour",
        search: "Chercher",
        reset: "Réinitialiser",
        setStartDate: "Date de départ",
        missingBuilding: "Veuillez saisir le nom de la propriété manquante",
        generateReport: "Obtenir un rapport",
        generateExcel: "Obtenir un rapport Excel",
        getLicensesReport: "Obtenir un rapport des licences",
        step1: "Choisir un nom",
        step2: "Liste de distribution",
        step3: "Écrire le message",
        step4: "Commencer la campagne",
        relaunch: "Relancer la campagne",
        startCampaign: "Lancer la campagne",
        chooseDl: "Choisir une liste de distribution",
        sendAccessTo: "Entrez l'adresse courriel requise"
      },
      info: {
        maxValue: "Valeur maximale {0}",
        minValue: "Valeur minimale {0}",
        noAddressFound: "Aucune adresse trouvée",
        noDesjData: "Aucune donnée disponible",
        noEmployeeSelected: "Aucun employé sélectionné",
        accountDisabled: "Les comptes des utilisateurs suivant ont été fermés : ",
        jobExistForThisId: "Cette demande a été traitée et une tâche cédulée a été créée.",
        errorCreateUser: "Une erreur est survenue à la création de l'utilisateur. Vérifier les informations soumises.",
        existingEmail: "Ce courriel existe pour un autre utilisateur",
        managerExist: "Cet employé fait partie de l'équipe de {0}. Êtes-vous sûr de vouloir l'intégrer à votre équipe ?",
        removeUser: "Appuyez sur le bouton ci-dessous pour confirmer que vous n'êtes plus le manager de  : ",
        userAlreadyExistInTerminationTable: "Une requête pour terminer l'emploie de cet utilisateur est ouverte",
        delayedUpdate: "Svp attendre quelques secondes afin de laisser les changements se répandre dans l'environnement de Microsoft.",
        awaitReport: "Vous allez être notifié par email lorsque votre rapport est prêt",
        jobRunning: "Une tâche est en cours. Essayez à nouveau dans 10 minutes",
        dlForAll: "Par defaut, l'utilisateur sera inscrit aux listes de distribution suivantes :",
        dlForAllAgent: "L'utilisateur sera inscrit aux listes de distribution suivantes :",
        licensesForAgent: "Les licenses suivantes vont être assigner à l'utilisateur :",
        choice: "Les choix apparaîtront ici",
        addToDl: "Demande d'ajout à d'autres listes de distribution",
        addToDlAgent: "Ajouter d'autres liste de distribution",
        addLicensesForUser: "Ajouter des licences pour l'utilisateur",
        bypassManager: "Si vous n'avez pas de superviseur, cochez la case pour contourner la validation du champs",
        updateSupervisorSuccess: "Votre superviseur a été changé avec succès.",
        nodata: "Aucun changement détecté",
        confirmation : "Appuyez sur le bouton ci-dessous pour confirmer : ",
        delete: "Appuyez sur le bouton ci-dessous pour confirmer la suppression : ",
        errorSessionId: "Microsoft est incapable d'ouvrir une session pour effectuer des opérations dans le fichier Excel. Veuillez réessayer plus tard (5 min) ou contacter le support technique si le problème persiste.",
        errorDelete: "Cette valeur ne peut pas être supprimée. Une ou plusieurs données en dépendent.",
        excelFilesNotExist: "Une erreur est survenue lors du téléversement du fichier : {0}",
        approbationRequest: "Choisir si l'application est approuvée ou refusée pour <strong>{0}</strong>.",
        publicProspectApplication: "Confirmer que la demande d'application pour <strong>{0}</strong> est conforme.",
        requiredOne: "Au moins un fichier est obligatoire",
        acknowledgeNewUnit: "Confirmer le changement d'unité",
        drApprobation: "En attente de l'approbation du DR",
        successcanceled: "Visite annulée avec succès",
        successnoshow: "Le statut du prospect a été mis à jour",
        partOfDay: "Moment de la journée de la prise de contact",
        showNoshow: "Prospect présent ou non pour une visite",
        meetingConflict: "Il y a déjà un ou des évènements à votre calendrier pour cette plage horaire : ",
        goAnyway: "Appuyez sur soumettre pour poursuivre malgré l'avertissement.",
        reportsLength: "Aucune données pour cette requête.",
        meetingNotFound: "Cette visite n'a pas été trouvée",
        meetingCancel: "Cette visite a été annulée",
        notCogirUser: "Cette adresse courriel ne fait pas partie du domaine cogir.net",
        activesessions: "Il y a des sessions actives pour cette propriété",
        response: "Statut de la réponse",
        successgeneric: "Vos informations ont été soumises avec succès",
        successmeetingrequest: "Votre invitation a bien été envoyée",
        nomeetingrequest: "Une erreur s'est produite lors de l'envois de l'invitation",
        errorfields: "Tous les champs du tableau sont requis et doivent être numérique",
        errorgenericarg: "Une erreur s'est produite : {0}",
        errorgeneric: "Une erreur s'est produite",
        errorlogin: "Essayer de vous connecter en appuyant sur le bouton de connection",
        emailexist: "Ce courriel appartient à un candidat actif.",
        emailexistuser: "Ce courriel appartient à un utilisateur actif.",
        months : 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre',
        required: "Ce champs est requis",
        email: "Cette adresse courriel n'est pas valide",
        emailattendees: "Add email address separated by comma",
        tel: "Un numéro de téléphone doit seulement contenir des nombres",
        integer: "Ce champs doit contenir des nombres",
        maxLength: "Ce champ est limité à {0} charactères",
        minLength: "Ce champ doit contenir {0} charactères au minimum",
        units: "Vous devez choisir une ou plusieurs unités",
        errorfile: "Le fichier téléversé n'est pas valide",
        textConfirmed: "Appuyez sur le bouton ci-dessous pour confirmer que le prospect <br> <strong> {0} </strong> <br> a loué l'appartement {1}",
        removeConfirmed: "Appuyez sur le bouton ci-dessous pour confirmer que le prospect <br> <strong> {0} </strong> <br> est dissocié de l'appartement {1}",
        removeConfirmedLeads: "Appuyez sur le bouton ci-dessous pour confirmer que le lead <strong> {0} </strong> n'est plus actif",
        removeConfirmedProspect: "Appuyez sur le bouton ci-dessous pour confirmer que le prospect <strong> {0} </strong> n'est plus actif",
        changeStatusProspect: "Appuyez sur le bouton ci-dessous pour confirmer que le prospect <strong> {0} </strong> n'est pas intéressé",
        removeConfirmedUser: "Appuyez sur le bouton ci-dessous pour confirmer que l'utilisateur <strong> {0} </strong> n'est plus actif",
        removeConfirmedUserBuilding: "Appuyez sur le bouton ci-dessous pour confirmer que l'utilisateur <strong> {0} </strong> n'est plsu associée à la propriété <strong> {1} </strong>",
        removeFromUnitRented: "Appuyez sur le bouton ci-dessous pour confirmer que le prospect <strong> {0} </strong> doit être enlever du tableau des unités louées",
        removeBuilding: "Appuyez sur le bouton ci-dessous pour confirmer que la propriété <strong> {0} </strong> sera enlevée de la liste",
        notoken: "Aucun token",
        myMSALObj: "myMSALObj invalide",
        UserNotAuthorized: "Utilisateur non autorisé",
        MeetingRequestNotSend: "L'invitation n'a pas été envoyée",
        startPast: "L'heure de début est dans le passé.",
        dateBefore: "La date de début doit être avant la date de fin.",
        dateAfterToday: "La date de départ doit être après la date du jour",
        maxVisitTime: "La durée maximale d'une visite est de 5 heures.",
        comments: "Commentaires ...",
        reason: "Ajouter une explication",
        applicationStatus: "Le nouveau statut de l'application est : {0}",
        documentTypeError: "Vous devez choisir un type de document pour chaque champs",
        updateMissingDoc: "Vous devez soumettre {0} fichier(s)",
        missingDocInProgress: "En attende de document manquant, vous allez recevoir {0} fichier(s).",
        limitNumberOfFiles: "Vous ne pouvez pas téléverser plus de 3 fichier(s)",
        limitNumberOfFilesMissingDoc: "Vous ne pouvez pas téléverser plus fichiers que de documents manquants",  
        invalidFileType: "Type de fichier non supporté (.pdf, .jpg ou .png) ou fichier trop volumineux (max 4 mb)",
        requiredDoc: "Types de fichier supportés (.pdf, .jpg or .png)",
        invalidFileSize: "La taille maximum de tous les fichiers combinés est de 4 mb",
        invalidFileTypeCsv: "Type de fichier non supporté (csv)",
        invalidFileTypeFormat: "Le format du fichier est invalide",
        startLocation : "Inscrire la date du début de location",
        confirmationLocation: "Confirmer la date de location",
        emptyApplication : "Aucune application en ce moment",
        emptyProspect: "Aucun prospect pour cet immeuble",
        emptyVacant: "Aucune unité pour cet immeuble",
        emptyNotification: "Aucune notification en ce moment",
        emptyField: "Tous les champs doivent être remplis",
        infoevent: "Permet de marquer une unité disponible ou non disponible à la location",
        buildingExist: "Cet immeuble est déjà présent dans la base de données",
        nobuilding: "Il n'y a aucune propriété avec ce NOCIE et ce NODIV",
        noendorserpossible : "Tous les applicants ont un endosseur pour cette application",
        searchBuilding: "Recherche par nom, adresse, province/état, pays, code postal et catégorie d'immeuble",
        searchByName: "Rechercher un nom en tenant compte des accents",

        searchDirectory: "Recherche par nom d'affichage, prénom, nom de famille, courriel, titre d'emploi et lieu de travail",
        successExcel: "Vos informations ont été soumises avec succès. Suivez le lien pour obtenir votre rapport Excel.",
        noCurrentOpenTicket: "Aucun ticket ouvert",
        noCampaign: "Aucune campagne en cours pour le moment",
        createNewCampaign: "Créer une nouvelle campagne ?",
        nullvalue: "Aucunes données à envoyer",
        startCampaign: "Lancer la campagne immédiatement.",
        relaunchCampaign: "Relancer la campagne immédiatement. Si une date de relance était prévue, elle sera mise à jour avec la date du jour.",
        cloneWarning: "Si vous avez une campagne en cours, elle sera fermée.",
        cloneModal: "Confirmer le clonage de la campagne : ",
        dateCampaignAlreadyExist: "La date et l'heure de campagne choisie existe pour une campagne en cour",
        emailClickConfirmation: "Merci d'avoir confirmé vos informations.",
        userAlreadyExistInClickCampaign: "Vous avez déjà soumis vos informations pour cette camapagne.",
        noUserInDl: "Votre adresse courriel n'a pas été trouvée dans la liste de distribution de la campagne.",
        businessAdvances: "*** Prévoir au minimum 48 heures ouvrables à l'avance.",
        futureEmail: "Une adresse courriel formée de votre prénom et nom de famille sera créée",
        warningAccessForm: "*** SVP Remplir ce formulaire dans l'ordre pour de meilleurs résultats."
      }
    }
  }
})
