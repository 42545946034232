<template>
  <b-container>
    <b-row v-if="signInList.length > 0" class="pt-2">
      <b-form-select v-model="signIn" :options="signInList"></b-form-select>
    </b-row>
    <hr />
    {{ signIn }}
    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";
import moment from "moment";

export default {
  mixins: [globalF],
  props: ["userInfo"],
  data() {
    return {
      spinner: false,
      disabled: false,
      error: "",
      info: "",
      signInList: [],
      signIn: null,
    };
  },
  mounted() {
    let token = this.$store.getters.getAppAccessToken;
    let userPrincipalName = this.userInfo.userPrincipalName;
    let graphEndpoint = `https://graph.microsoft.com/v1.0/auditLogs/signIns?$filter=startsWith(userPrincipalName,'${userPrincipalName}')`;

    this.getSignInDetails(token, graphEndpoint);
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
    url: function () {
      return this.getUrl();
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLg: function () {
      this.actionCall("building");
    },
  },
  methods: {
    getSignInDetails(token, graphEndpoint) {
      this.spinner = true;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          if (response.status === 200) {
            this.mapSignInEvent(response.data.value);
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    mapSignInEvent(signInList) {
      this.signInList = signInList.map((s) => {
        let signInDate = moment(s.createdDateTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return {
          text: signInDate,
          value: s,
        };
      });
    },
  }
};
</script>
