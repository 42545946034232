<template>
    <b-container>
      <!-- btn nav -->
      <div class="mt-4">
        <b-button variant="link" @click="goTo('Admin')">{{ $t("label.backToCampaign") }}</b-button>
      </div>
      <div class="mt-4">
        <b-button class="mr-2" :variant="currentStep === 'step1' ? 'success' : 'secondary'" :disabled="step1.disabled" @click="switchStep('step1')">1 - {{ $t('action.step1') }}</b-button>
        <b-button class="mr-2" :variant="currentStep === 'step2' ? 'success' : 'secondary'" :disabled="step2.disabled" @click="switchStep('step2')">2 - {{ $t('action.step2') }}</b-button>
        <b-button class="mr-2" :variant="currentStep === 'step3' ? 'success' : 'secondary'" :disabled="step3.disabled" @click="switchStep('step3')">3 - {{ $t('action.step3') }}</b-button>
        <b-button class="mr-2" :variant="currentStep === 'step4' ? 'success' : 'secondary'" :disabled="step4.disabled" @click="switchStep('step4')">4 - {{ $t('action.step4') }}</b-button>
      </div>
      <!--<div class="mt-2">
        <b-button v-if="processData > 0" variant="link" @click="resetApplication">{{$t('label.resetApplication')}}</b-button>
      </div>-->
      <div v-if ="currentStep === 'step2'">
        <step-2 v-on:changePage="setNextPage" v-on:changeStep ="switchStep" :btnNextStep="btnNextStep3" />
      </div>
      <div v-else-if ="currentStep === 'step3'">
        <step-3 v-on:changePage="setNextPage" v-on:changeStep ="switchStep" :btnNextStep="btnNextStep4" />
      </div>
      <div v-else-if ="currentStep === 'step4'">
        <step-4 v-on:changePage="setNextPage" v-on:changeStep ="switchStep" :btnNextStep="btnNextStep5" />
      </div>
      <div v-else>
        <step-1 v-on:changePage="setNextPage" v-on:changeStep ="switchStep" :btnNextStep="btnNextStep2" />
      </div>

      <b-modal id="ResetApplication" title="Confirmation" hide-footer>
        <ResetApplicationModal />
      </b-modal>   
    </b-container>
</template>

<script>
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'
import Step4 from './Step4.vue'
import ResetApplicationModal from '../Modal/ResetApplicationModal.vue'
import { globalF } from '../Helpers';

export default {  
mixins: [globalF],  
props: [],
data() { 
  return { 
    step1 : {disabled: false },
    step2 : {disabled: true },
    step3 : {disabled: true },
    step4 : {disabled: true },
    currentStep : 'step1',
    btnNextStep1: false,
    btnNextStep2: false,
    btnNextStep3: false,
    btnNextStep4: false,
    btnNextStep5: false,
    appReady: false,
    error:""
}},
mounted () {
  let campaign = this.$store.getters.getCampaign;
  let distributionList = this.$store.getters.getDistributionsList;
  let templates = this.$store.getters.getCampaignTemplateVersion;

  this.getProcessData(campaign, distributionList, templates);
},
computed: {
  url: function () {
    return this.getUrl();
  },
  user: function () {
    return this.$store.getters.getUser;
  }
},
methods:{
    getProcessData: function (campaign, distributionList, templates) {
  
        let campaignFilter = campaign.filter(p => p.Status === 3 && p.UserId === this.user.IdMyProfile);

        if (campaignFilter.length > 0) {

          let idCampaign = campaignFilter[0].Id;
          this.step2.disabled = false;
          this.currentStep = 'step2';

          let distributionListFilter = distributionList.filter(p => p.IdCampaign === idCampaign);

          if (distributionListFilter.length > 0) {
            this.step3.disabled = false;
            this.currentStep = 'step3';
          }
          else {
            this.step3.disabled = true;
          }

          let templatesFilter = templates.filter(p => p.IdCampaign === idCampaign);

          if (templatesFilter.length > 0) {
            this.step4.disabled = false;
            this.currentStep = 'step4';
          }
          else {
            this.step4.disabled = true;
          }
        }
  },
  setNextPage(page) {  
    
    if (page === 'CampaignDispatcher') {
      let campaign = this.$store.getters.getCampaign;
      let distributionList = this.$store.getters.getDistributionsList;
      let templates = this.$store.getters.getCampaignTemplateVersion;
      this.getProcessData(campaign, distributionList, templates);
    }
    else {
      this.$emit('changePage', page); 
    }
           
  },
  switchStep(step) {
    this.currentStep = step;
  },
  resetApplication() {
    this.$bvModal.show("ResetApplication");
  },
  goTo : function (val) {
    this.$store.commit('setAdminActiveTab', 'Campaign');
    this.$emit('changePage', val);
  },
},
components: {
    Step1,
    Step2,
    Step3,
    Step4,
    ResetApplicationModal
  }
}
</script>