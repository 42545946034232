<template>
  <b-container>
    <b-form @submit="onSubmit">
      <b-row class="mt-2">
        <b-col sm>
          <div>
            <strong>{{ $t("label.departureDate") }}</strong>
          </div>
          <datepicker
            input-class="datepicker"
            v-model="form.departureDate"
            :placeholder="$t('action.chooseDate')"
            :language="language"
            :disabledDates="disableddepartureDate"
          ></datepicker>
          <div>
            <div
              class="text-danger"
              v-if="
                !$v.form.departureDate.required && $v.form.departureDate.$dirty
              "
            >
              {{ $t("info.required") }}
            </div>
            <vue-timepicker
              class="mt-2"
              :minute-interval="5"
              v-model="form.departureTime"
              hide-clear-button
            ></vue-timepicker>
          </div>
          <b-button variant="link" @click="reset()">{{
            $t("action.reset")
          }}</b-button>
          <hr />
        </b-col>
      </b-row>
      <b-button
        class="mt-4"
        type="submit"
        variant="success"
        :disabled="disabled"
        >{{ $t("action.submit") }}</b-button
      >
      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="error" class="mt-2">
        <b-col sm class="mt-4">
          <b-alert show variant="danger">
            <p>{{ error }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from "axios";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["values", "modalid"],
  data() {
    return {
      form: {
        request: "editDepartureDate",
        idTermination: this.values.Id,
        departureDate: this.getDateData(),
        departureTime: this.getTimeData(),
      },
      success: false,
      spinner: false,
      disabled: false,
      error: "",
    };
  },
  validations() {
    let form = {
      departureDate: {
        required,
      },
    };
    return { form };
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disableddepartureDate: function () {
      let myDate = moment().startOf("day").toDate();
      return { to: myDate };
    },
  },
  methods: {
    reset() {
      this.form.departureDate = "";
      this.form.departureTime = "00:00";
    },
    getTimeData: function () {
      return this.values.DepartureTime ? this.values.DepartureTime : "00:00";
    },
    getDateData: function () {
      let date = this.values.DepartureDate ? this.values.DepartureDate : "";
      if (date) {
        date = moment(date).toDate();
      }
      return date;
    },
    validateDate() {
      let pass = true;
      let departureDate = moment(this.form.departureDate).format("YYYY-MM-DD");
      let departureTime = this.form.departureTime;
      let departureDateTime = moment(
        `${departureDate} ${departureTime}`,
        "YYYY-MM-DD HH:mm"
      );
      let today = moment();
      if (departureDateTime < today) {
        this.error = this.$t("info.dateAfterToday");
        pass = false;
      }
      return pass;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.success = false;
      this.error = "";
      this.info = "";
      this.$v.$touch();
      if (!this.$v.$invalid && this.validateDate()) {
        this.disabled = true;
        this.spinner = true;
        this.form.departureDate = moment(this.form.departureDate).format(
          "YYYY-MM-DD"
        );

        axios
          .post(this.url, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then(() => {
            this.disabled = true;
            this.spinner = false;
            this.success = true;
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      }
    },
  },
  components: {
    Datepicker,
    VueTimepicker,
  },
};
</script>
