<template>
  <b-container>
    <b-nav tabs class="mt-4">
      <b-nav-item :active="active === 'res'" @click="active = 'res'">{{
        $t("label.resName")
      }}</b-nav-item>
    </b-nav>

    <AdminTable v-if="active === 'res'" :list="[]" type="PropertyName" client="Rh" />
    
  </b-container>
</template>

<script>
import { globalF } from "./Helpers";
import AdminTable from "./AdminTable";

export default {
  mixins: [globalF],
  data() {
    return {
      active: 'res',
    };
  },
  validations() {},
  mounted() {},
  computed: {},
  methods: {
    setNextPage(page) {
      this.$emit("changePage", page);
    },
  },
  components: {
    AdminTable
  },
};
</script>
