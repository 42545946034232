<template>
  <div>
    <div class="mt-2" v-if="tableData.length > 0">
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-input
            v-model="search"
            :placeholder="$t('action.search')"
          ></b-form-input>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table
            id="TicketTable"
            stacked="lg"
            bordered
            :fields="myfields"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(IdTicket)="row">
              <a :href=getZendeskLink(row.item.IdTicket) target="_blank">{{row.item.IdTicket}}</a>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="TicketTable"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ['request'],
  data() {
    return {
      rawData: [],
      tableData: [],
      count: null,
      dataFromTable: {},
      sortBy: "Creation",
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
    };
  },
  mounted() {
      this.getTableData();
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let IdTicket = o.IdTicket ? o.IdTicket.toString() : "";
        let fn = o.Fname ? o.Fname : "";
        let Fname = fn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let ln = o.Lname ? o.Lname : "";
        let Lname = ln.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let StartDate = o.StartDate ? o.StartDate : "";

        let cre = o.Creation ? o.Creation : "";
        let Creation = cre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let ucre = o.UserCreation ? o.UserCreation : "";
        let UserCreation = ucre.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        return (
          IdTicket.includes(s) ||
          Fname.toLowerCase().includes(s) ||
          Lname.toLowerCase().includes(s) ||
          StartDate.toLowerCase().includes(s) ||
          Creation.toLowerCase().includes(s) ||
          UserCreation.toLowerCase().includes(s) 
        );
      });

      return find;
    },
    myfields: function () {
      let myFields = [
        {
          key: "IdTicket",
          sortable: true,
          label: "Id ticket",
        },
        { key: "Fname", sortable: true, label: this.$t("label.fname") },
        { key: "Lname", sortable: true, label: this.$t("label.lname") },
        {
          key: "StartDate",
          sortable: true,
          label: this.$t("label.campaignStartDate"),
        },
        {
          key: "Creation",
          sortable: true,
          label: this.$t("label.creation"),
        },
        {
          key: "UserCreation",
          sortable: true,
          label: this.$t("label.userCreationDate"),
        },
      ];

      return myFields;
    },
    url: function () {
      return this.getUrl();
    }
  },
  watch: {
    currentLg: function () {},
  },
  methods: {
    getZendeskLink: function ($idTicket) {
      let zendeskBaseUrl = this.$store.getters.getZendeskLink;
      const env = process.env.VUE_APP_WHERE;
      let base = env === 'prod' ? zendeskBaseUrl['prod'] : zendeskBaseUrl['home'];
      return base + 'agent/tickets/' + $idTicket;
    },
    getTableData() {
      this.spinner = true; 
      axios
        .post(
          this.url,
          { request: "getProcessedUserList", requestType: "creation" },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.tableData = response.data;
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    setNextPage(accessFormData, page) {
      this.$store.commit('addCurrentUserToCreate', accessFormData);
      this.$emit("changePage", page);
    },
    resetTable: function () {
      this.search = "";
    },
  },
  components: {},
};
</script>
