<template>
  <b-container>
    <b-row align-h="center" class="mt-4">
      <b-col sm="4">
        <b-alert v-if="emailMessage.alert === 'success'" show variant="success">
          <p>{{emailMessage.message}}</p>
        </b-alert>
        <b-alert v-else show variant="danger">
          <p>{{emailMessage.message}}</p>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: [],
  data() {
    return {};
  },
  computed: {
    emailMessage: function () {
      return this.$store.getters.getClickEmailMessage;
    }
  },
  methods: {},
};
</script>
