<template>
  <b-container>
    <b-row align-h="center" class="mt-4">
      <b-col sm="6">
        <b-alert class="text-center" show variant="success">
          <p>{{$t('info.successgeneric')}}</p>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
