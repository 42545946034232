<template>
    <div>
        <b-row>
          <b-col sm="4" class="mt-4">
            <div><strong>{{ $t('label.templateName') }}</strong></div>
            <b-form-select v-model="version" :options="templatesList"></b-form-select>
            <div class="text-danger" v-if="!$v.version.required && $v.version.$dirty">{{ $t("info.required") }}</div>
          </b-col>
        </b-row>

        <hr>

        <div><strong><u>{{ $t('label.french') }}</u></strong></div>

        <b-row>
            <b-col sm='4' class="mt-4">
                <div><strong>{{ $t('label.subject') }}</strong></div>
                <b-form-input v-model.trim="$v.subject.$model" :placeholder="$t('label.subject')"></b-form-input>
                <div class="text-danger" v-if="!$v.subject.required && $v.subject.$dirty">{{ $t("info.required") }}</div>
                <div class="text-danger" v-if="!$v.subject.maxLength && $v.subject.$dirty">{{ $t("info.maxLength", [150]) }}</div>
            </b-col>
        </b-row>

        <!-- Editor for message -->
        <vue-editor class="mt-4" v-model.trim="message" :editorToolbar="customToolbar"></vue-editor>
        <div class="text-danger" v-if="!$v.message.required && $v.message.$dirty && !$v.message.requiredAfterHtmlStripped">{{ $t("info.required") }}</div>
        <div class="text-danger" v-if="!$v.message.maxLength && $v.message.$dirty">{{ $t("info.maxLength", [5000]) }}</div>

        <hr>
        
        <div><strong><u>{{ $t('label.english') }}</u></strong></div>

        <b-row>
            <b-col sm='4' class="mt-4">
                <div><strong>{{ $t('label.subject') }}</strong></div>
                <b-form-input v-model.trim="$v.subjectEn.$model" :placeholder="$t('label.subject')"></b-form-input>
                <div class="text-danger" v-if="!$v.subjectEn.required && $v.subjectEn.$dirty">{{ $t("info.required") }}</div>
                <div class="text-danger" v-if="!$v.subjectEn.maxLength && $v.subjectEn.$dirty">{{ $t("info.maxLength", [150]) }}</div>
            </b-col>
        </b-row>

        <!-- Editor for message -->
        <vue-editor class="mt-4" v-model.trim="messageEn" :editorToolbar="customToolbar"></vue-editor>
        <div class="text-danger" v-if="!$v.messageEn.required && $v.messageEn.$dirty && !$v.messageEn.requiredAfterHtmlStripped">{{ $t("info.required") }}</div>
        <div class="text-danger" v-if="!$v.messageEn.maxLength && $v.messageEn.$dirty">{{ $t("info.maxLength", [5000]) }}</div>
      
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { globalF } from '../Helpers';
import { required, maxLength } from 'vuelidate/lib/validators';

//custom validator for vuelidate
const requiredAfterHtmlStripped = (message) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = message;
  let content = tmp.textContent || tmp.innerText || '';
  return content.length > 0 ? true: false;
}

export default {  
mixins: [globalF],  
props: ['myTemplate'],
data() { 
  return {
    campaign: this.getData('campaign'),
    distribution: this.getData('distribution'),
    version: this.getData('version'),
    message: this.getData('message'),
    subject: this.getData('subject'),
    messageEn: this.getData('messageEn'),
    subjectEn: this.getData('subjectEn')
  }
},
validations() {

  return {
    version: {
        required
      },
      subject: {
        required,
        maxLength: maxLength(150)
      },
      message: {
          required,
          requiredAfterHtmlStripped,
          maxLength: maxLength(5000)
      },
      subjectEn: {
        required,
        maxLength: maxLength(150)
      },
      messageEn: {
        required,
        requiredAfterHtmlStripped,
        maxLength: maxLength(5000)
      }
    };
},
computed: {
  url: function () {
    return this.getUrl();
  },
  user: function () {
    return this.$store.getters.getUser;
  },
  templatesList: function () {
    return this.getTemplatesList();
  },
  customToolbar: function () {
    //:editorToolbar="customToolbar"
    return [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check"}],          
            [{ color: [] }],
            ["link"]
      ];
  }
},
methods:{
  getData: function (type) {
    let data = null;
    if (this.myTemplate) {
      if (type === 'campaign') {
        data = this.myTemplate.IdCampaign;
      }
      if (type === 'distribution') {
        data = this.myTemplate.IdDistributionList;
      }
      if (type === 'version') {
        data = this.myTemplate.IdTemplate;
      }
      if (type === 'subject') {
        data = this.myTemplate.Subject;
      }
      if (type === 'message') {
        data = this.myTemplate.Message;
      }
      if (type === 'subjectEn') {
        data = this.myTemplate.SubjectEn;
      }
      if (type === 'messageEn') {
        data = this.myTemplate.MessageEn;
      }
      
    }
    return data;
  },
  getTemplateData: function () {

    let templateData = null;
    
    this.$v.$touch();
    if (!this.$v.$invalid) {
          templateData = {
            'campaign': this.campaign,
            'distribution': this.distribution,
            'version' : this.version,
            'subject': this.subject,
            'message' : this.message,
            'subjectEn': this.subjectEn,
            'messageEn' : this.messageEn
          }
    }
    
    return templateData;
  },
  setNextPage(page) {
      this.$emit('changePage', page);
  },
  setNextStep(step) {
      this.$emit('changeStep', step);
  }
},
components: {
  VueEditor
}
}
</script>