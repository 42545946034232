import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//For ajax data
export const store = new Vuex.Store({
  state: {
    //env
    urls: {
      "home": { json: "http://127.0.0.1:8093" },
      "staging": { json: "https://staging.cogir.net:8091" },
      "prod": { json: { en: "https://myprofil-api.azurewebsites.net", fr: "https://myprofil-api.azurewebsites.net" } }
    },
    zendeskLink: {
      "home" : "https://d3v-cogir.zendesk.com/",
      "prod" : "https://cogirsupport.zendesk.com/"
    },
    //login
    msalObj: null,
    microsoftAccount: null,
    userToken: null,
    user: null,
    buildings: [],
    cities: [],
    provinces: [],
    countries: [],
    classNames: [],
    appAccessToken: null,
    employeeId: null,
    isEmailNoBuildingSent: false,
    campaign: [],
    dates: [],
    distributionsList: [],
    campaignTemplate: null,
    campaignTemplateVersion: [],
    adminActiveTab: '',
    sessions: [],
    sessionTableValue: null,
    collaboratorService: null,
    clickEmailMessage: { message: "Error", alert: "danger" },
    currentUserToCreate: null,
    graphProductName: [],
    otherProducts: [],
    subscribedSkus: [],
    additionnalDlList: [],
    licenseList: [],
    hourlyRate: null
  },
  getters: {
    //env
    getUrlsList: state => {
      return state.urls;
    },
    getZendeskLink: state => {
      return state.zendeskLink;
    },
    //login
    getmsalObj: state => {
      return state.msalObj;
    },
    getMicrosoftAccount: state => {
      return state.microsoftAccount;
    },
    getUserToken: state => {
      return state.userToken;
    },
    getUser: state => {
      return state.user;
    },
    getBuildings: state => {
      return state.buildings;
    },
    getCities: state => {
      return state.cities;
    },
    getProvinces: state => {
      return state.provinces;
    },
    getCountries: state => {
      return state.countries;
    },
    getClassNames: state => {
      return state.classNames;
    },
    getCampaign: state => {
      return state.campaign;
    },
    getCampaignTemplate: state => {
      return state.campaignTemplate;
    },
    getCampaignTemplateVersion: state => {
      return state.campaignTemplateVersion;
    },
    getDates: state => {
      return state.dates;
    },
    getDistributionsList: state => {
      return state.distributionsList;
    },
    getAppAccessToken: state => {
      return state.appAccessToken;
    },
    getEmployeeId: state => {
      return state.employeeId;
    },
    getEmailNoBuildingSent: state => {
      return state.isEmailNoBuildingSent
    },
    getAdminActiveTab: state => {
      return state.adminActiveTab;
    },
    getAllSessions: state => {
      return state.sessions;
    },
    getSessionTableValue: state => {
      return state.sessionTableValue;
    },
    getCollaboratorService: state => {
      return state.collaboratorService;
    },
    getClickEmailMessage: state => {
      return state.clickEmailMessage;
    },
    getCurrentUserToCreate: state => {
      return state.currentUserToCreate;
    },
    getGraphProductName: state => {
      return state.graphProductName;
    },
    getOtherProducts: state => {
      return state.otherProducts;
    },
    getSubscribedSkus: state => {
      return state.subscribedSkus
    },
    getAdditionnalDlList: state => {
      return state.additionnalDlList;
    },
    getLicenseList: state => {
      return state.licenseList;
    },
    getHourlyRate: state => {
      return state.hourlyRate;
    }
  },
  mutations: {
    //Login
    addmsalObj(state, msalObj) {
      state.msalObj = msalObj;
    },
    addMicrosoftAccount(state, microsoftAccount) {
      state.microsoftAccount = microsoftAccount;
    },
    addUserToken(state, token) {
      state.userToken = token;
    },
    addUser(state, user) {
      state.user = user;
    },
    addBuildings(state, buildings) {
      state.buildings = buildings;
    },
    addCities(state, cities) {
      state.cities = cities;
    },
    addProvinces(state, provinces) {
      state.provinces = provinces;
    },
    addCountries(state, countries) {
      state.countries = countries;
    },
    addClassNames(state, classNames) {
      state.classNames = classNames;
    },
    addCampaign(state, campaign) {
      state.campaign = campaign;
    },
    addCampaignTemplate(state, campaignTemplate) {
      state.campaignTemplate = campaignTemplate;
    },
    addCampaignTemplateVersion(state, campaignTemplateVersion) {
      state.campaignTemplateVersion = campaignTemplateVersion;
    },
    addDates(state, dates) {
      state.dates = dates;
    },
    addDistributionsList(state, distributionsList) {
      state.distributionsList = distributionsList;
    },
    addAppAccessToken(state, token) {
      state.appAccessToken = token;
    },
    addEmployeeId(state, employeeId) {
      state.employeeId = employeeId;
    },
    setEmailNoBuildingSent(state, status) {
      state.isEmailNoBuildingSent = status;
    },
    setAdminActiveTab(state, tabName) {
      state.adminActiveTab = tabName;
    },
    addSessions(state, sessions) {
      state.sessions = sessions;
    },
    setSessionTableValue(state, sessionTableValue) {
      state.sessionTableValue = sessionTableValue;
    },
    addCollaboratorService(state, collaboratorService) {
      state.collaboratorService = collaboratorService;
    },
    addClickEmailMessage(state, message) {
      state.clickEmailMessage = message;
    },
    addCurrentUserToCreate(state, user) {
      state.currentUserToCreate = user;
    },
    addOtherProducts(state, otherProducts) {
      state.otherProducts = otherProducts;
    },
    addGraphProductName(state, productNameList) {
      state.graphProductName = productNameList;
    },
    addGraphSubscribedSkus(state, subscribedSkus) {
      state.subscribedSkus = subscribedSkus;
    },
    addAdditionnalDlList(state, selectList) {
      state.additionnalDlList = selectList;
    },
    addLicenseList(state, licenseList) {
      state.licenseList = licenseList;
    },
    addHourlyRate(state, hourlyRate) {
      state.hourlyRate = hourlyRate;
    }
  }
})
