<template>
  <div class="mt-4">
    <b-form @submit="onSubmit" v-if="!spinner">
      <b-row class="mt-2">
        <b-col sm="4" class="mt-4">
          <b-form-checkbox
            v-model="testEmail"
            value="checked"
            unchecked-value="unchecked"
            name="testEmail"
            switch
          >
            <span>{{ $t("action.testEmail") }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6" class="mt-4">
          <b-alert show variant="primary">
            <p>
              <u>{{ form.name }}</u>
            </p>
            <div
              v-for="(dlTemplate, index) in arrDlTemplate"
              :key="'dlTemplate-' + index"
            >
              <div class="mb-2">
                <strong>{{
                  index + 1 + " - " + $t("label.distributionList")
                }}</strong
                >: {{ displayName(dlTemplate, "distList") }}
              </div>
              <ul>
                <li>
                  <strong>{{ $t("label.dlCount") }}</strong
                  >: {{ dlTemplate.count }}
                </li>
                <li>
                  <strong>{{ $t("label.templateName") }}</strong
                  >: {{ displayName(dlTemplate, "template") }}
                </li>
              </ul>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <div v-if="testEmail === 'unchecked'">
        <b-row class="mt-2">
          <b-col sm class="mt-4">
            <div class="mb-2">
              <strong>{{ $t("label.typeOfSending") }}</strong>
            </div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="sendingType"
                :aria-describedby="ariaDescribedby"
                name="sendingType"
                value="immediate"
                >{{ $t("label.rightnow") }}</b-form-radio
              >
              <b-form-radio
                v-model="sendingType"
                :aria-describedby="ariaDescribedby"
                name="sendingType"
                value="schedule"
                >{{ $t("label.schedule") }}</b-form-radio
              >
              <b-form-radio
                v-model="sendingType"
                :aria-describedby="ariaDescribedby"
                name="sendingType"
                value="delayed"
                >{{ $t("label.delayed") }}</b-form-radio
              >
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="sendingType === 'schedule'">
          <b-col sm class="mt-4">
            <div>
              <strong>{{ $t("label.campaignStartDate") }}</strong>
            </div>
            <datepicker
              input-class="datepicker"
              v-model="form.startDate"
              :placeholder="$t('action.chooseDate')"
              :language="language"
              :disabledDates="disabledStartDate"
            ></datepicker>
            <div
              class="text-danger"
              v-if="!$v.form.startDate.required && $v.form.startDate.$dirty"
            >
              {{ $t("info.required") }}
              </div>
            <div>
              <vue-timepicker
                class="mt-2"
                :minute-interval="5"
                v-model="startTime"
                hide-clear-button
              ></vue-timepicker>
            </div>
            <b-button variant="link" @click="reset('start')">{{
              $t("action.reset")
            }}</b-button>
          </b-col>
        </b-row>
        <b-row v-if="sendingType !== 'delayed'" class="mt-2">
          <b-col sm class="mt-4">
            <div>
              <strong>{{ $t("label.campaignRelaunch") }}</strong>
            </div>
            <datepicker
              input-class="datepicker"
              v-model="relaunchDate"
              :placeholder="$t('action.chooseDate')"
              :language="language"
              :disabledDates="disabledRelaunchDate"
            ></datepicker>
            <div>
              <vue-timepicker
                class="mt-2"
                :minute-interval="5"
                v-model="relaunchTime"
                hide-clear-button
              ></vue-timepicker>
            </div>
            <b-button variant="link" @click="reset('relaunch')">{{
              $t("action.reset")
            }}</b-button>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-button
          class="mt-4"
          type="submit"
          variant="success"
          :disabled="disabled"
          >{{ $t("action.submit") }}</b-button
        >
      </div>
    </b-form>

    <div v-if="spinner">
      <b-spinner
        style="width: 3rem; height: 3rem"
        class="spinner"
        variant="success"
        label="Spinning"
        disabled="labelStatus"
      ></b-spinner>
    </div>

    <b-row v-if="error" class="mt-2">
      <b-col sm class="mt-4">
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import { required } from "vuelidate/lib/validators";
import { globalF } from "../Helpers";
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  mixins: [globalF],
  props: [],
  data() {
    return {
      form: {
        id: this.getCampaignData("Id", 0),
        name: this.getCampaignData("Name", ""),
        description: this.getCampaignData("Description", ""),
        status: this.getCampaignData("Status", ""),
        scheduleRelaunchDate: null,
        scheduleRelaunchTime: null,
        scheduleStartDate: null,
        scheduleStartTime: null,
        startDate: null,
      },
      testEmail: "unchecked",
      startTime: "00:00",
      relaunchDate: null,
      relaunchTime: "00:00",
      sendingType: "immediate",
      rawData: [],
      arrDlTemplate: [],
      count: null,
      nextDL: 0,
      distListFromGraph: [],
      disabled: false,
      error: "",
      spinner: false,
    };
  },
  validations() {
    let form = {};

    if (this.sendingType === 'schedule') {
      form.startDate = {required};
    }

    return { form };

  },
  mounted() {
    this.spinner = true;
    let token = this.$store.getters.getAppAccessToken;
    this.getSendObject(token);
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disabledStartDate: function () {
      let myDate = moment().startOf("day").toDate();
      return { to: myDate };
    },
    disabledRelaunchDate: function () {
      let myDate = moment().add(1, "days").startOf("day").toDate();
      return { to: myDate };
    },
    templatesList: function () {
      return this.getTemplatesList();
    },
    hoursValues: function () {
      let times = [];
      for (let i = 0; i < 24; i++) {
        let number = i;
        let str = number.toString();
        if (i < 10) {
          str = "0" + str;
        }
        times.push({ text: str, value: str });
      }
      return times;
    },
    timesValues: function () {
      let times = [];
      for (let i = 0; i < 12; i++) {
        let number = i * 5;
        let str = number.toString();
        if (i < 2) {
          str = "0" + str;
        }
        times.push({ text: str, value: str });
      }
      return times;
    },
  },
  methods: {
    reset: function (type) {
      if (type === "start") {
        this.form.startDate = "";
        this.startTime = "00:00";
        this.form.scheduleStartDate = null;
        this.form.scheduleStartTime = null;
      }
      if (type === "relaunch") {
        this.relaunchDate = "";
        this.relaunchTime = "00:00";
        this.form.scheduleRelaunchDate = null;
        this.form.scheduleRelaunchTime = null;
      }
    },
    displayName(templateData, type) {
      let txt = "";
      if (type === "distList") {
        if (this.distListFromGraph) {
          txt = this.distListFromGraph.displayName;
        }
      }
      if (type === "template") {
        let dl = this.templatesList.find(
          (dl) => dl.value === templateData.IdTemplate
        );
        if (dl) {
          txt = dl.text;
        }
      }
      return txt;
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    setNextStep(step) {
      this.$emit("changeStep", step);
    },
    validateDates: function () {
      let status = false;

      if (this.form.startDate) {
        let sdstr = moment(this.form.startDate).format("YYYY-MM-DD");
        let sd = moment(sdstr + " " + this.startTime);
        if (moment(sd).isBefore(moment())) {
          status = true;
          this.error = this.$t("info.startPast");
        }
      }

      if (this.form.startDate && this.relaunchDate) {
        let sdstr = moment(this.form.startDate).format("YYYY-MM-DD");
        let rdstr = moment(this.relaunchDate).format("YYYY-MM-DD");
        let sd = moment(sdstr + " " + this.startTime);
        let rd = moment(rdstr + " " + this.relaunchTime);

        if (sd.isValid() && rd.isValid()) {
          if (moment(rd).isSameOrBefore(sd)) {
            status = true;
            this.error = this.$t("info.dateBefore");
          }
        }
      }
      return status;
    },
    getSendObject: function (token) {
      let distributionList = this.getDlData();
      let templateList = this.getTemplateData();

      let arrDlTemplate = [];

      for (let i = 0; i < distributionList.length; i++) {
        let objectId = distributionList[i];
        let template = templateList.find(
          (t) => t.IdDistributionList === objectId
        );
        if (template) {
          arrDlTemplate.push(template);
        }
      }
      this.callDistributionList(token, arrDlTemplate);
    },
    callDistributionList: function (token, arrDlTemplate) {
      let length = arrDlTemplate.length;

      if (this.nextDL < length) {
        let objectId = arrDlTemplate[this.nextDL].IdDistributionList;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${objectId}/members/$count`;
        this.getDistributionListCount(token, graphEndpoint, arrDlTemplate);
      } else {
        let dlData = this.getDlData();
        this.getDlById(token, dlData[0]);
        this.arrDlTemplate = arrDlTemplate;
      }
    },
    getDistributionListCount: function (token, graphEndpoint, arrDlTemplate) {
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            arrDlTemplate[this.nextDL].count = response.data;
            this.nextDL += 1;
            this.callDistributionList(token, arrDlTemplate);
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getCountDL() {
      let count = 0;
      for (let i = 0; i < this.arrDlTemplate.length; i++) {
        if (this.arrDlTemplate[i].count) {
          count += this.arrDlTemplate[i].count;
        }
      }

      let status = true;
      if (count > 5000 && count < 0) {
        //limite réelle 10 000
        this.error = this.$t("info.errorgeneric");
        status = false;
      }

      return status;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.error = "";
      this.$v.$touch();
      if (!this.$v.$invalid && !this.validateDates() && this.getCountDL()) {
        this.disabled = true;
        this.spinner = true;

        this.form.request = "activateCampaign";
        this.form.campaignType = "start";
        this.form.userId = this.user.IdMyProfile;
        this.form.graphUserId = this.user.id;
        this.form.testEmail = this.testEmail;
        this.form.arrDlTemplate = this.arrDlTemplate;
        this.form.sendingType = this.sendingType;
        this.form.status = 1;

        if (this.form.startDate) {
          let startDateString = moment(this.form.startDate).format("YYYY-MM-DD");
          
          this.form.scheduleStartDate = moment(startDateString + " " + this.startTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

          this.form.scheduleStartTime = moment(
            this.form.scheduleStartDate + " " + this.startTime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm");
        }

        if (this.relaunchDate) {

          let relaunchDateStr = moment(this.relaunchDate).format("YYYY-MM-DD");

          this.form.scheduleRelaunchDate = moment(relaunchDateStr + " " + this.relaunchTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
          
          this.form.scheduleRelaunchTime = moment(
            this.form.scheduleRelaunchDate + " " + this.relaunchTime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm");
        }

        axios
          .post(this.url, this.form, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            this.disabled = false;
            this.spinner = false;
            if (response.data === "dateCampaignAlreadyExist") {
              this.error = this.$t("info.dateCampaignAlreadyExist");
            }
            else {
              let promise = this.getAppData(this.user);
              promise.then(() => {
                if (this.testEmail !== "checked") {
                  this.$store.commit("setAdminActiveTab", "Campaign");
                  this.$emit("changePage", "Admin");
                }
              });
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      }
    },
    useTestObject: function () {
      let mockDl = this.arrDlTemplate.map((dl) => {
        dl.distributionList = [[this.user]];
        return dl;
      });

      return mockDl;
    },
  },
  components: {
    Datepicker,
    VueTimepicker,
  },
};
</script>