<template>
  <b-container>
  <b-form @submit="onSubmit">

  <b-row>
      <b-col class="mt-4">
        <div>{{ $t('action.missingBuilding') }}</div>
        <b-form-input v-model.trim="$v.form.building.$model"></b-form-input>
        <div class="text-danger" v-if="!$v.form.building.required && $v.form.building.$dirty">{{ $t("info.required") }}</div>
        <div class="text-danger" v-if="!$v.form.building.maxLength && $v.form.building.$dirty">{{ $t("info.maxLength", [75]) }}</div>
      </b-col>
    </b-row>
  
  <b-button class="mt-4 text-center" type="submit" variant="success" :disabled="disabled">{{ $t("action.submit") }}</b-button>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
          </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
          <b-alert show variant="danger">
            <p>{{ error }} </p>
          </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner" class="text-center align-middle">
      <b-spinner style="width: 3rem; height: 3rem;" variant="success" label="Spinning" disabled="labelStatus"></b-spinner>
    </div>

  </b-form>
</b-container>
</template>

<script>

import axios from 'axios';
import { required, maxLength } from 'vuelidate/lib/validators';
import { globalF } from '../Helpers';
export default {
  mixins: [globalF],
    data() {
        return { 
            form: {
                building: ""
            },
            spinner: false,
            success: false,
            disabled: false,
            error: "",
        }
    },
    validations() {
        return {
            form: {
                building: {
                    required,
                    maxLength: maxLength(75)
                }
            }
        }
    },
    computed: {
        url: function () {
            return this.getUrl();
        },
        user: function () {
            return this.$store.getters.getUser;
        }
    },
    methods:{
        onSubmit(evt) {
            evt.preventDefault();
            this.$v.$touch();
            if (!this.$v.$invalid) { 
                this.spinner = true;
                this.form.request = "sendNotificationEmail";
                this.form.userName = this.user.displayName;
                this.form.userEmail = this.user.mail;

                axios.post(this.url, this.form, { headers: {'Content-Type': 'application/json'}})
                .then((response)  => {
                this.disabled = false;
                this.spinner = false;

                if (response.data === 202) {
                    this.$store.commit('setEmailNoBuildingSent', true);
                    let obj = {"Email" : this.user.mail, "LogOut": null, "Request": JSON.stringify(this.form), "Submit": null}
                    this.updateSession(obj);
                    this.success = true;
                }
                else {
                    this.error = this.$t('info.errorgeneric');
                }
                })
                .catch(()  => {
                this.error = this.$t('info.errorgeneric');
                this.disabled = false;
                this.spinner = false;
                });
            }
        }
    }
}
</script>
