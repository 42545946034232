<template>
  <b-container>
    <h5 class="mt-4">
      {{ $t("label.terminatedUserForm") }}
      <b-button variant="link" @click="openmodal('ticketInfo')">{{
        $t("action.viewTicketInfo")
      }}</b-button>
      <b-button variant="link" @click="goTo('Admin', 'TicketTable')">{{
        $t("label.backToCurrentOpenTicket")
      }}</b-button>
    </h5>

    <b-form @submit="onSubmit">
      <b-row>
        <b-col sm="6" class="mt-4">
          <v-select
            v-model="existingUser"
            :options="users"
            @search="searchUser"
            :placeholder="$t('action.chooseUser')"
            label="displayName"
            disabled
          >
          </v-select>
        </b-col>
      </b-row>
      <hr />
      <div v-if="existingUser">
        <b-row class="mt-2">
          <b-col sm>
            <div class="mb-2">
              <strong>{{ $t("label.typeOfTermination") }}</strong>
            </div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-model="form.sendingType"
                :aria-describedby="ariaDescribedby"
                name="sendingType"
                value="immediate"
                >{{ $t("label.rightnowTermination") }}
                <span
                  v-b-tooltip.hover
                  :title="$t('tooltip.rightnowTermination')"
                  >&#9432;</span
                ></b-form-radio
              >
              <b-form-radio
                v-model="form.sendingType"
                :aria-describedby="ariaDescribedby"
                name="sendingType"
                value="schedule"
                >{{ $t("label.scheduleTermination") }}
                <span
                  v-b-tooltip.hover
                  :title="$t('tooltip.scheduleTermination')"
                  >&#9432;</span
                ></b-form-radio
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="form.sendingType === 'schedule'">
          <b-col sm>
            <div>
              <strong>{{ $t("label.departureDate") }}</strong>
            </div>
            <datepicker
              input-class="datepicker"
              v-model="form.departureDate"
              :placeholder="$t('action.chooseDate')"
              :language="language"
              :disabledDates="disableddepartureDate"
            ></datepicker>
            <div>
              <div
                class="text-danger"
                v-if="
                  !$v.form.departureDate.required &&
                  $v.form.departureDate.$dirty
                "
              >
                {{ $t("info.required") }}
              </div>
              <vue-timepicker
                class="mt-2"
                :minute-interval="5"
                v-model="form.departureTime"
                hide-clear-button
              ></vue-timepicker>
            </div>
            <b-button variant="link" @click="reset()">{{
              $t("action.reset")
            }}</b-button>

            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" class="mt-4">
            <div>
              <strong>{{ $t("label.offices") }}</strong>
            </div>
            <v-select
              v-model="form.office"
              :options="buildings"
              :selectable="(option) => !option.Flag"
              :filter="searchBuilding"
              label="ResName"
            >
              <template slot="option" slot-scope="option">
                <strong
                  style="color: black"
                  v-if="option.Flag === 'ClassName'"
                  disabled
                  >{{ option.ResName }}</strong
                >
                <span v-else>{{ option.ResName }}</span>
              </template>
            </v-select>
            <div>
              <small>&#9432; {{ $t("info.searchBuilding") }}</small>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" class="mt-2">
            <b-form-checkbox
              id="keepHistory"
              v-model="form.keepHistory"
              name="keepHistory"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.keepHistory") }}
              <strong>{{
                form.keepHistoryEmail ? form.keepHistoryEmail.mail : ""
              }}</strong>
              <span v-b-tooltip.hover :title="$t('tooltip.keepHistory')"
                >&#9432;</span
              >
            </b-form-checkbox>

            <!-- keepHistoryEmail -->
            <div v-if="form.keepHistory === 'yes'">
              <v-select
                v-model.trim="$v.form.keepHistoryEmail.$model"
                :options="users"
                @search="searchUser"
                :placeholder="$t('action.chooseUser')"
                label="displayName"
              >
              </v-select>
              <div
                class="text-danger"
                v-if="
                  !$v.form.keepHistoryEmail.required &&
                  $v.form.keepHistoryEmail.$dirty
                "
              >
                {{ $t("info.required") }}
              </div>
            </div>
            <b-form-checkbox
              id="forwardEmail"
              v-model="form.forwardEmail"
              name="forwardEmail"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.forwardEmail") }}
              <strong>{{
                form.sendAccessTo ? form.sendAccessTo.mail : ""
              }}</strong>
              <span v-b-tooltip.hover :title="$t('tooltip.forwardEmail')"
                >&#9432;</span
              >
            </b-form-checkbox>

            <!-- Send Access To -->
            <div class="ml-4 mt-2" v-if="form.forwardEmail === 'yes'">
              <hr />

              <v-select
                v-model.trim="$v.form.sendAccessTo.$model"
                :options="users"
                @search="searchUser"
                :placeholder="$t('action.chooseUser')"
                label="displayName"
                class="mt-2"
              >
              </v-select>

              <b-form-checkbox
                id="keepInMailbox"
                v-model="form.keepInMailbox"
                name="keepInMailbox"
                value="yes"
                unchecked-value="no"
                class="mt-2"
                >{{ $t("label.keepInMailbox") }}
              </b-form-checkbox>

              <div
                class="text-danger"
                v-if="
                  !$v.form.sendAccessTo.required && $v.form.sendAccessTo.$dirty
                "
              >
                {{ $t("info.required") }}
              </div>
              <hr />
            </div>
            <b-form-checkbox
              id="removedManager"
              v-model="form.removedManager"
              name="removedManager"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.removedManager") }}
            </b-form-checkbox>
            <b-form-checkbox
              id="removedDl"
              v-model="form.removedDl"
              name="removedDl"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.removedDl") }}
            </b-form-checkbox>
            <b-form-checkbox
              v-if="form.forwardEmail === 'no'"
              id="accessRestriction"
              v-model="form.accessRestriction"
              name="accessRestriction"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.accessRestriction") }}
            </b-form-checkbox>
            <b-form-checkbox
              id="autoreply"
              v-model="form.autoreply"
              name="autoreply"
              value="yes"
              unchecked-value="no"
              >{{ $t("label.autoreply") }}
            </b-form-checkbox>
            <div v-if="form.autoreply === 'yes'">
              <!-- Editor for message -->
              <vue-editor
                class="mt-4"
                v-model.trim="form.autoreplyMessage"
                :editorToolbar="customToolbar"
              ></vue-editor>
              <div
                class="text-danger"
                v-if="
                  !$v.form.autoreplyMessage.required &&
                  $v.form.autoreplyMessage.$dirty &&
                  !$v.form.autoreplyMessage.requiredAfterHtmlStripped
                "
              >
                {{ $t("info.required") }}
              </div>
              <div
                class="text-danger"
                v-if="!$v.form.autoreplyMessage.maxLength && $v.message.$dirty"
              >
                {{ $t("info.maxLength", [5000]) }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-button
        v-if="existingUser"
        class="mt-4"
        type="submit"
        variant="success"
        :disabled="disabled"
        >{{ $t("action.submit") }}</b-button
      >

      <b-modal id="ticketInfo" :title="$t('label.ticketInfo')" hide-footer>
        <ul>
          <li v-for="(value, key) in currentUserInfo" :key="key">
            {{ key + " : " + value }}
          </li>
        </ul>
      </b-modal>

      <div v-if="spinner" class="text-center align-middle">
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="success"
          label="Spinning"
          disabled="labelStatus"
        ></b-spinner>
      </div>

      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="error" class="mt-2">
        <b-col sm class="mt-4">
          <b-alert show variant="danger">
            <p>{{ error }}</p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row v-if="info" class="mt-2">
        <b-col sm class="mt-4">
          <b-alert show variant="info">
            <p>{{ info }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { VueEditor } from "vue2-editor";
import { required, maxLength } from "vuelidate/lib/validators";
import "vue-search-select/dist/VueSearchSelect.css";
import { globalF } from "../Helpers";

const requiredAfterHtmlStripped = (message) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = message;
  let content = tmp.textContent || tmp.innerText || "";
  return content.length > 0 ? true : false;
};

export default {
  mixins: [globalF],
  data() {
    return {
      form: {
        accessFormLogObj: {
          blockUser: null,
          convertMailBox: null,
          clearManagerField: null,
        },
        sendAccessTo: this.getData("SendAccessTo", ""),
        keepInMailbox: this.getData("KeepInMailbox", "no"),
        keepHistory: this.getData("KeepHistory", "no"),
        keepHistoryEmail: this.getData("KeepHistoryEmail", ""),
        forwardEmail: this.getData("ForwardEmail", "no"),
        removedManager: this.getData("RemovedManager", "yes"),
        removedDl: this.getData("RemovedDl", "yes"),
        autoreply: this.getData("Autoreply", "no"),
        accessRestriction: this.getData("AccessRestriction", "no"),
        office: this.getOfficeDataById(),
        licensesToRemove: [],
        groupsToUpdate: [],
        requestType: "terminationOfEmployment",
        autoreplyMessage: this.getData("AutoreplyMessage", ""),
        departureDate: this.setDateData("DepartureDate", ""),
        departureTime: this.getData("DepartureTime", "00:00"),
        sendingType: this.getData("SendingType", "immediate"),
      },
      buildings: [],
      existingUser: this.getExistingUser("User", null),
      spinner: false,
      success: false,
      disabled: false,
      allDomains: [],
      users: [],
      error: "",
      info: "",
      characters: "a-z,A-Z,0-9,#",
      passwordSize: 8,
    };
  },
  validations() {
    let form = {
      sendAccessTo: {},
      keepHistoryEmail: {},
      //office: { required },
      autoreplyMessage: {},
    };

    if (this.form.forwardEmail === "yes") {
      form.sendAccessTo = { required };
    }

    if (this.form.keepHistory === "yes") {
      form.keepHistoryEmail = { required };
    }

    if (this.form.autoreply === "yes") {
      form.autoreplyMessage = {
        required,
        requiredAfterHtmlStripped,
        maxLength: maxLength(5000),
      };
    }

    if (this.form.sendingType === "schedule") {
      form.departureDate = { required };
    }

    return { form };
  },
  mounted() {
    this.getBuildingList();
    this.actionCall("userMemberOf"); //Faire choisir dans un tableau
    this.actionCall("userLicenses");
    let promise = this.validateToken();
    promise.then((value) => {
      if (value) {
        this.getAllDomains(value);
      }
    });
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    currentLg: function () {
      return this.$i18n.locale;
    },
    currentUserInfo: function () {
      let info = [];
      let currentUser = this.$store.getters.getCurrentUserToCreate;
      if (currentUser) {
        delete currentUser.User;
        info = currentUser;
      }
      return info;
    },
    customToolbar: function () {
      //:editorToolbar="customToolbar"
      return [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }],
        ["link"],
      ];
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disableddepartureDate: function () {
      let myDate = moment().startOf("day").toDate();
      return { to: myDate };
    },
  },
  watch: {
    existingUser: function (user) {
      if (user) {
        this.form.office = this.getOfficeDataByName(user.officeLocation);
      }
    },
  },
  methods: {
    searchBuilding(options, search) {
      let sl = search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      let find = options.filter((o) => {
        let ResNamePar = o.ResName ? o.ResName.replace(/[()]/g, "") : ""; //Remove ()
        let ResName = ResNamePar.normalize("NFD").replace(
          /[\u0300-\u036f]/g,
          ""
        ); //Remove accent

        let City = o.City ? o.City : "";
        let ClassNamesFr = o.ClassNamesFr ? o.ClassNamesFr : "";
        let ClassNamesEn = o.ClassNamesEn ? o.ClassNamesEn : "";
        let Country = o.Country ? o.Country : "";
        let State = o.State ? o.State : "";
        let Street = o.Street ? o.Street : "";
        let Zip = o.Zip ? o.Zip : "";

        return (
          City.toLowerCase().includes(s) ||
          ClassNamesFr.toLowerCase().includes(s) ||
          ClassNamesEn.toLowerCase().includes(s) ||
          ResName.toLowerCase().includes(s) ||
          Country.toLowerCase().includes(s) ||
          State.toLowerCase().includes(s) ||
          Street.toLowerCase().includes(s) ||
          Zip.toLowerCase().includes(s)
        );
      });

      return find;
    },
    getMailFromObject: function (val) {
      return val.mail ? val.mail : val;
    },
    getOfficeDataByName: function (resName) {
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.ResName === resName);
      return building ? building : null;
    },
    getOfficeDataById: function () {
      let IdBuilding = this.getData("IdBuilding");
      let buildings = this.$store.getters.getBuildings;
      let building = buildings.find((b) => b.Id === IdBuilding);
      return building ? building : null;
    },
    getBuildingList: function () {
      let buildings = this.$store.getters.getBuildings;
      this.mapBuilding(buildings);
    },
    mapBuilding: function (buildings) {
      let lg = this.$i18n.locale;
      let uniqueClass = [];

      for (let i = 0; i < buildings.length; i++) {
        let find = uniqueClass.find((c) => c === buildings[i].Class);
        if (!find) {
          uniqueClass.push(buildings[i].Class);
        }
      }

      uniqueClass.sort((a, b) => {
        return a - b;
      });
      let buildingsListByClassWithLabel = [];

      for (let i = 0; i < uniqueClass.length; i++) {
        let blist = buildings.filter((b) => b.Class === uniqueClass[i]);
        let className =
          lg === "fr" ? blist[0].ClassNamesFr : blist[0].ClassNamesEn;
        blist.unshift({ ResName: className, Flag: "ClassName" });
        buildingsListByClassWithLabel.push(blist);
      }

      this.buildings = buildingsListByClassWithLabel.flat();
    },
    getExistingUser: function (type, propertyValue) {
      let user = this.$store.getters.getCurrentUserToCreate;
      if (user) {
        propertyValue = JSON.parse(user[type]);
      }
      return propertyValue;
    },
    setDateData: function (type, propertyValue) {
      let date = this.getData(type, propertyValue);
      if (date) {
        date = moment(date).toDate();
      }
      return date;
    },
    getData: function (type, propertyValue) {
      let user = this.$store.getters.getCurrentUserToCreate;
      if (user) {
        propertyValue = user[type];
      }
      return propertyValue;
    },
    searchUser: function (search) {
      this.actionCall("allUsers", search);
    },
    actionCall: function (type, searchText) {
      this.info = "";
      this.error = "";
      this.success = false;
      let promise = this.validateToken();
      promise.then((value) => {
        if (value) {
          if (type === "allUsers") {
            this.searchUsersBySearchText(value, searchText);
          }
          if (type === "userMemberOf") {
            this.getUserMemberOf(value);
          }
          if (type === "userLicenses") {
            this.getUserLicenses(value);
          }
        }
      });
    },
    getAllUsers(token, searchText) {
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}')&$expand=manager($select=id,displayName,mail)&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) =>
                  a.displayName.indexOf("*") === -1 &&
                  this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    getUserLicenses(token) {
      this.spinner = true;
      let idUser = this.existingUser.id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/licenseDetails`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          let userLicenses = response.data.value;
          this.form.licensesToRemove = userLicenses.map((l) => {
            return l.skuId;
          });
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getUserMemberOf(token) {
      this.spinner = true;
      let idUser = this.existingUser.id;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${idUser}/memberOf`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.spinner = false;
          let groupList = response.data.value;
          this.form.groupsToUpdate = groupList.filter((g) => {
            if (g.groupTypes) {
              if (g.groupTypes.includes("DynamicMembership")) {
                return false;
              }
            }
            if (g.securityEnabled) {
              return false;
            }
            return true;
          });
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    openmodal: function (id) {
      this.$bvModal.show(id);
    },
    goTo: function (val, table) {
      this.$store.commit("setAdminActiveTab", table);
      this.$emit("changePage", val);
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    validateDate() {
      let pass = true;
      if (this.form.sendingType === "schedule") {
        let departureDate = moment(this.form.departureDate).format("YYYY-MM-DD");
        let departureTime = this.form.departureTime;
        let departureDateTime = moment(
          `${departureDate} ${departureTime}`,
          "YYYY-MM-DD HH:mm"
        );
        let today = moment();
        if (departureDateTime < today) {
          this.error = this.$t("info.dateAfterToday");
          pass = false;
        }
      }
      return pass;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.success = false;
      this.error = "";
      this.info = "";
      this.$v.$touch();
      if (!this.$v.$invalid && this.validateDate()) {
        this.disabled = true;
        this.spinner = true;

        let formInfo = this.$store.getters.getCurrentUserToCreate;

        if (this.form.forwardEmail === "yes") {
          this.form.accessRestriction = "no";
        }

        this.form.requesterId = this.user.id;
        this.form.requesterName = this.user.displayName;
        this.form.requesterEmail = this.user.mail;
        this.form.user = this.existingUser;
        this.form.idBuilding = this.form.office ? this.form.office.Id : 0;
        this.form.building = this.form.office;
        this.form.idTermination = formInfo.Id;
        this.form.idAccessForm = formInfo.IdAccessForm;
        this.form.idTicket = formInfo.IdTicket;
        this.form.comments = formInfo.Comments;

        this.form.sendAccessTo = this.getMailFromObject(this.form.sendAccessTo);
        this.form.keepHistoryEmail = this.getMailFromObject(
          this.form.keepHistoryEmail
        );

        /** */

        let onlyGroupList = this.form.groupsToUpdate.filter(
          (s) => s.groupTypes
        );

        let distList = onlyGroupList.filter((s) => s.groupTypes.length === 0);
        let microsoftList = onlyGroupList.filter((s) =>
          s.groupTypes.includes("Unified")
        );
        this.form.distributionList = distList.map((s) => {
          return s.id;
        });
        this.form.microsoft365Group = microsoftList.map((s) => {
          return s.id;
        });

        if (this.form.sendingType === "schedule") {
          this.form.departureDate = moment(this.form.departureDate).format(
            "YYYY-MM-DD"
          );
          this.validateJobStatus();
        }

        else {
          this.form.departureDate = "";
          this.form.departureTime = "";
          this.validateJobStatus();
        }
      }
    },
    //Validation before sending
    validateJobStatus() {
      let obj = {
        request: "validateJobStatus",
        idRef: this.form.idTermination,
        jobName: "RemoveLicenses",
      };
      axios
        .post(this.url, obj, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log("validateJobStatus");
          if (response.data) {
            this.spinner = false;
            this.disabled = false;
            this.error = this.$t("info.jobExistForThisId");
          } else {
            this.logRequest();
          }
        })
        .catch(() => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
        });
    },
    logRequest() {
      this.form.request = "logRequestTerminationOfEmployment";
      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.spinner = false;
          this.$emit("changePage", "SuccessConfirmation");
        })
        .catch((error) => {
          this.spinner = false;
          this.disabled = false;
          this.error = this.$t("info.errorgeneric");
          if (error.response) {
            this.error = error.response.data;
          }
          this.form.accessFormLogObj.logRequest = error;
          this.logTerminatedUserError();
        });
    },
    logTerminatedUserError: function () {
      this.disabled = true;
      this.spinner = true;

      this.form.request = "accessFormLog";

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.disabled = false;
          this.spinner = false;

        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    }
  },
  components: {
    VueEditor,
    Datepicker,
    VueTimepicker,
  },
};
</script>