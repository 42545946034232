<template>
  <b-container>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col sm class="mt-4">
          <v-select
            v-model="userToAdd"
            :options="users"
            @search="searchUser"
            :placeholder="$t('action.chooseUser')"
            label="displayName"
          >
          </v-select>
          <div
            class="text-danger"
            v-if="!$v.userToAdd.required && $v.userToAdd.$dirty"
          >
            {{ $t("info.required") }}
          </div>

          <b-alert
            v-if="validateManager(userToAdd)"
            show
            variant="info"
            class="mt-4"
          >
            <p>
              {{ $t("info.managerExist", [userToAdd.manager.displayName]) }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-button
        class="mt-4 text-center"
        type="submit"
        variant="success"
        :disabled="disabled"
        >{{ $t("action.submit") }}</b-button
      >
      <b-row v-if="success" class="text-center pt-4">
        <b-col>
          <b-alert show variant="success">
            <p>{{ $t("info.successgeneric") }}</p>
            <p>{{ $t("info.delayedUpdate") }}</p>
          </b-alert>
        </b-col>
      </b-row>
      <div v-if="error" class="pt-4">
        <b-alert show variant="info">
          <p>{{ error }}</p>
        </b-alert>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [globalF],
  props: ["modalid", "manager"],
  data() {
    return {
      form: {},
      allDomains: [],
      userToAdd: null,
      users: [],
      spinner: false,
      disabled: false,
      success: false,
      error: "",
    };
  },
  validations() {
    return {
      userToAdd: { required },
    };
  },
  mounted() {
    let promise = this.validateToken();
    promise.then((value) => {
      if (value) {
        this.getAllDomains(value);
      }
    });
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
  },
  methods: {
    searchUser: function (searchText) {
      let token = this.$store.getters.getAppAccessToken;
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}')&$expand=manager($select=id,displayName,mail)&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) =>
                  a.displayName.indexOf("*") === -1 &&
                  this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    validateManager(userToAdd) {
      let manager = null;
      if (userToAdd) {
        manager = userToAdd.manager;
      }
      return manager;
    },
    updateManager(token, user, manager) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${user.id}/manager/$ref`;
      axios
        .put(
          graphEndpoint,
          {
            "@odata.id": `https://graph.microsoft.com/v1.0/users/${manager.id}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.success = true;
          let obj = {
            Email: this.manager.mail,
            LogOut: null,
            Request: null,
            Submit: JSON.stringify(response),
          };
          this.updateSession(obj);
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.spinner = true;
        let token = this.$store.getters.getAppAccessToken;
        let manager = this.manager;
        let userToAdd = this.userToAdd;

        this.updateManager(token, userToAdd, manager);
      }
    },
  },
};
</script>
