<template>
  <b-container>
    <b-row class="mt-4">
      <b-col sm="4">
          <b-form-select
            v-model="userTable"
            :options="tableList"
          ></b-form-select>
      </b-col>
    </b-row>

    <CreateTable v-if="userTable === 'create'" />
    <TerminationTable v-if="userTable === 'termination'" />
  </b-container>
</template>

<script>
import { globalF } from "../Helpers";
import CreateTable from "./CreateTable";
import TerminationTable from "./TerminationTable";

export default {
  mixins: [globalF],
  data() {
    return {
      userTable: "create",
    };
  },
  validations() {},
  mounted() {},
  computed: {
    tableList() {
      return [
        { value: "create", text: "Creation" },
        { value: "termination", text: "Termination" },
      ];
    },
  },
  methods: {
    setNextPage(page) {
      this.$emit("changePage", page);
    },
  },
  components: {
    CreateTable,
    TerminationTable,
  },
};
</script>
