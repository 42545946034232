<template>
  <div id="app">
    <Header v-on:changePage="setNextPage" v-on:changeLg="setLg" />
    <div class="content">
      <div v-if="error">
        {{ error }}
      </div>
      <div v-else>
        <Dispatcher
          :redirect="redirect"
          :current="mycurrent"
          v-on:changePage="setNextPage"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Dispatcher from "./components/Dispatcher.vue";

export default {
  name: "app",
  data: () => ({
    mycurrent: "Login",
    redirect: null,
    error: "",
  }),
  mounted() {
    //google map loading
    window.gmapsCallback = () => this.initMap() // NEW!
    this.gmapsInit() // NEW!

    this.rejectInternetExplorer();
    this.setLanguage();
    this.getQueryString();
  },
  methods: {
    gmapsInit: function() {
      const apiKey = 'AIzaSyAZ29Tes8SRNfiQvg7RZgPE33WqsGdP2ME';
      const callbackName = 'gmapsCallback';

      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${callbackName}&libraries=places`;
      document.querySelector('head').appendChild(script);
    },
    initMap: function() {
      console.log("Google map is ready to use");
    },
    rejectInternetExplorer() {
      if (/*@cc_on!@*/ false || !!document.documentMode) {
        this.error =
          this.$i18n.locale === "fr"
            ? "INTERNET EXPLORER N'EST PAS SUPPORTÉ. VEUILLEZ UTILISER CHROME, FIREFOX OU MICROSOFT EDGE"
            : "INTERNET EXPLORER IS NOT SUPPORTED. PLEASE USE CHROME, FIREFOX OR MICROSOFT EDGE";
      }
    },
    setLanguage() {
      let navlg = window.navigator.language;
      if (navlg) {
        if (navlg.toLowerCase().includes("fr")) {
          this.setLg("fr");
        }
      }
    },
    setNextPage(page) {
      this.mycurrent = page;
    },
    setLg(val) {
      this.$i18n.locale = val;
    },
    getQueryString: function () {
      let query = this.$route.query;
      if (query.token && query.token1 && query.email && query.action) {

        let key = "queryStringRequest";
        let strValue = JSON.stringify(query);
        sessionStorage.setItem(key, strValue);

        this.$router.replace({ token: null, token1: null , email: null, action: null });

      }

      if (query.terminationOfEmploymentId && query.ticketId) {

        let key = "queryStringRequest";
        let strValue = JSON.stringify(query);
        sessionStorage.setItem(key, strValue);

        this.$router.replace({ terminationOfEmploymentId: null, ticketId: null });

      }
      
      if (query.accessFormId && query.ticketId) {

        let key = "queryStringRequest";
        let strValue = JSON.stringify(query);
        sessionStorage.setItem(key, strValue);

        this.$router.replace({ accessFormId: null, ticketId: null });

      }

      if (query.redirect) {
        let key = "queryStringRequest";
        let strValue = JSON.stringify(query);
        sessionStorage.setItem(key, strValue);

        this.$router.replace({ redirect: null });
      }

    },
  },
  components: {
    Dispatcher,
    Header,
    Footer,
  },
};
</script>
