<template>
  <div>
    <b-form @submit="onSubmit">
      <b-row class="mt-2">
        <b-col sm="6" class="mt-4">
          <div v-if="currentListItem"><span class="font-weight-bold">{{ $t("label.currentDl") }} : </span>{{currentListItem.displayName}}</div>
          <div class="mt-4">
            <strong>{{ $t("label.distributionList") }}</strong>
          </div>
          <v-select
              v-model.trim="$v.listItem.$model"
              :options="distListFromGraph"
              @search="searchDl"
              :placeholder="$t('action.chooseDl')"
              label="displayName"
            >
            </v-select>
          <div
            class="text-danger"
            v-if="!$v.listItem.required && $v.listItem.$dirty"
          >
            {{ $t("info.required") }}
          </div>
        </b-col>
      </b-row>
      
        <b-button
          v-if="listItem"
          class="mt-4"
          variant="link"
          @click="OnListClick"
          >{{ $t("label.displayDistributionList") }}</b-button
        >
      <hr>
      
        <b-button
          class="mt-4"
          type="submit"
          variant="success"
          :disabled="disabled"
          >{{ $t("action.submit") }}</b-button
        >
      

      <div v-if="spinner">
        <b-spinner
          style="width: 3rem; height: 3rem"
          class="spinner"
          variant="success"
          label="Spinning"
          disabled="labelStatus"
        ></b-spinner>
      </div>

      <b-row v-if="error" class="mt-2">
        <b-col sm class="mt-4">
          <b-alert show variant="danger">
            <p>{{ error }}</p>
          </b-alert>
        </b-col>
      </b-row>

      <hr />

      <div class="mt-2" v-if="tableData.length > 0">
        <b-row class="pt-2">
          <b-col sm="6" class="mt-2">
            <b-form-select v-model="perPage" :options="options"></b-form-select>
          </b-col>
          <b-col sm="6" class="mt-2">
            <div>{{ $t("label.tablesize") }} {{ rows }}</div>
          </b-col>
        </b-row>

        <b-row class="pt-2">
          <b-col sm="6" class="mt-2">
            <b-form-input
              v-model="search"
              :placeholder="$t('action.search')"
            ></b-form-input>
            <div>
              <small>&#9432; {{ $t("info.searchDirectory") }}</small>
            </div>
            <b-button variant="link" @click="resetTable()">{{
              $t("action.reset")
            }}</b-button>
          </b-col>
        </b-row>

        <b-row class="pt-4">
          <b-col class="lgTable">
            <b-table
              id="DistributionListTable"
              stacked="lg"
              bordered
              :fields="myfields"
              :items="items"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :per-page="perPage"
              :current-page="currentPage"
            >
            </b-table>
          </b-col>
        </b-row>

        <b-row class="pt-4">
          <b-col sm="6" class="mt-2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="DistributionListTable"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";
import "vue-search-select/dist/VueSearchSelect.css";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [globalF],
  props: [],
  data() {
    return {
      distListFromGraph: [],
      currentListItem: null,
      listItem: null,
      //tbl
      rawData: [],
      tableData: [],
      count: null,
      sortBy: "displayName",
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      error: "",
      disabled: false
    };
  },
  validations() {
    return {
      listItem: { required },
    };
  },
  mounted() {
    let listItem = this.getDlData();
    this.getCurrentListItem(listItem);
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    token: function () {
      return this.$store.getters.getAppAccessToken;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let dp = o.displayName ? o.displayName : "";
        let displayName = dp.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let gn = o.givenName ? o.givenName : "";
        let givenName = gn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let sn = o.surname ? o.surname : "";
        let surname = sn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let mail = o.mail ? o.mail : "";

        let jt = o.jobTitle ? o.jobTitle : "";
        let jobTitle = jt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let off = o.officeLocation ? o.officeLocation.replace(/[()]/g, "") : ""; //Remove ()
        let officeLocation = off
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return (
          displayName.toLowerCase().includes(s) ||
          givenName.toLowerCase().includes(s) ||
          surname.toLowerCase().includes(s) ||
          mail.toLowerCase().includes(s) ||
          jobTitle.toLowerCase().includes(s) ||
          officeLocation.toLowerCase().includes(s)
        );
      });

      return find;
    },
    myfields: function () {
      return [
        {
          key: "displayName",
          sortable: true,
          label: this.$t("label.displayName"),
        },
        { key: "givenName", sortable: true, label: this.$t("label.fname") },
        { key: "surname", sortable: true, label: this.$t("label.lname") },
        { key: "mail", sortable: true, label: this.$t("label.email") },
        { key: "jobTitle", sortable: true, label: this.$t("label.job") },
        {
          key: "officeLocation",
          sortable: true,
          label: this.$t("label.offices"),
        },
      ];
    },
  },
  methods: {
    getListItem: function () {
      let listItem = this.getDlData();
      this.getCurrentListItem(listItem);
      return listItem.length > 0 ? listItem[0] : null;
    },
    getCurrentListItem: function (listItem) {
      if (listItem) {
        let objectId = listItem.id ? listItem.id : listItem;
        let token = this.$store.getters.getAppAccessToken;
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${objectId}`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.currentListItem = response.data;
            this.spinner = false;
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    searchDl: function (searchText) {
      if (searchText) {
        let token = this.$store.getters.getAppAccessToken;
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/groups?$filter=startswith(displayName,'${searchText}')&$count=true&$orderby=displayName`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.distListFromGraph = response.data.value;
            this.spinner = false;
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    resetTable: function () {
      this.search = "";
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    setNextStep(step) {
      this.$emit("changeStep", step);
    },
    OnListClick: function () {
      this.rawData = [];
      this.tableData = [];
      let objectId = this.listItem.id ? this.listItem.id : this.listItem;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${objectId}/members?$count=true`;
      this.getDistributionListMember(this.token, graphEndpoint);
    },
    getDistributionListMember: function (token, graphEndpoint) {
      this.spinner = true;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data["@odata.count"]) {
              this.count = response.data["@odata.count"];
            }
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getDistributionListMember(token, nextLink);
            } else {
              this.spinner = false;
              this.formatRawData();
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    formatRawData: function () {
      let flatTable = this.rawData.flat();
      this.tableData = flatTable;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) { 
        this.disabled = true;
        this.spinner = true;
        let form = {
          request: "setDistributionList",
          distributionList: this.listItem.id ? this.listItem.id : this.listItem,
          IdCampaign: this.getCampaignData("Id", 0),
        };

        axios
          .post(this.url, form, {
            headers: { "Content-Type": "application/json" },
          })
          .then(() => {
            let promise = this.getAppData(this.user);
            promise.then(() => {
              this.disabled = false;
              this.spinner = false;
              this.$emit("changePage", "CampaignDispatcher");
            });
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.disabled = false;
            this.spinner = false;
          });
      } 
    },
  },
  components: {},
};
</script>