<template>
  <b-container>
    <div class="mt-4">
      <datepicker input-class="datepicker" v-model="startDate" :placeholder="$t('action.chooseDate')" :language="language"
        :highlighted="highlighted"></datepicker>
    </div>

    <div class="mt-2" v-if="tableData.length > 0">
      <b-form-group v-if="enabledButton" class="mt-4">
        <b-form-radio v-model="requestType" name="requestType" value="terminateSelectedEmployees">{{
          $t("label.terminateSelectedEmployees") }}
        </b-form-radio>
        <b-form-radio v-model="requestType" name="requestType" value="addEmplToExclusionList">{{
          $t("label.addEmplToExclusionList") }}
        </b-form-radio>
        <b-form-radio v-model="requestType" name="requestType" value="resetPasswordAndRevokeSession">{{
          $t("label.resetPassword") }}
        </b-form-radio>
        <b-form-radio v-model="requestType" name="requestType" value="blockAccount">{{ $t("label.blockAccount") }}
        </b-form-radio>
      </b-form-group>
      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-select v-model="perPage" :options="options"></b-form-select>
        </b-col>
        <b-col sm="6" class="mt-2">
          <div>{{ $t("label.tablesize") }} {{ rows }}</div>
        </b-col>
      </b-row>

      <b-row class="pt-2">
        <b-col sm="6" class="mt-2">
          <b-form-input v-model="search" :placeholder="$t('action.search')"></b-form-input>
          <b-button variant="link" @click="resetTable()">{{
            $t("action.reset")
          }}</b-button>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col class="lgTable">
          <b-table id="DesjTerminationTable" stacked="lg" bordered :fields="myfields" :items="items"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :per-page="perPage" :current-page="currentPage">
            <template v-slot:cell(email)="row">
              <b-button variant="link" size="sm" @click="openmodal('userInfo', row.item)">{{ row.item.email }}</b-button>
            </template>
            <template v-slot:cell(details)="row">
              <b-form-checkbox v-model="row.item.remove" value="checked" unchecked-value="unchecked" name="removeItem"
                switch :disabled="row.item.processed === '' ? false : true">
                <span>{{ $t("label.choose") }}</span>
              </b-form-checkbox>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row class="pt-4">
        <b-col sm="6" class="mt-2">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="DesjTerminationTable"></b-pagination>
        </b-col>
      </b-row>
      <b-button v-if="enabledButton" @click="onSubmit()" class="mt-4" type="submit" variant="success"
        :disabled="disabled">{{ $t("action.submit") }}</b-button>
    </div>
    <div v-else>
      <b-row v-if="startDate" class="mt-4">
        <b-col sm="4">
          <b-alert show variant="info">
            <p>{{ $t("info.noDesjData") }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </div>

    <b-modal id="userInfo" size="xl" :title="$t('label.userInfo')" hide-footer>
      <b-row v-if="userInfoModal">
        <b-col>
          <p>
            <strong>{{ $t("label.desjUser") }}</strong>
          </p>
          <ul>
            <li v-for="(value, key) in getUserInfoModal(
              userInfoModal,
              'desjUser'
            )" :key="key">
              {{ value }}
            </li>
          </ul>
        </b-col>
        <b-col>
          <p>
            <strong>{{ $t("label.graphUser") }}</strong>
          </p>
          <ul>
            <li v-for="(value, key) in getUserInfoModal(
              userInfoModal,
              'graphUser'
            )" :key="key">
              {{ key }} : {{ value }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="desjTerminationModal" size="xl" :title="getTitleModal()" hide-footer>
      <DesjardinsTerminationModal :requestType="requestType" :tableData="tableData" modalid="desjTerminationModal"
        :idTable="idTable" :idTicket="idTicket" />
    </b-modal>

    <b-row v-if="success" class="text-center pt-4">
      <b-col>
        <b-alert show variant="success">
          <p>{{ $t("info.successgeneric") }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="errorList.length > 0" class="pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ $t("info.accountDisabled") }}</p>
          <ul>
            <li v-for="user in errorList" :key="user.id">
              {{ user.displayName + " (" + user.mail + ")" }}
            </li>
          </ul>
        </b-alert>
      </b-col>
    </b-row>

    <b-row v-if="error" class="text-center pt-4">
      <b-col>
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>

    <div v-if="spinner">
      <b-spinner style="width: 3rem; height: 3rem" class="spinner" variant="success" label="Spinning"
        disabled="labelStatus"></b-spinner>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { globalF } from "../Helpers";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import DesjardinsTerminationModal from "../Modal/DesjardinsTerminationModal.vue";

export default {
  mixins: [globalF],
  data() {
    return {
      form: {
        accessFormLogObj: {
          blockUser: null,
          convertMailBox: null,
          clearManagerField: null,
        },
      },
      userInfoModal: null,
      requestType: "terminateSelectedEmployees",
      startDate: "",
      rawData: [],
      tableData: [],
      count: null,
      dataFromTable: {},
      sortBy: "StartJob",
      sortDesc: true,
      perPage: 10,
      currentPage: 1,
      search: "",
      options: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      spinner: false,
      success: false,
      disabled: false,
      error: "",
      info: "",
      originalListLength: 0,
      newList: [],
      errorList: [],
      idTable: null,
      idTicket: null,
      highlighted: {}
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hide", () => {
      this.loadTable(this.startDate);
    });
    this.getStartJobForJobData();
  },
  computed: {
    user: function () {
      return this.$store.getters.getUser;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    rows() {
      return this.items.length;
    },
    items: function () {
      let mydatalist = this.tableData;
      let sl = this.search.toLowerCase();
      let s = sl.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Remove accent

      let find = mydatalist.filter((o) => {
        let fn = o.fname ? o.fname : "";
        let fname = fn.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let ln = o.lname ? o.lname : "";
        let lname = ln.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let email = o.email ? o.email : "";

        let lastPaidDay = o.lastPaidDay ? o.lastPaidDay : "";

        let bg = o.building ? o.building : "";
        let building = bg.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let st = o.status ? o.status : "";
        let status = st.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        return (
          fname.toLowerCase().includes(s) ||
          lname.toLowerCase().includes(s) ||
          email.toLowerCase().includes(s) ||
          lastPaidDay.toLowerCase().includes(s) ||
          building.toLowerCase().includes(s) ||
          status.toLowerCase().includes(s)
        );
      });

      return find;
    },
    myfields: function () {
      let myFields = [
        { key: "fname", sortable: true, label: this.$t("label.fname") },
        { key: "lname", sortable: true, label: this.$t("label.lname") },
        { key: "email", sortable: true, label: this.$t("label.email") },
        { key: "building", sortable: true, label: this.$t("label.building") },
        {
          key: "lastPaidDay",
          sortable: true,
          label: this.$t("label.lastPaidDay"),
        },
        { key: "status", sortable: true, label: this.$t("label.jobStatus") },
        { key: "details", label: this.$t("label.details") },
        { key: "processed", label: this.$t("label.processed") },
      ];

      return myFields;
    },
    url: function () {
      return this.getUrl();
    },
    enabledButton: function () {
      return (
        moment().format("YYYY-MM-DD") ===
        moment(this.startDate).format("YYYY-MM-DD")
      );
    },
  },
  watch: {
    startDate: function (date) {
      this.loadTable(date);
    },
  },
  methods: {
    loadTable(date) {
      this.errorList = [];
      this.error = "";
      this.success = false;
      this.originalListLength = 0;
      this.newList = [];
      this.tableData = [];
      if (date) {
        this.getTableData(date);
      }
    },
    onSubmit() {
      this.errorList = [];
      this.error = "";
      this.success = false;
      this.originalListLength = 0;
      this.newList = [];

      let empleList = this.tableData.filter(
        (item) => item.remove === "checked"
      );

      if (empleList.length > 0) {
        if (
          this.requestType === "terminateSelectedEmployees" ||
          this.requestType === "resetPasswordAndRevokeSession" ||
          this.requestType === "blockAccount"
        ) {
          let token = this.$store.getters.getAppAccessToken;
          this.originalListLength = empleList.length;

          for (let i = 0; i < empleList.length; i++) {
            let empl = empleList[i];
            this.getUser(token, empl);
          }
        } else {
          this.$bvModal.show("desjTerminationModal");
        }
      } else {
        this.error = this.$t("info.noEmployeeSelected");
      }
    },
    getUser(token, empl) {
      this.spinner = true;
      let emailToValidate = empl.email;
      let graphEndpoint = `https://graph.microsoft.com/v1.0/users/${emailToValidate}?$select=id,displayName,givenName,surname,mail,jobTitle,officeLocation,preferredLanguage,accountEnabled&$expand=manager($select=id,displayName,mail,preferredLanguage)`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          let user = response.data;
          this.newList.push(user);
          if (this.newList.length === this.originalListLength) {
            this.validateRequest();
          }
        })
        .catch((error) => {
          this.spinner = false;
          if (error.response) {
            this.error = error.response.data;
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        });
    },
    validateRequest() {
      this.spinner = false;
      let newList = this.newList;
      let errorList = [];
      for (let i = 0; i < newList.length; i++) {
        let empl = newList[i];

        if (!empl.accountEnabled) {
          errorList.push(empl);
        }
      }

      if (errorList.length > 0) {
        this.errorList = errorList;
      } else {
        this.$bvModal.show("desjTerminationModal");
      }
    },
    getTitleModal() {
      let modalTitle = "";
      if (this.requestType === "terminateSelectedEmployees") {
        modalTitle = this.$t("label.terminateSelectedEmployees");
      }
      if (this.requestType === "addEmplToExclusionList") {
        modalTitle = this.$t("label.addEmplToExclusionList");
      }
      if (this.requestType === "resetPasswordAndRevokeSession") {
        modalTitle = this.$t("label.resetPassword");
      }
      if (this.requestType === "blockAccount") {
        modalTitle = this.$t("label.blockAccount");
      }
      return modalTitle;
    },
    setHighlightedDate(dateList) {
      let obj = {
          dates: dateList.map(d => {
            return moment(d.StartJob).toDate();
          })
      }
      return obj;
    },
    getStartJobForJobData() {
      this.spinner = true;
      axios
        .post(
          this.url,
          { request: "getStartJobForJobData" },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.highlighted = this.setHighlightedDate(response.data);
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getTableData(date) {
      let startDate = moment(date).format("YYYY-MM-DD");
      this.spinner = true;
      axios
        .post(
          this.url,
          { request: "getDesjReportByDay", date: startDate },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          this.tableData = this.setTableData(response.data);
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    setTableData(data) {
      if (data) {
        this.idTable = data.Id;
        this.idTicket = data.IdTicket;
        let jobDataObj = JSON.parse(data.JobData);
        let jobData = jobDataObj.foundAccountEnabledTrue;

        return jobData.map((item) => {
          let graphUserStr = item[item.length - 1];
          let graphUser = null;
          let email = "";
          let processed = false;
          let processedType = "";
          if (this.isValidJson(graphUserStr)) {
            graphUser = JSON.parse(graphUserStr);
            if (graphUser.length > 0) {
              email = graphUser[0].mail ? graphUser[0].mail : "";
              processed = graphUser[0].processed
                ? graphUser[0].processed
                : false;
              processedType = graphUser[0].processedType
                ? graphUser[0].processedType
                : "";
            }
          }
          return {
            fname: item[12],
            lname: item[11],
            email: item[16] ? item[16] : email,
            lastPaidDay: item[19],
            status: item[17],
            remove: "unchecked",
            desjUser: item,
            graphUser: graphUser,
            building: graphUser ? graphUser[0].officeLocation : "",
            processed: this.getProcessed(processedType),
            _cellVariants: {
              email: item[16] ? "" : "warning",
              processed: processed ? "danger" : "",
            },
          };
        });
      } else {
        return [];
      }
    },
    getProcessed(processedType) {
      if (processedType === "resetPasswordAndRevokeSession") {
        return "reset";
      } else if (processedType === "addEmplToExclusionList") {
        return "exclusion";
      } else if (processedType === "terminateSelectedEmployees") {
        return "terminate";
      } else {
        return "";
      }
    },
    getUserInfoModal(userInfoModal, userType) {
      let userInfo = userInfoModal[userType];
      if (userType === "desjUser") {
        let lastIndex = userInfo.length - 1;
        return userInfo.filter((item, index) => index !== lastIndex);
      }
      if (userType === "graphUser") {
        return userInfo[0];
      }
    },
    openmodal: function (id, userInfo) {
      this.userInfoModal = userInfo;
      this.$bvModal.show(id);
    },
    setNextPage(page) {
      this.$emit("changePage", page);
    },
    resetTable: function () {
      this.search = "";
    },
  },
  components: {
    Datepicker,
    DesjardinsTerminationModal,
  },
};
</script>
