import axios from 'axios';
import moment from 'moment';
export const globalF = {
  methods: {
    validateToken: async function () {
      let userToken = this.$store.getters.getUserToken;
      return userToken;
    },
    goToLogin: function () {
      this.$store.commit('addUser', null);
      this.$emit('changePage', 'Login');
    },
    getBuildingData: async function () {
      let url = this.getUrl();
      let appData = await axios.post(url, { request: "getBuildingData" }, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          this.$store.commit('addBuildings', response.data.buildings);
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });

      return appData;
    },
    getAppData: async function (user, page) {

      let url = this.getUrl();
      let appData = await axios.post(url, { request: "getAppData", email: user.mail }, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {

          let userData = response.data.user;

          if (userData) {
            user.IdMyProfile = userData.Id
            user.AppRole = userData.RoleId;
          }
          else {
            user.AppRole = 0; //no role
          }

          this.$store.commit('addUser', user);
          this.$store.commit('addBuildings', response.data.buildings);
          this.$store.commit('addCities', response.data.cities);
          this.$store.commit('addProvinces', response.data.provinces);
          this.$store.commit('addCountries', response.data.countries);
          this.$store.commit('addClassNames', response.data.classNames);
          this.$store.commit('addCollaboratorService', response.data.collaboratorService);

          this.$store.commit('addCampaign', response.data.campaign ? response.data.campaign : []);
          this.$store.commit('addDates', response.data.dates ? response.data.dates : []);
          this.$store.commit('addDistributionsList', response.data.distributionsList ? response.data.distributionsList : []);

          this.$store.commit('addCampaignTemplate', response.data.templates ? response.data.templates : []);
          this.$store.commit('addCampaignTemplateVersion', response.data.templateVersion ? response.data.templateVersion : []);

          this.$store.commit('addSessions', response.data.sessionsList ? response.data.sessionsList : []);

          this.$store.commit('addGraphProductName', response.data.graphProductName ? response.data.graphProductName : []);
          this.$store.commit('addGraphSubscribedSkus', response.data.graphSubscribedSkus ? response.data.graphSubscribedSkus : []);

          this.$store.commit('addOtherProducts', response.data.otherProducts ? response.data.otherProducts : []);

          this.$store.commit('addHourlyRate', response.data.hourlyRate ? response.data.hourlyRate : null);

          if (response.data.accessToken) {
            let accessTokenObj = JSON.parse(response.data.accessToken);
            if (accessTokenObj) {
              this.$store.commit('addAppAccessToken', accessTokenObj.access_token);
            }
          }

          if (page) {
            this.$emit('changePage', page);
          }
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });

      return appData;
    },
    getAppRole: function (AppRole, level) {
      //SuperAdmin
      if (AppRole === 4) {
        return true;
      }
      else if (AppRole === level) {
        return true;
      }
      else {
        return false;
      }
    },
    getEmployeeId(token) {
      let graphEndpoint = "https://graph.microsoft.com/v1.0/me?$select=employeeId";
      return axios.get(graphEndpoint, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json' } });
    },
    getDlById(token, objectId) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${objectId}`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ConsistencyLevel: "eventual",
          },
        })
        .then((response) => {
          this.distListFromGraph = response.data;
          this.spinner = false;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    getAllGroups: function (token, graphEndpoint) {
      this.spinner = true;
      axios.get(graphEndpoint, { headers: { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json', 'ConsistencyLevel': 'eventual' } })
        .then((response) => {
          if (response.status === 200) {
            let nextLink = response.data["@odata.nextLink"];
            this.rawData.push(response.data.value);

            if (nextLink) {
              this.getAllGroups(token, nextLink);
            } else {
              let flatTable = this.rawData.flat();
              this.distListFromGraph = this.mapDataForSelect(flatTable, 'id', 'displayName');
              this.spinner = false;
            }
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t('info.errorgeneric');
          this.spinner = false;
        });
    },
    setSession(user) {
      let url = this.getUrl();

      axios.post(url, { request: "setSession", email: user.mail }, { headers: { 'Content-Type': 'application/json' } })
        .then(() => {
          this.disabled = false;
          this.spinner = false;
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });
    },
    updateSession(session) {
      let url = this.getUrl();
      axios.post(url, { request: "updateSession", session: session }, { headers: { 'Content-Type': 'application/json' } })
        .then(() => {
          this.disabled = false;
          this.spinner = false;
        })
        .catch((error) => {
          this.error = error;
          this.disabled = false;
          this.spinner = false;
        });
    },
    getUrl() {
      const loc = window.location.hostname;
      const env = process.env.VUE_APP_WHERE;
      const urls = this.$store.getters.getUrlsList;
      let url = urls[env].json;

      if (env === 'prod') {
        url = loc.includes('myprofile') ? urls[env].json.en : urls[env].json.fr;
      }

      return url;
    },
    getHost() {
      const loc = window.location.hostname;
      const env = process.env.VUE_APP_WHERE;
      const urls = this.$store.getters.getUrlsList;
      let url = urls[env].host;

      if (env === 'prod') {
        url = loc.includes('myprofile') ? urls[env].host.en : urls[env].host.fr;
      }

      return url;
    },
    getCampaignData(type, defaultValue) {

      let user = this.$store.getters.getUser;
      let campaign = this.$store.getters.getCampaign;
      let propertyValue = defaultValue;

      //campagne en cour de l'utilisateur
      let campaignData = campaign.filter(p => p.Status === 3 && p.UserId === user.IdMyProfile);

      if (campaignData.length > 0) {
        let step1 = campaignData[0];
        propertyValue = step1[type];
      }
      return propertyValue;
    },
    getTemplateData() {
      let idCampaign = this.getCampaignData('Id', 0);
      let templates = this.$store.getters.getCampaignTemplateVersion;
      let templatesFilter = templates.filter(p => p.IdCampaign === idCampaign);
      return templatesFilter;
    },
    getDlData() {
      let idCampaign = this.getCampaignData('Id', 0);
      let distributionList = this.$store.getters.getDistributionsList;

      let distributionListFilter = distributionList.filter(p => p.IdCampaign === idCampaign);
      let listItem = [];
      if (distributionListFilter.length > 0) {
        listItem = distributionListFilter.map(dl => {
          return dl.IdDistributionList;
        });
      }
      return listItem;
    },
    getTemplatesList: function () {
      let templatesList = [];
      let campaignTemplate = this.$store.getters.getCampaignTemplate;
      if (campaignTemplate.templates) {
        if (campaignTemplate.templates && Array.isArray(campaignTemplate.templates)) {
          templatesList = this.mapDataForSelect(campaignTemplate.templates, 'id', 'name');
        }
      }
      return templatesList;
    },
    mapDataForSelect: function (list, value, text) {
      return list.map(l => {
        return {
          value: l[value],
          text: l[text]
        };
      });
    },
    getCampaignWithStartDate: function () {
      let campaign = this.$store.getters.getCampaign;
      let datesCampaign = this.$store.getters.getDates;
      return campaign.map(c => {
        let dates = datesCampaign.filter(d => d.IdCampaign === c.Id);
        if (dates.length > 0) {
          dates.sort((a, b) => { return moment(a.StartDate).toDate() - moment(b.StartDate).toDate() });
          c.StartDate = dates[0].StartDate;
          c.StartTime = dates[0].StartTime;
          c.DatesCampaign = dates;
        }
        return c;
      });
    },
    getAllDomains: function (token) {
      let graphEndpoint = `https://graph.microsoft.com/v1.0/domains`;
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.allDomains = response.data.value;
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.spinner = false;
        });
    },
    validateTenantDomain: function (email, allDomains) {
      if (email) {
        let ind = email.indexOf("@");
        let mailDomain = email.slice(ind + 1);
        let find = allDomains.find(a => a.id === mailDomain);
        return find;
      }
      return false;
    },
    isValidJson: function (str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    searchUsersBySearchText(token, searchText) {
      if (searchText) {
        this.spinner = true;
        let graphEndpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchText}') or startswith(mail,'${searchText}') &$expand=manager($select=id,displayName,mail)&$count=true&$top=10`;
        axios
          .get(graphEndpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              ConsistencyLevel: "eventual",
            },
          })
          .then((response) => {
            this.spinner = false;
            if (response.status === 200) {
              this.users = response.data.value.filter(
                (a) =>
                  a.displayName.indexOf("*") === -1 &&
                  this.validateTenantDomain(a.mail, this.allDomains)
              );
            } else {
              this.error = this.$t("info.errorgeneric");
            }
          })
          .catch(() => {
            this.error = this.$t("info.errorgeneric");
            this.spinner = false;
          });
      }
    },
    generatePassword() {
      let characters = "a-z,A-Z,0-9";
      let passwordSize = 5;

      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      /*if (charactersArray.indexOf("#") >= 0) {
        //CharacterSet += "![]{}()%&*$#^<>~@|";
        CharacterSet += "!%&*$#@";
      }*/
      for (let i = 0; i < passwordSize; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }

      password += "6xV";

      return password.charAt(0).toUpperCase() + password.slice(1);
    },
  }
};