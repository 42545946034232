<template>
  <b-container>
    <b-form @submit="onSubmit">
      <b-row class="mt-2">
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.campaignStartDate") }}</strong>
          </div>
          <datepicker
            input-class="datepicker"
            v-model="startDate"
            :placeholder="$t('action.chooseDate')"
            :language="language"
            :disabledDates="disabledStartDate"
            :disabled="setDisabled"
          ></datepicker>
          <div
            class="text-danger"
            v-if="!$v.startDate.required && $v.startDate.$dirty"
          >
            {{ $t("info.required") }}
          </div>
          <div>
            <vue-timepicker
              class="mt-2"
              :minute-interval="5"
              v-model="startTime"
              :disabled="setDisabled"
              hide-clear-button
            ></vue-timepicker>
          </div>
          <div>
            <b-button
              v-if="!setDisabled"
              variant="link"
              @click="reset('start')"
              >{{ $t("action.reset") }}</b-button
            >
            <b-button
              v-if="!setDisabled"
              variant="link"
              class="ml-2"
              @click="set('start')"
              >{{ $t("action.setStartDate") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm class="mt-4">
          <div>
            <strong>{{ $t("label.campaignRelaunch") }}</strong>
          </div>
          <datepicker
            input-class="datepicker"
            v-model="relaunchDate"
            :placeholder="$t('action.chooseDate')"
            :language="language"
            :disabledDates="disabledRelaunchDate"
            :disabled="setDisabledRelaunchDate"
          ></datepicker>
          <div>
            <vue-timepicker
              class="mt-2"
              :minute-interval="5"
              v-model="relaunchTime"
              :disabled="setDisabledRelaunchDate"
              hide-clear-button
            ></vue-timepicker>
          </div>
          <div>
            <b-button
              v-if="!setDisabledRelaunchDate"
              variant="link"
              @click="reset('relaunch')"
              >{{ $t("action.reset") }}</b-button
            >
            <b-button
              v-if="!setDisabledRelaunchDate"
              variant="link"
              class="ml-2"
              @click="set('relaunch')"
              >{{ $t("action.setStartDate") }}</b-button
            >
          </div>
        </b-col>
      </b-row>

      <b-button
        class="mt-4 text-center"
        type="submit"
        variant="success"
        :disabled="disabled || (setDisabled && setDisabledRelaunchDate)"
        >{{ $t("action.submit") }}</b-button
      >

      <hr />

      <b-alert v-if="!setDisabled" show variant="primary">
        <div>{{ $t("info.startCampaign") }}</div>
        <b-button
          class="mt-4 text-center"
          variant="outline-info"
          @click="launchCampaignType('start')"
          >{{ $t("action.startCampaign") }}</b-button
        >
      </b-alert>

      <b-alert
        v-if="setDisabled && !setDisabledRelaunchDate"
        show
        variant="info"
      >
        <div>{{ $t("info.relaunchCampaign") }}</div>
        <b-button
          class="mt-4 text-center"
          variant="outline-info"
          @click="launchCampaignType('relaunch')"
          >{{ $t("action.relaunch") }}</b-button
        >
      </b-alert>

      <div v-if="error" class="pt-4">
        <b-alert show variant="danger">
          <p>{{ error }}</p>
        </b-alert>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import { en, fr } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import axios from "axios";
import { globalF } from "../Helpers";

export default {
  mixins: [globalF],
  props: ["values", "type", "modalid"],
  data() {
    return {
      form: {
        scheduleRelaunchDate: null,
        scheduleRelaunchTime: null,
        scheduleStartDate: null,
        scheduleStartTime: null,
      },
      startDate: this.getDate("start", ""),
      startTime: this.getTime("start", "00:00"),
      relaunchDate: this.getDate("relaunch", ""),
      relaunchTime: this.getTime("relaunch", "00:00"),
      spinner: false,
      disabled: false,
      error: "",
    };
  },
  validations() {
    return {
      startDate: {
        required,
      },
    };
  },
  computed: {
    url: function () {
      return this.getUrl();
    },
    user: function () {
      return this.$store.getters.getUser;
    },
    language: function () {
      return this.$i18n.locale === "fr" ? fr : en;
    },
    disabledStartDate: function () {
      let myDate = moment().startOf("day").toDate();
      return { to: myDate };
    },
    disabledRelaunchDate: function () {
      let myDate = moment().add(1, "days").startOf("day").toDate();
      return { to: myDate };
    },
    setDisabled: function () {
      let status = false;
      let startDate = this.values.StartDate;
      let startTime = this.values.StartTime;

      if (startDate && startTime) {
        let sdstr = moment(this.startDate).format("YYYY-MM-DD");
        let sd = moment(sdstr + " " + this.startTime);

        if (moment(sd).isBefore(moment())) {
          status = true;
        }
      }

      return status;
    },
    setDisabledRelaunchDate: function () {
      let status = false;
      let relaunchDate = this.getDate("relaunch", null);
      let relaunchTime = this.getTime("relaunch", null);
      if (relaunchDate && relaunchTime) {
        let sdstr = moment(relaunchDate).format("YYYY-MM-DD");
        let sd = moment(sdstr + " " + relaunchTime);

        if (moment(sd).isBefore(moment())) {
          status = true;
        }
      }

      return status;
    },
  },
  methods: {
    getDate: function (type, byDefault) {
      let date = byDefault;
      let startDate = null;
      if (type === "start") {
        startDate = this.values.StartDate;
      }
      if (type === "relaunch") {
        let arrDates = this.values.DatesCampaign;
        if (arrDates) {
          let relaunchDate = arrDates.find((d) => d.Type === "relaunch");
          if (relaunchDate) {
            startDate = relaunchDate.StartDate;
          }
        }
      }

      if (startDate) {
        let validDate = moment(startDate);
        if (validDate.isValid()) {
          date = validDate.toDate();
        }
      }

      return date;
    },
    getTime: function (type, byDefault) {
      let time = byDefault;
      let startDate = null;
      let startTime = null;
      if (type === "start") {
        startDate = this.values.StartDate;
        startTime = this.values.StartTime;
      }
      if (type === "relaunch") {
        let arrDates = this.values.DatesCampaign;
        if (arrDates) {
          let relaunchDate = arrDates.find((d) => d.Type === "relaunch");
          if (relaunchDate) {
            startDate = relaunchDate.StartDate;
            startTime = relaunchDate.StartTime;
          }
        }
      }

      if (startDate && startTime) {
        let validTime = moment(
          startDate + " " + startTime,
          "YYYY-MM-DD HH:mm:ss"
        );
        if (validTime.isValid()) {
          time = validTime.format("HH:mm");
        }
      }

      return time;
    },
    validateDates: function () {
      let status = false;

      if (!this.setDisabled && this.startDate) {
        let sdstr = moment(this.startDate).format("YYYY-MM-DD");
        let sd = moment(sdstr + " " + this.startTime);
        if (moment(sd).isBefore(moment())) {
          status = true;
          this.error = this.$t("info.startPast");
        }
      }

      if (this.relaunchDate) {
        let rdstr = moment(this.relaunchDate).format("YYYY-MM-DD");
        let rd = moment(rdstr + " " + this.relaunchTime);
        if (rd.isValid()) {
          if (moment(rd).isSameOrBefore(moment())) {
            status = true;
            this.error = this.$t("info.dateBefore");
          }
        }
      }

      if (this.startDate && this.relaunchDate) {
        let sdstr = moment(this.startDate).format("YYYY-MM-DD");
        let sd = moment(sdstr + " " + this.startTime);
        let rdstr = moment(this.relaunchDate).format("YYYY-MM-DD");
        let rd = moment(rdstr + " " + this.relaunchTime);

        if (moment(rd).isBefore(moment(sd))) {
          status = true;
          this.error = this.$t("info.dateBefore");
        }
      }

      if (this.setDisabled && this.setDisabledRelaunchDate) {
        status = true;
        this.error = this.$t("info.nodata");
      }

      return status;
    },
    set: function (type) {
      if (type === "start") {
        this.startDate = this.getDate("start", "");
        this.startTime = this.getTime("start", "00:00");
        this.form.scheduleStartDate = null;
        this.form.scheduleStartTime = null;
      }
      if (type === "relaunch") {
        this.relaunchDate = this.getDate("relaunch", "");
        this.relaunchTime = this.getTime("relaunch", "00:00");
        this.form.scheduleRelaunchDate = null;
        this.form.scheduleRelaunchTime = null;
      }
    },
    reset: function (type) {
      if (type === "start") {
        this.startDate = null;
        this.startTime = "00:00";
        this.form.scheduleStartDate = null;
        this.form.scheduleStartTime = null;
      }
      if (type === "relaunch") {
        this.relaunchDate = null;
        this.relaunchTime = "00:00";
        this.form.scheduleRelaunchDate = null;
        this.form.scheduleRelaunchTime = null;
      }
    },
    onSubmit(evt) {
      this.error = "";
      evt.preventDefault();
      this.$v.$touch();

      if (!this.$v.$invalid && !this.validateDates()) {
        this.spinner = true;
        this.form.request = "editCampaignDate";
        this.form.IdCampaign = this.values.Id;

        let arrDates = this.values.DatesCampaign;
        let startDate = arrDates.find((d) => d.Type === "start");
        let relaunchDate = arrDates.find((d) => d.Type === "relaunch");
        if (startDate && !this.setDisabled) {
          this.form.IdStart = startDate.Id;
        }
        if (relaunchDate) {
          this.form.IdRelaunch = relaunchDate.Id;
        }

        if (this.startDate && !this.setDisabled) {

          let startDateString = moment(this.startDate).format("YYYY-MM-DD");

          this.form.scheduleStartDate = moment(startDateString + " " + this.startTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

          this.form.scheduleStartTime = moment(
            this.form.scheduleStartDate + " " + this.startTime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm");
        }

        if (this.relaunchDate) {
          let relaunchDateStr = moment(this.relaunchDate).format("YYYY-MM-DD");

          this.form.scheduleRelaunchDate = moment(relaunchDateStr + " " + this.relaunchTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
          
          this.form.scheduleRelaunchTime = moment(
            this.form.scheduleRelaunchDate + " " + this.relaunchTime,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm");
        }

        if (!this.form.scheduleStartDate && !this.form.scheduleRelaunchDate) {
          this.error = this.$t("info.nodata");
        } else {
          axios
            .post(this.url, this.form, {
              headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
              this.disabled = false;
              this.spinner = false;

              if (response.data === 0) {
                this.$bvModal.hide(this.modalid);
              } else if (response.data === "dateCampaignAlreadyExist") {
                this.error = this.$t("info.dateCampaignAlreadyExist");
              } else {
                this.error = this.$t("info.errorgeneric");
              }
            })
            .catch(() => {
              this.error = this.$t("info.errorgeneric");
              this.disabled = false;
              this.spinner = false;
            });
        }
      }
    },
    launchCampaignType: function (campaignType) {
      this.disabled = true;
      this.spinner = true;
      this.form.request = "activateCampaign";
      this.form.userId = this.user.IdMyProfile;
      this.form.graphUserId = this.user.id;
      this.form.testEmail = false;
      this.form.arrDlTemplate = this.arrDlTemplate;
      this.form.sendingType = "immediate";
      this.form.status = campaignType === 'start' ? 1 : 2;
      this.form.id = this.values.Id;
      this.form.name = this.values.Name;
      this.form.description = this.values.Description;
      this.form.userId = this.values.UserId;
      this.form.campaignType = campaignType;

      let startDate = this.getDate("start", null);

      if (startDate) {
        this.form.startDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
      }

      if (this.startDate) {
        let startDateString = moment(this.startDate).format("YYYY-MM-DD");

        this.form.scheduleStartDate = moment(startDateString + " " + this.startTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

        this.form.scheduleStartTime = moment(
          this.form.scheduleStartDate + " " + this.startTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
      }

      if (this.relaunchDate) {

        let relaunchDateStr = moment(this.relaunchDate).format("YYYY-MM-DD");

        this.form.scheduleRelaunchDate = moment(relaunchDateStr + " " + this.relaunchTime, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
          
        this.form.scheduleRelaunchTime = moment(
          this.form.scheduleRelaunchDate + " " + this.relaunchTime,
          "YYYY-MM-DD HH:mm:ss"
        ).format("HH:mm");
      }

      axios
        .post(this.url, this.form, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          this.disabled = false;
          this.spinner = false;
          if (response.data === 0) {
            this.$bvModal.hide(this.modalid);
          } else {
            this.error = this.$t("info.errorgeneric");
          }
        })
        .catch(() => {
          this.error = this.$t("info.errorgeneric");
          this.disabled = false;
          this.spinner = false;
        });
    }
  },
  components: {
    Datepicker,
    VueTimepicker,
  },
};
</script>
